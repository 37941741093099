import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import IconSocialPinterest from '../IconSocialPinterest/IconSocialPinterest';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, sitePinterestPage } = config;

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const pinterestLink = sitePinterestPage ? (
    <ExternalLink
      key="linkToPinterest"
      href={sitePinterestPage}
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialPinterest />
    </ExternalLink>
  ) : null;

  const instagramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [instagramLink, fbLink, pinterestLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const baseUrl = config.canonicalRootURL;

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLinksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.extraLinks}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
            </div>
            <div className={css.infoLinks}>
              <p className={css.listLinksHeader}>
                <FormattedMessage id="Footer.companyHeader" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={`${baseUrl}/blog`}>
                    <FormattedMessage id="Footer.blog" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={config.tallyForm.contact}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    className={css.link}
                    href="https://webflow.rollto.me/newsletter-sign-up"
                  >
                    <FormattedMessage id="Footer.toNewsLetterPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    className={css.link}
                    href={`${baseUrl}/privacy`}
                  >
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={`${baseUrl}/terms`}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={`${baseUrl}/faqs`}>
                    <FormattedMessage id="Footer.faqs" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <p className={css.listLinksHeader}>
                <FormattedMessage id="Footer.listingsHeader" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.allMobileBars" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={config.tallyForm.concierge}>
                    <FormattedMessage id="Footer.conciergeService" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={`${baseUrl}/mobile-bars-for-sale`}>
                    <FormattedMessage id="Footer.mobileBarForSale" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink className={css.link} href={`${baseUrl}/mobile-bar-builders`}>
                    <FormattedMessage id="Footer.mobileBarBuilders" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <p className={css.listLinksHeader}>
                <FormattedMessage id="Footer.eventsHeader" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.wedding" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.corporateEvent" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.bridalShower" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.birthday" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.photoShoot" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.charityEvent" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.festival" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.babyShower" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.viewAll" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <p className={css.listLinksHeader}>
                <FormattedMessage id="Footer.searchLocationsHeader" />
              </p>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161439%2C-118.126728%2C33.900014%2C-118.521447',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchLosAngeles" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  {' '}
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        's?address=San%20Francisco%2C%20CA&bounds=37.84923536%2C-122.356967%2C37.69095746%2C-122.517688',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchSanFrancisco" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Charleston%2C%20South%20Carolina%2C%20United%20States&bounds=32.83231971%2C-79.90976142%2C32.74370816%2C-79.99436715',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchCharleston" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Chicago%2C%20Illinois%2C%20United%20States&bounds=42.023135%2C-87.523907294%2C41.644286%2C-87.869226',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchChicago" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Dallas%2C%20TX&bounds=33.016499%2C-96.555516%2C32.618564%2C-96.995725',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchDallas" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=New%20York%2C%20NY&bounds=40.88192355%2C-73.82949866%2C40.61821827%2C-74.10890766',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchNewYork" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search:
                        '?address=Austin%2C%20Texas%2C%20United%20States&bounds=30.519669%2C-97.541602%2C30.067944%2C-98.076759',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchAustin" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.searchViewAll" />
                  </NamedLink>
                </li>
              </ul>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);

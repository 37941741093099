import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink, PrimaryButton, SecondaryButton } from '..';
import { BiSupport } from 'react-icons/bi';
import { BsCheck2All } from 'react-icons/bs';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build OrderActionButtons for customer
const OrderActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    declineError,
    declineInProgress,
    onDeclineOffer,
    onMakePayment,
    showDeclineButton,
    showMakePaymentButton,
    showCancelButton,
    onCancelBooking,
    showMakePaymentByPaymentLinkButton,
    paymentLink,
    showButtons,
    cancelInProgress,
    cancelError,
    requestDisputeInProgress,
    requestDisputeError,
    showCompleteDisputeButton,
    onCompleteDispute,
    onRequestDispute,
    showDisputeButton,
    makePaymentInProgress,
    onMakeSecondPayment
  } = props;

  const buttonsDisabled = declineInProgress || cancelInProgress || requestDisputeInProgress || makePaymentInProgress;

  const declineErrorMessage = declineError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineFailed" />
    </p>
  ) : null;
  const cancelErrorMessage = cancelError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelFailed" />
    </p>
  ) : null;
  const requestDisputeErrorMessage = requestDisputeError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.requestDisputeFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {declineErrorMessage}
        {cancelErrorMessage}
        {requestDisputeErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {showDisputeButton && (
          <SecondaryButton
            className={css.secondaryButton}
            inProgress={requestDisputeInProgress}
            disabled={buttonsDisabled}
            onClick={onRequestDispute}
          >
            <FormattedMessage id="TransactionPanel.requestDispute" />
            <BiSupport size={16} />
          </SecondaryButton>
        )}
        {showDeclineButton && (
          <SecondaryButton
            className={css.secondaryButton}
            inProgress={declineInProgress}
            disabled={buttonsDisabled}
            onClick={onDeclineOffer}
          >
            <FormattedMessage id="TransactionPanel.declineButton" />
          </SecondaryButton>
        )}
        {showCancelButton && (
          <SecondaryButton
            className={css.secondaryButton}
            inProgress={cancelInProgress}
            disabled={buttonsDisabled}
            onClick={onCancelBooking}
          >
            <FormattedMessage id="TransactionPanel.cancelButton" />
          </SecondaryButton>
        )}
        {showMakePaymentButton && (
          <PrimaryButton
            className={css.primaryButton}
            disabled={buttonsDisabled}
            onClick={onMakePayment}
          >
            <FormattedMessage id="TransactionPanel.makePaymentButton" />
          </PrimaryButton>
        )}
        {showCompleteDisputeButton && (
          <PrimaryButton
            className={css.primaryButton}
            disabled={buttonsDisabled}
            onClick={onCompleteDispute}
          >
            <FormattedMessage id="TransactionPanel.completeDisputeButton" />
            <BsCheck2All size={15} />
          </PrimaryButton>
        )}
        {showMakePaymentByPaymentLinkButton && (
          <PrimaryButton
            className={css.primaryButton}
            disabled={buttonsDisabled}
            onClick={onMakeSecondPayment}
          >
            <FormattedMessage id="TransactionPanel.makePaymentButton" />
          </PrimaryButton>
        )}
      </div>
    </div>
  ) : null;
};

export default OrderActionButtonsMaybe;

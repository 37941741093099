import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldCustomMultipleSelect,
} from '../../components';
import { CANADA_CITIES, CANADA_PROVINCES, US_CITIES, US_STATES } from '../../util/locations';
import {
  ALL_STATES,
  getCitiesOfRegion,
} from '../../util/locationHelpers';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

const allCityOptions = [...US_CITIES, ...CANADA_CITIES];

const getCitiesOption = (stateArray, searchCitiesArray) =>
  stateArray?.reduce((prev, state) => {
    const options = getCitiesOfRegion(searchCitiesArray, state.value || state);
    return [...prev, ...options];
  }, []);

export const EditListingLocationFormComponent = props => {
  const { initialValues } = props;
  const [ciyOptions, setCityOptions] = useState(getCitiesOption(initialValues.workStates, allCityOptions));

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
        } = formRenderProps;

        const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressPlaceholder',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        const statesLabel = intl.formatMessage({ id: 'EditListingLocationForm.statesLabel' });
        const statesRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.statesRequiredMessage',
        });
        const statesNoteMessage = intl.formatMessage({
          id: 'EditListingLocationForm.statesNoteMessage',
        });

        const standardCitiesLabel = intl.formatMessage({
          id: 'EditListingLocationForm.standardCitiesLabel',
        });
        const standardCitiesRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.standardCitiesRequiredMessage',
        });

        const extraCitiesLabel = intl.formatMessage({
          id: 'EditListingLocationForm.extraCitiesLabel',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const { standardWorkCities = [], extraWorkCities = [] } = values;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            <LocationAutocompleteInputField
              rootClassName={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              autoFocus
              name="location"
              label={titleRequiredMessage}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldCustomMultipleSelect
              id="workStates"
              name="workStates"
              className={css.locationAddress}
              label={statesLabel}
              description={statesNoteMessage}
              options={ALL_STATES}
              validate={requiredFieldArrayCheckbox(statesRequiredMessage)}
              onChange={value => {
                form.change('workStates', value);

                setCityOptions(getCitiesOption(value, allCityOptions));

                form.change('standardWorkCities', getCitiesOption(value, standardWorkCities, value));
                form.change('extraWorkCities', getCitiesOption(value, extraWorkCities));
              }}
              isClearable
              isSearchable
            />

            <FieldCustomMultipleSelect
              id="standardWorkCities"
              name="standardWorkCities"
              className={css.locationAddress}
              label={standardCitiesLabel}
              options={ciyOptions}
              validate={requiredFieldArrayCheckbox(standardCitiesRequiredMessage)}
              isSearchable
              isClearable
            />

            <FieldCustomMultipleSelect
              id="extraWorkCities"
              name="extraWorkCities"
              className={css.locationAddress}
              label={extraCitiesLabel}
              options={ciyOptions}
              isSearchable
              isClearable
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);

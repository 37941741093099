import config from '../config';
import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer requests a booking to a listing, a transaction is
// created with the initial request-to-book transition.
export const TRANSITION_REQUEST_TO_BOOK = 'transition/request-to-book';

// When a provider declines a booking request without making offer
export const TRANSITION_DECLINE_REQUEST_BOOKING = 'transition/decline-request-booking';

// When a provider makes an offer to a booking request
export const TRANSITION_MAKE_OFFER = 'transition/make-offer';

// When a customer declines an offer
export const TRANSITION_CUSTOMER_DECLINE_OFFER = 'transition/customer-decline-offer';
export const TRANSITION_CUSTOMER_DECLINE_OFFER_FULL_PAYMENT =
  'transition/customer-decline-offer-full-payment';

// When a request booking is expired
export const TRANSITION_EXPIRE_REQUEST_BOOKING = 'transition/expire-request-booking';

// FULL PAYMENT FLOW (-21 days prior to booking start date flow)

// When a request booking is in -21 days prior to booking start date flow
export const TRANSITION_TO_FULL_PAYMENT_FLOW = 'transition/to-full-payment-flow';
export const TRANSITION_EXPIRE_FULL_PAYMENT_FLOW = 'transition/expire-full-payment-flow';
export const TRANSITION_EXPIRE_FULL_PAYMENT = 'transition/expire-full-payment';
export const TRANSITION_CONFIRM_FULL_PAYMENT = 'transition/confirm-full-payment';
export const TRANSITION_START_EVENT_AFTER_FULL_PAYMENT =
  'transition/start-event-after-full-payment';
export const TRANSITION_ADMIN_CANCEL_FULL_PAYMENT = 'transition/admin-cancel-full-payment';
export const TRANSITION_CANCEL_FULL_PAYMENT = 'transition/cancel-full-payment';
export const TRANSITION_PAYOUT = 'transition/payout';
export const TRANSITION_MAKE_FULL_PAYMENT = 'transition/make-full-payment';

// SPLIT PAYMENT FLOW (-21 days prior to booking start date flow)

// When a customer makes a payment in +21 days prior to booking start date flow
export const TRANSITION_MAKE_FIRST_PAYMENT = 'transition/make-first-payment';
export const TRANSITION_EXPIRE_FIRST_PAYMENT = 'transition/expire-first-payment';
export const TRANSITION_CONFIRM_FIRST_PAYMENT = 'transition/confirm-first-payment';
export const TRANSITION_ADMIN_CANCEL_FIRST_PAYMENT = 'transition/admin-cancel-first-payment';
export const TRANSITION_CANCEL_FIRST_PAYMENT = 'transition/cancel-first-payment';
export const TRANSITION_EXECUTE_FIRST_PAYOUT = 'transition/execute-first-payout';
export const TRANSITION_CANCEL_AFTER_FIRST_PAYOUT = 'transition/cancel-after-first-payout';
export const TRANSITION_SEND_REMINDER_SECOND_PAYMENT = 'transition/send-reminder-second-payment';
export const TRANSITION_CREATE_SECOND_PAYMENT_LINK = 'transition/create-second-payment-link';
export const TRANSITION_EXPIRE_CREATE_SECOND_PAYMENT_LINK =
  'transition/expire-create-second-payment-link';
export const TRANSITION_PENDING_CONFIRM_SECOND_PAYMENT =
  'transition/pending-confirm-second-payment';
export const TRANSITION_EXPIRE_PENDING_CONFIRM_SECOND_PAYMENT =
  'transition/expire-pending-confirm-second-payment';
export const TRANSITION_CONFIRM_SECOND_PAYMENT = 'transition/confirm-second-payment';
export const TRANSITION_EXPIRE_CONFIRM_SECOND_PAYMENT = 'transition/expire-confirm-second-payment';
export const TRANSITION_START_EVENT = 'transition/start-event';
export const TRANSITION_PAYOUT_OFF_PLATFORM = 'transition/payout-off-platform';

// ONGOING EVENT
export const TRANSITION_REQUEST_DISPUTE = 'transition/request-dispute';
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_EXPIRE_DISPUTE_AUTO_COMPLETE = 'transition/expire-dispute-auto-complete';
export const TRANSITION_COMPLETE_AFTER_DISPUTE = 'transition/complete-after-dispute';
export const TRANSITION_ADMIN_COMPLETE_AFTER_DISPUTE = 'transition/admin-complete-after-dispute';
export const TRANSITION_ADMIN_CANCEL_AFTER_DISPUTE = 'transition/admin-cancel-after-dispute';
export const TRANSITION_ADMIN_CANCEL_FULL_PAYMENT_AFTER_DISPUTE =
  'transition/cancel-full-payment-after-dispute';
export const TRANSITION_TO_PAYOUT_STATE = 'transition/to-payout-state';
export const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_CUSTOMER_REQUEST_BOOKING = 'customer-request-booking';
const STATE_OFFER_MADE = 'offer-made';
const STATE_PENDING_FIRST_PAYMENT = 'pending-first-payment';
const STATE_FIRST_PAYMENT_CONFIRMED = 'first-payment-confirmed';
const STATE_FIRST_PAYOUT_COMPLETED = 'first-payout-completed';
const STATE_SETUP_SECOND_PAYMENT = 'setup-second-payment';
const STATE_PENDING_SECOND_PAYMENT = 'pending-second-payment';
const STATE_PENDING_CONFIRM_SECOND_PAYMENT = 'pending-confirm-second-payment';
const STATE_SECOND_PAYMENT_CONFIRMED = 'second-payment-confirmed';
const STATE_FULL_PAYMENT_FLOW = 'full-payment-flow';
const STATE_PENDING_FULL_PAYMENT = 'pending-full-payment';
const STATE_FULL_PAYMENT_CONFIRMED = 'full-payment-confirmed';
const STATE_ONGOING_EVENT = 'ongoing-event';
const STATE_PAID_OUT = 'paid-out';
const STATE_DISPUTE = 'dispute';
const STATE_COMPLETED = 'completed';
const STATE_PAYOUT = 'payout';
const STATE_DECLINED = 'declined';
const STATE_CANCELED = 'canceled';
const STATE_EXPIRED = 'expired';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: config.bookingProcessAlias,

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_REQUEST_TO_BOOK]: STATE_CUSTOMER_REQUEST_BOOKING,
      },
    },
    [STATE_CUSTOMER_REQUEST_BOOKING]: {
      on: {
        [TRANSITION_DECLINE_REQUEST_BOOKING]: STATE_DECLINED,
        [TRANSITION_MAKE_OFFER]: STATE_OFFER_MADE,
      },
    },
    [STATE_OFFER_MADE]: {
      on: {
        [TRANSITION_MAKE_FIRST_PAYMENT]: STATE_PENDING_FIRST_PAYMENT,
        [TRANSITION_TO_FULL_PAYMENT_FLOW]: STATE_FULL_PAYMENT_FLOW,
        [TRANSITION_CUSTOMER_DECLINE_OFFER]: STATE_DECLINED,
        [TRANSITION_EXPIRE_REQUEST_BOOKING]: STATE_DECLINED,
      },
    },
    [STATE_FULL_PAYMENT_FLOW]: {
      on: {
        [TRANSITION_MAKE_FULL_PAYMENT]: STATE_PENDING_FULL_PAYMENT,
        [TRANSITION_EXPIRE_FULL_PAYMENT_FLOW]: STATE_EXPIRED,
        [TRANSITION_CUSTOMER_DECLINE_OFFER_FULL_PAYMENT]: STATE_CANCELED,
      },
    },
    [STATE_PENDING_FULL_PAYMENT]: {
      on: {
        [TRANSITION_CONFIRM_FULL_PAYMENT]: STATE_FULL_PAYMENT_CONFIRMED,
        [TRANSITION_EXPIRE_FULL_PAYMENT]: STATE_FULL_PAYMENT_FLOW,
      },
    },
    [STATE_FULL_PAYMENT_CONFIRMED]: {
      on: {
        [TRANSITION_CANCEL_FULL_PAYMENT]: STATE_CANCELED,
        [TRANSITION_ADMIN_CANCEL_FULL_PAYMENT]: STATE_CANCELED,
        [TRANSITION_START_EVENT_AFTER_FULL_PAYMENT]: STATE_ONGOING_EVENT,
      },
    },
    [STATE_ONGOING_EVENT]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_COMPLETED,
      },
    },
    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_REQUEST_DISPUTE]: STATE_DISPUTE,
        [TRANSITION_TO_PAYOUT_STATE]: STATE_PAYOUT,
      },
    },
    [STATE_DISPUTE]: {
      on: {
        [TRANSITION_EXPIRE_DISPUTE_AUTO_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_COMPLETE_AFTER_DISPUTE]: STATE_COMPLETED,
        [TRANSITION_ADMIN_COMPLETE_AFTER_DISPUTE]: STATE_COMPLETED,
      },
    },
    [STATE_PAYOUT]: {
      on: {
        [TRANSITION_PAYOUT_OFF_PLATFORM]: STATE_PAID_OUT,
        [TRANSITION_PAYOUT]: STATE_PAID_OUT,
      },
    },
    [STATE_PAID_OUT]: {
      on: {
        [TRANSITION_MARK_DELIVERED]: STATE_DELIVERED,
      },
    },
    [STATE_PENDING_FIRST_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_FIRST_PAYMENT]: STATE_OFFER_MADE,
        [TRANSITION_CONFIRM_FIRST_PAYMENT]: STATE_FIRST_PAYMENT_CONFIRMED,
      },
    },
    [STATE_FIRST_PAYMENT_CONFIRMED]: {
      on: {
        [TRANSITION_EXECUTE_FIRST_PAYOUT]: STATE_FIRST_PAYOUT_COMPLETED,
        [TRANSITION_ADMIN_CANCEL_FIRST_PAYMENT]: STATE_CANCELED,
        [TRANSITION_CANCEL_FIRST_PAYMENT]: STATE_CANCELED,
      },
    },
    [STATE_FIRST_PAYOUT_COMPLETED]: {
      on: {
        [TRANSITION_SEND_REMINDER_SECOND_PAYMENT]: STATE_SETUP_SECOND_PAYMENT,
        [TRANSITION_CANCEL_AFTER_FIRST_PAYOUT]: STATE_CANCELED,
      },
    },
    [STATE_SETUP_SECOND_PAYMENT]: {
      on: {
        [TRANSITION_CREATE_SECOND_PAYMENT_LINK]: STATE_PENDING_SECOND_PAYMENT,
        [TRANSITION_EXPIRE_CREATE_SECOND_PAYMENT_LINK]: STATE_EXPIRED,
      },
    },
    [STATE_PENDING_SECOND_PAYMENT]: {
      on: {
        [TRANSITION_PENDING_CONFIRM_SECOND_PAYMENT]: STATE_PENDING_CONFIRM_SECOND_PAYMENT,
        [TRANSITION_EXPIRE_PENDING_CONFIRM_SECOND_PAYMENT]: STATE_EXPIRED,
      },
    },
    [STATE_PENDING_CONFIRM_SECOND_PAYMENT]: {
      on: {
        [TRANSITION_CONFIRM_SECOND_PAYMENT]: STATE_SECOND_PAYMENT_CONFIRMED,
        [TRANSITION_EXPIRE_CONFIRM_SECOND_PAYMENT]: STATE_EXPIRED,
      },
    },
    [STATE_SECOND_PAYMENT_CONFIRMED]: {
      on: {
        [TRANSITION_START_EVENT]: STATE_ONGOING_EVENT,
      },
    },
    [STATE_CANCELED]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsBookingRequested = tx => txLastTransition(tx) === TRANSITION_REQUEST_TO_BOOK;
export const txIsOfferMade = tx => txLastTransition(tx) === TRANSITION_MAKE_OFFER;
export const txIsFirstPaymentPending = tx => txLastTransition(tx) === TRANSITION_MAKE_FIRST_PAYMENT;
export const txIsFirstPaymentConfirmed = tx =>
  txLastTransition(tx) === TRANSITION_CONFIRM_FIRST_PAYMENT;
export const txIsFirstPayoutExecuted = tx =>
  txLastTransition(tx) === TRANSITION_EXECUTE_FIRST_PAYOUT;
export const txIsSecondPaymentReminderSent = tx =>
  txLastTransition(tx) === TRANSITION_SEND_REMINDER_SECOND_PAYMENT;
export const txIsSecondPaymentLinkCreated = tx =>
  txLastTransition(tx) === TRANSITION_CREATE_SECOND_PAYMENT_LINK;
export const txIsCreateSecondPaymentLinkExpired = tx =>
  txLastTransition(tx) === TRANSITION_EXPIRE_CREATE_SECOND_PAYMENT_LINK;
export const txIsSecondPaymentConfirmPending = tx =>
  txLastTransition(tx) === TRANSITION_PENDING_CONFIRM_SECOND_PAYMENT;
export const txIsSecondPaymentConfirmPendingExpired = tx =>
  txLastTransition(tx) === TRANSITION_EXPIRE_PENDING_CONFIRM_SECOND_PAYMENT;
export const txIsSecondPaymentConfirmed = tx =>
  txLastTransition(tx) === TRANSITION_CONFIRM_SECOND_PAYMENT;
export const txIsSecondPaymentNotConfirmed = tx =>
  txLastTransition(tx) === TRANSITION_EXPIRE_CONFIRM_SECOND_PAYMENT;
export const txIsFullPaymentFlow = tx => txLastTransition(tx) === TRANSITION_TO_FULL_PAYMENT_FLOW;
export const txIsFullPaymentMade = tx => txLastTransition(tx) === TRANSITION_MAKE_FULL_PAYMENT;
export const txIsFullPaymentNotConfirmed = tx =>
  txLastTransition(tx) === TRANSITION_EXPIRE_FULL_PAYMENT;
export const txIsFullPaymentFlowExpired = tx =>
  txLastTransition(tx) === TRANSITION_EXPIRE_FULL_PAYMENT_FLOW;
export const txIsFullPaymentConfirmed = tx =>
  txLastTransition(tx) === TRANSITION_CONFIRM_FULL_PAYMENT;
export const txIsEventStarted = tx =>
  txLastTransition(tx) === TRANSITION_START_EVENT ||
  txLastTransition(tx) === TRANSITION_START_EVENT_AFTER_FULL_PAYMENT;
export const txIsDisputeRequested = tx => txLastTransition(tx) === TRANSITION_REQUEST_DISPUTE;
export const txIsDisputeCompleted = tx =>
  txLastTransition(tx) === TRANSITION_ADMIN_COMPLETE_AFTER_DISPUTE ||
  txLastTransition(tx) === TRANSITION_COMPLETE_AFTER_DISPUTE ||
  txLastTransition(tx) === TRANSITION_EXPIRE_DISPUTE_AUTO_COMPLETE;
export const txIsEventCompleted = tx =>
  txLastTransition(tx) === TRANSITION_COMPLETE ||
  txLastTransition(tx) === TRANSITION_TO_PAYOUT_STATE;
export const txIsPaidOut = tx =>
  txLastTransition(tx) === TRANSITION_PAYOUT ||
  txLastTransition(tx) === TRANSITION_PAYOUT_OFF_PLATFORM;

const CANCELLED_TRANSITIONS = [
  TRANSITION_ADMIN_CANCEL_AFTER_DISPUTE,
  TRANSITION_ADMIN_CANCEL_FULL_PAYMENT_AFTER_DISPUTE,
  TRANSITION_ADMIN_CANCEL_FULL_PAYMENT,
  TRANSITION_ADMIN_CANCEL_FIRST_PAYMENT,
  TRANSITION_CANCEL_AFTER_FIRST_PAYOUT,
  TRANSITION_CANCEL_FIRST_PAYMENT,
  TRANSITION_CANCEL_FULL_PAYMENT,
];

export const txIsCanceled = tx => CANCELLED_TRANSITIONS.includes(txLastTransition(tx));

const DECLINED_TRANSITIONS = [
  TRANSITION_DECLINE_REQUEST_BOOKING,
  TRANSITION_CUSTOMER_DECLINE_OFFER,
  TRANSITION_CUSTOMER_DECLINE_OFFER_FULL_PAYMENT,
];

export const txIsBookingDeclined = tx => DECLINED_TRANSITIONS.includes(txLastTransition(tx));

const EXPIRED_TRANSITIONS = [
  TRANSITION_EXPIRE_CONFIRM_SECOND_PAYMENT,
  TRANSITION_EXPIRE_CREATE_SECOND_PAYMENT_LINK,
  TRANSITION_EXPIRE_FULL_PAYMENT_FLOW,
  TRANSITION_EXPIRE_REQUEST_BOOKING,
  TRANSITION_EXPIRE_PENDING_CONFIRM_SECOND_PAYMENT,
];

export const txIsBookingExpired = tx => EXPIRED_TRANSITIONS.includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  txLastTransition(tx) === TRANSITION_PAYOUT ||
  txLastTransition(tx) === TRANSITION_PAYOUT_OFF_PLATFORM ||
  txLastTransition(tx) === TRANSITION_MARK_DELIVERED;

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_REQUEST_TO_BOOK,
    TRANSITION_MAKE_OFFER,
    TRANSITION_DECLINE_REQUEST_BOOKING,
    TRANSITION_CUSTOMER_DECLINE_OFFER,
    TRANSITION_EXPIRE_REQUEST_BOOKING,
    TRANSITION_TO_FULL_PAYMENT_FLOW,
    TRANSITION_CONFIRM_FIRST_PAYMENT,
    TRANSITION_EXPIRE_FIRST_PAYMENT,
    TRANSITION_ADMIN_CANCEL_FIRST_PAYMENT,
    TRANSITION_CANCEL_FIRST_PAYMENT,
    TRANSITION_CANCEL_AFTER_FIRST_PAYOUT,
    TRANSITION_CREATE_SECOND_PAYMENT_LINK,
    TRANSITION_PENDING_CONFIRM_SECOND_PAYMENT,
    TRANSITION_CONFIRM_SECOND_PAYMENT,
    TRANSITION_START_EVENT,
    TRANSITION_EXPIRE_FULL_PAYMENT,
    TRANSITION_CUSTOMER_DECLINE_OFFER_FULL_PAYMENT,
    TRANSITION_EXPIRE_FULL_PAYMENT_FLOW,
    TRANSITION_CONFIRM_FULL_PAYMENT,
    TRANSITION_EXPIRE_CREATE_SECOND_PAYMENT_LINK,
    TRANSITION_START_EVENT_AFTER_FULL_PAYMENT,
    TRANSITION_ADMIN_CANCEL_FULL_PAYMENT,
    TRANSITION_ADMIN_COMPLETE_AFTER_DISPUTE,
    TRANSITION_COMPLETE_AFTER_DISPUTE,
    TRANSITION_EXPIRE_DISPUTE_AUTO_COMPLETE,
    TRANSITION_REQUEST_DISPUTE,
    TRANSITION_ADMIN_CANCEL_AFTER_DISPUTE,
    TRANSITION_ADMIN_CANCEL_FULL_PAYMENT_AFTER_DISPUTE,
    TRANSITION_MARK_DELIVERED,
    TRANSITION_COMPLETE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_REQUEST_TO_BOOK,
    TRANSITION_MAKE_OFFER,
    TRANSITION_MAKE_FIRST_PAYMENT,
    TRANSITION_MAKE_FULL_PAYMENT,
    TRANSITION_CREATE_SECOND_PAYMENT_LINK,
    TRANSITION_CONFIRM_SECOND_PAYMENT,
    TRANSITION_PAYOUT_OFF_PLATFORM,
  ].includes(transition);
};

export const txIsPaymentPending = tx =>
  [
    TRANSITION_MAKE_FIRST_PAYMENT,
    TRANSITION_MAKE_FULL_PAYMENT,
    TRANSITION_CREATE_SECOND_PAYMENT_LINK,
    TRANSITION_PENDING_CONFIRM_SECOND_PAYMENT
  ].includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  [
    TRANSITION_MAKE_OFFER,
    TRANSITION_TO_FULL_PAYMENT_FLOW,
    TRANSITION_CONFIRM_FIRST_PAYMENT,
    TRANSITION_EXECUTE_FIRST_PAYOUT,
    TRANSITION_SEND_REMINDER_SECOND_PAYMENT,
    TRANSITION_CREATE_SECOND_PAYMENT_LINK,
    TRANSITION_START_EVENT,
    TRANSITION_START_EVENT_AFTER_FULL_PAYMENT,
    TRANSITION_ADMIN_COMPLETE_AFTER_DISPUTE,
    TRANSITION_COMPLETE_AFTER_DISPUTE,
    TRANSITION_REQUEST_DISPUTE,
  ].includes(txLastTransition(tx));

export const txIsPayoutStates = tx =>
  [TRANSITION_TO_PAYOUT_STATE, TRANSITION_PAYOUT_OFF_PLATFORM, TRANSITION_PAYOUT].includes(
    txLastTransition(tx)
  );

export const hasTransition = (txTransitions, transition) => {
  return txTransitions.some(tx => tx.transition === transition);
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import step1 from './images/step1.png';
import step2 from './images/step2.png';
import step3 from './images/step3.png';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" tagName=""/>
        <br />
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" tagName=""/>
      </h2>

      <div className={css.steps}>
        <div className={css.step}>
          <div className={css.stepImage}>
            <img src={step1} />
          </div>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" tagName=""/>
          </h3>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text1" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text2" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text3" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.stepImage}>
            <img src={step2} />
          </div>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" tagName=""/>
          </h3>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text1" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text2" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text3" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.stepImage}>
            <img src={step3} />
          </div>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" tagName=""/>
          </h3>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text1" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text2" />
          </p>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text3" />
          </p>
        </div>
      </div>

      <div className={css.createListingLink}>
        <NamedLink name="NewListingPage">
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;

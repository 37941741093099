import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ValidationError } from '..';
import { FormattedMessage } from 'react-intl';

import css from './FieldCustomLocationSelect.module.css';

const predictionStyles = {
  position: 'absolute',
  left: '0',
  width: '100%',
  borderBottomLeftRadius: 'var(--borderRadius)',
  borderBottomRightRadius: 'var(--borderRadius)',
  backgroundColor: 'var(--marketplaceColor)',
  boxShadow: 'var(--boxShadowPopup)',
  zIndex: 'calc(var(--zIndexPopup) + 1)',
};

const mobilePredictionsStyles = {
  ...predictionStyles,
  top: `calc(var(--topbarHeightDesktop) - var(--TopbarSearchForm_bottomBorder))`,
  minHeight: `calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  )`,
  height: '100%',
  paddingBottom: 'var(--locationAutocompleteBottomPadding)',
};

const desktopPredictionsStyles = {
  ...predictionStyles,
  top: '50px',
  marginTop: `calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  )`,
};

const NoOptionsMessage = props => (
  <components.NoOptionsMessage {...props}>
    <FormattedMessage
      id={props.selectProps.noOptionMessageId || 'FieldCustomLocationSelect.defaultNoOptionMessage'}
    />
  </components.NoOptionsMessage>
);

const FieldCustomLocationSelectComponent = props => {
  const {
    rootClassName,
    className,
    iconClassName,
    inputClassName,
    description,
    id,
    label,
    input,
    meta,
    options,
    isCreatable,
    isMobile,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  // const hasError = touched && invalid && error;
  // const isValid = valid && touched;

  //custom styles of components inside Select
  const customStyles = {
    container: () => ({ position: 'static', color: 'var(--matterColorLight)' }),
    control: () => {}, //clear default styles
    valueContainer: styles => ({
      ...styles,
      height: 'var(--topbarHeightDesktop)',
      'div': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    }), //apply custom styles in default style of components
    option: styles => ({
      ...styles,
      backgroundColor: 'var(--marketplaceColorLDark)',
      whiteSpace: 'break-spaces',
      '&:hover': {
        backgroundColor: 'var(--marketplaceColorDark)',
      },
      '&:focus-visible': {
        backgroundColor: 'var(--marketplaceColorDark)',
      },
    }),
    placeholder: styles => ({
      ...styles,
      color: 'var(--matterColorAnti)',
      fontWeight: 'var(--fontWeightMedium)',
    }),
    menu: () => ({ ...(isMobile ? mobilePredictionsStyles : desktopPredictionsStyles) }),
    menuList: styles => ({ ...styles, ...(isMobile ? { maxHeight: '100%' } : {}) }),
    noOptionsMessage: styles => ({ ...styles, color: 'var(--matterColorLight' }),
  };

  const rootClasses = classNames(rootClassName || css.root, className);
  const inputClasses = classNames(inputClassName || css.input);

  const selectProps = {
    className: inputClasses,
    id,
    options,
    styles: customStyles,
    components: {
      IndicatorSeparator: () => null,
      IndicatorsContainer: () => null,
      NoOptionsMessage,
    },
    ...input,
    ...meta,
    ...rest,
  };

  return options ? (
    <div className={rootClasses}>
      {isCreatable ? <CreatableSelect {...selectProps} /> : <Select {...selectProps} />}
      <ValidationError fieldMeta={meta} />
    </div>
  ) : null;
};

FieldCustomLocationSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  iconClassName: '',
  inputClassName: '',
  predictionsClassName: '',

  id: null,
  label: null,
  children: null,
  isClearable: false,
  isSearchable: false,
  isCreatable: false,
};

const { string, object, node, bool } = PropTypes;

FieldCustomLocationSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  iconClassName: string,
  inputClassName: string,
  predictionsClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,

  description: string,

  isClearable: bool,
  isSearchable: bool,
  isCreatable: bool,
};

const FieldCustomLocationSelect = props => {
  return <Field component={FieldCustomLocationSelectComponent} {...props} />;
};

export default FieldCustomLocationSelect;

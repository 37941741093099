/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
//helping variable for the search features
//TODO: fix this if has any change about the state & city properties in the listing public Data
export const locationQueryParams = {
  state: { queryParamNames: ['pub_workStates'], searchMode: 'has_any' },
  city: {
    queryParamNames: ['pub_workCities'],
    searchMode: 'has_any',
  },
};

export const filters = [
  {
    id: 'mobileBarTypes',
    label: 'Mobile Bar Types',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['meta_mobileBarTypes'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'mul_enum',
      searchMode: 'has_any',
      options: [
        { key: 'horse_trailer_bar', label: 'Horse Trailer Bar' },
        { key: 'vintage_vehicle', label: 'Vintage Vehicle' },
        { key: 'converted_camping_trailer', label: 'Converted Camping Trailer' },
        { key: 'airstream_bar', label: 'Airstream Bar' },
        { key: 'bicycle_bar', label: 'Bicycle Bar' },
        { key: 'bar_cart', label: 'Bar Cart' },
        { key: 'tap_truck', label: 'Tap Truck' },
        { key: 'piaggio_ape_bar', label: 'Piaggio Ape Bar' },
        { key: 'photo_booth', label: 'Photo Booth' },
        { key: 'photo_prop', label: 'Photo Prop' },
        { key: 'satellite_bar', label: 'Satellite Bar' },
        { key: 'coffee_cart', label: 'Coffee Cart' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'secondary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'secondary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'catering',
    label: 'Catering Options',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_cateringOptions'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'alcoholic_drinks',
          label: 'Alcoholic drinks',
        },
        {
          key: 'non_alcoholic_drinks',
          label: 'Non-alcoholic drinks',
        },
        {
          key: 'cocktails',
          label: 'Cocktails',
        },
        {
          key: 'mocktails',
          label: 'Mocktails',
        },
        {
          key: 'hot_beverages',
          label: 'Hot beverages',
        },
        {
          key: 'food_by_bar',
          label: 'Food (provided by mobile bar)',
        },
        {
          key: 'food_by_organizer',
          label: 'Food (provided by event organizer)',
        },
        {
          key: 'photo_booth',
          label: 'Photo booth (guests allowed inside vehicle)',
        },
        {
          key: 'photo_prop',
          label: 'Photo prop (guests to remain outside of vehicle)',
        },
      ],
    },
  },
  {
    id: 'service',
    label: 'Service Options',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_serviceOptions'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'bar_staff_included',
          label: 'Bar staff included',
        },
        {
          key: 'bar_staff_extra',
          label: 'Bar staff provided for extra fee',
        },
        {
          key: 'self_served',
          label: 'Guest self-serve only',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
  ],
};

import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  Accordion,
  FieldCustomMultipleSelect,
} from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import css from './EditListingExtraInfoForm.module.css';
import { isEqual } from 'lodash';

export const EditListingExtraInfoFormComponent = props => (
  <FinalForm
    mutators={{
      ...arrayMutators,
    }}
    {...props}
    initialValuesEqual={isEqual}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form: {
          mutators: { push },
        },
        values,
      } = formRenderProps;

      const addOnLabelMessage = intl.formatMessage({
        id: 'EditListingExtraInfoForm.addOnLabel',
      });
      const addOnPlaceholder = intl.formatMessage({
        id: 'EditListingExtraInfoForm.addOnPlaceholder',
      });

      const faqLabel = intl.formatMessage({
        id: 'EditListingExtraInfoForm.faqLabel',
      });
      const faqNoteMessage = intl.formatMessage({
        id: 'EditListingExtraInfoForm.faqNoteMessage',
      });

      const addQuestionLabel = intl.formatMessage({
        id: 'EditListingExtraInfoForm.addQuestionLabel',
      });
      const removeQuestionLabel = intl.formatMessage({
        id: 'EditListingExtraInfoForm.removeQuestionLabel',
      });

      const questionLabel = intl.formatMessage({
        id: 'EditListingExtraInfoForm.questionLabel',
      });
      const questionRequiredMessage = intl.formatMessage({
        id: 'EditListingExtraInfoForm.questionRequiredMessage',
      });

      const answerLabel = intl.formatMessage({
        id: 'EditListingExtraInfoForm.answerLabel',
      });
      const answerRequiredMessage = intl.formatMessage({
        id: 'EditListingExtraInfoForm.answerRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExtraInfoForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExtraInfoForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCustomMultipleSelect
            id="addOns"
            name="addOns"
            className={css.policy}
            isCreatable
            label={addOnLabelMessage}
            placeholder={addOnPlaceholder}
            options={[]}
          />

          <h4 className={css.label}>{faqLabel}</h4>
          <p className={css.description}>{faqNoteMessage}</p>
          <button
            type="button"
            className={css.addFAQButton}
            onClick={() =>
              push('faqs', {
                question: '',
                answer: '',
              })
            }
          >
            {addQuestionLabel}
          </button>
          <FieldArray name="faqs">
            {({ fields }) => {
              return fields.map((name, index) => (
                <div key={name} className={css.faqContainer}>
                  <Accordion
                    title={values.faqs[index]?.question || `Question #${index + 1}`}
                    className={css.faq}
                  >
                    <FieldTextInput
                      id={`${name}.question`}
                      name={`${name}.question`}
                      className={css.policy}
                      label={questionLabel}
                      type="text"
                      validate={required(questionRequiredMessage)}
                    />

                    <FieldTextInput
                      id={`${name}.answer`}
                      name={`${name}.answer`}
                      className={css.policy}
                      type="textarea"
                      label={answerLabel}
                      validate={required(answerRequiredMessage)}
                    />
                  </Accordion>
                  <button
                    type="button"
                    className={css.removeFAQButton}
                    onClick={() => fields.remove(index)}
                  >
                    {removeQuestionLabel}
                  </button>
                </div>
              ));
            }}
          </FieldArray>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingExtraInfoFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingExtraInfoFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingExtraInfoFormComponent);

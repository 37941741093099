import { CANADA_CITIES, CANADA_PROVINCES, US_CITIES, US_STATES } from './locations';

export const ALL_STATES = [...US_STATES, ...CANADA_PROVINCES];
export const ALL_CITIES = [...US_CITIES, ...CANADA_CITIES];
export const ALL_LOCATIONS = [...ALL_CITIES, ...ALL_STATES];

export const searchLocation = (searchArray, key) => {
  return searchArray?.find(item => item.value === key);
};

export const getCitiesOfRegion = (searchArray, state) =>
  searchArray?.filter(city => city.state === state);

export const mapSelectOptionsToPublicDataValue = selectOptions =>
  selectOptions?.map(option => option.value);

export const mapArrayToSelectOptions = (dataArray, options = ALL_LOCATIONS) =>
  dataArray
    ? dataArray.map(option => {
        return searchLocation(options, option) || {};
      })
    : [];

import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';

import css from './Carousel.module.css';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={css.button}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <AiOutlineArrowLeft />
  </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={css.button}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount ? true : false}
    type="button"
  >
    <AiOutlineArrowRight />
  </button>
);

const Carousel = props => {
  const { className, rootClassName, children, ...componentProps } = props;

  const classes = rootClassName || classNames(className, css.container);

  return (
    <Slider
      className={classes}
      nextArrow={<SlickArrowRight />}
      prevArrow={<SlickArrowLeft />}
      {...componentProps}
    >
      {children}
    </Slider>
  );
};

Carousel.propTypes = {
  className: string,
  rootClassName: string,
  children: node,
};

export default Carousel;

import config from "../config";

const parseCookie = str =>
  str
  .split(';')
  .map(v => v.split('='))
  .reduce((acc, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {});

export function getFlexToken() {
  const key = `st-${config.sdk.clientId}-token`;

  const cookie = parseCookie(document.cookie) ?? "";

  return cookie ? JSON.parse(cookie[key]) : null;
}
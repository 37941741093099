import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Button, FieldCustomLocationSelect, Form } from '../../components';
import { ALL_CITIES, ALL_STATES } from '../../util/locationHelpers';
import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';
import { findSelectOptions } from '../../util/search';
import { MAX_MOBILE_SCREEN_WIDTH, MAX_TABLET_SCREEN_WIDTH } from '../../constants';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';

import css from './TopbarSearchForm.module.css';

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewport } = this.props;
    const isTablet =
      viewport.width < MAX_TABLET_SCREEN_WIDTH && viewport.width > MAX_MOBILE_SCREEN_WIDTH;

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            values,
            initialValues,
            helpText,
            handleSubmit,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const stateSearchFiledPlaceholderId = isTablet
            ? 'TopbarSearchForm.mobileStatePlaceholder'
            : 'TopbarSearchForm.desktopStatePlaceholder';
          const citySearchFiledPlaceholderId = isTablet
            ? 'TopbarSearchForm.mobileCityPlaceholder'
            : 'TopbarSearchForm.desktopCityPlaceholder';
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          const { state } = values || initialValues;
          const cityOptions = state?.value
            ? findSelectOptions(ALL_CITIES, 'state', state.value)
            : [];
          const submitSearchButton = isMobile ? (
            <Button className={css.mobileSearchButton} type="submit">
              <FormattedMessage id="TopbarSearchForm.searchButton" />
            </Button>
          ) : (
            <Button className={css.desktopIconButton} type="submit">
              <IconHourGlass />
            </Button>
          );

          return (
            <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="SearchPage">
              <FieldCustomLocationSelect
                className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                placeholder={intl.formatMessage({ id: stateSearchFiledPlaceholderId })}
                isMobile={isMobile}
                isSearchable
                name="state"
                options={ALL_STATES}
              />
              <FieldCustomLocationSelect
                className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                placeholder={intl.formatMessage({ id: citySearchFiledPlaceholderId })}
                isMobile={isMobile}
                isSearchable
                name="city"
                options={cityOptions}
                noOptionMessageId="TopbarSearchForm.stateNotFound"
              />
              {helpText}
              {submitSearchButton}
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = compose(
  withViewport,
  injectIntl
)(TopbarSearchFormComponent);

export default TopbarSearchForm;

import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { compose } from 'redux';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingServicesForm.module.css';

const EditListingServicesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        invalid,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;

      const cateringOptionsLabel = intl.formatMessage({
        id: 'EditListingServicesForm.cateringOptionsLabel',
      });
      const cateringOptionsRequiredMessage = intl.formatMessage({
        id: 'EditListingServicesForm.cateringOptionsRequiredMessage',
      });
      
      const serviceOptionsLabel = intl.formatMessage({
        id: 'EditListingServicesForm.serviceOptionsLabel',
      });
      const serviceOptionsRequiredMessage = intl.formatMessage({
        id: 'EditListingServicesForm.serviceOptionsRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingServicesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingServicesForm.showListingFailed" />
        </p>
      ) : null;

      const cateringOptions = findOptionsForSelectFilter('catering', filterConfig);
      const serviceOptions = findOptionsForSelectFilter('service', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id='cateringOptions'
            name='cateringOptions'
            label={cateringOptionsLabel}
            options={cateringOptions}
            validate={requiredFieldArrayCheckbox(cateringOptionsRequiredMessage)}
          />

          <FieldCheckboxGroup
            className={css.features}
            id='serviceOptions'
            name='serviceOptions'
            label={serviceOptionsLabel}
            options={serviceOptions}
            validate={requiredFieldArrayCheckbox(serviceOptionsRequiredMessage)}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingServicesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingServicesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
  intl: intlShape,
};

const EditListingServicesForm = EditListingServicesFormComponent;

export default compose(injectIntl)(EditListingServicesForm);

import { bool } from 'prop-types';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';

const { Money } = sdkTypes;

import { getProcessDisplayPriceFnc } from '../../util/lineItemHelpers';
import {
  TRANSITION_TO_FULL_PAYMENT_FLOW,
  hasTransition,
  txIsBookingRequested,
  txLastTransition,
} from '../../util/transaction';

import css from './BookingBreakdown.module.css';


const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl } = props;

  const {
    metadata: txMetadata = {},
    transitions,
    protectedData: txProtectedData,
  } = transaction.attributes;

  const processDisplayAmount = getProcessDisplayPriceFnc(transaction);

  const { isFullPaymentFlow, secondHalfPaid } = txMetadata;

  const totalLabel = isProvider ? (
    <FormattedMessage id='BookingBreakdown.providerTotalDefault' />
  ) : isFullPaymentFlow || txIsBookingRequested(transaction) ? (
    <FormattedMessage id="BookingBreakdown.total" />
  ) : (
    <FormattedMessage id="BookingBreakdown.totalFirstPayment" />
  );

  const remainingBalanceLabel = intl.formatMessage({
    id: 'BookingBreakdown.remainingBalance',
  });

  const secondPaymentLabel = intl.formatMessage({
    id: 'BookingBreakdown.secondPayment',
  });

  const totalFinalLabel = intl.formatMessage({
    id: 'BookingBreakdown.totalFinalLabel',
  });

  const { payoutTotal, payinTotal } = transaction.attributes;

  const totalPrice = isProvider ? payoutTotal : payinTotal;

  const displayTotalPrice = new Money(processDisplayAmount(totalPrice.amount), totalPrice.currency);

  // If it's not full payment flow, display the remaining balance
  const shouldDisplayRemainingBalance =
    !hasTransition(transitions, TRANSITION_TO_FULL_PAYMENT_FLOW) &&
    !isFullPaymentFlow &&
    !txIsBookingRequested(transaction);

  // Second half payment total (if any)
  const remainingPayment = shouldDisplayRemainingBalance
    ? new Money(txProtectedData.txTotalPrice.amount - payinTotal.amount, totalPrice.currency)
    : null;
  const remainingPaymentLabel = remainingPayment
    ? secondHalfPaid
      ? secondPaymentLabel
      : remainingBalanceLabel
    : null;

  const finalTotalPrice = remainingPayment
    ? new Money(payinTotal.amount + remainingPayment.amount, totalPrice.currency)
    : displayTotalPrice;

  return !txIsBookingRequested(transaction) ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formatMoney(intl, displayTotalPrice)}</div>
      </div>
      {remainingPayment && (
        <>
          <div className={css.lineItem}>
            <div className={css.itemLabel}>{remainingPaymentLabel}</div>
            <div className={css.itemValue}>{formatMoney(intl, remainingPayment)}</div>
          </div>
          <div className={css.lineItemTotal}>
            <div className={css.totalLabel}>{totalFinalLabel}</div>
            <div className={css.totalPrice}>{formatMoney(intl, finalTotalPrice)}</div>
          </div>
        </>
      )}
    </>
  ) : null;
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;

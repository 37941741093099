import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink, Carousel } from '../../components';

import css from './SectionForOwners.module.css';

import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import image4 from './images/image4.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const images = [image1, image2, image3, image4];

const SectionForOwners = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionForOwners.title" tagName=""/>
      </h2>
      <div className={css.content}>
        <div className={css.list}>
          <p>
            <FormattedMessage id="SectionForOwners.listItem1" />
          </p>
          <p>
            <FormattedMessage id="SectionForOwners.listItem2" />
          </p>
          <p>
            <FormattedMessage id="SectionForOwners.listItem3" />
          </p>
          <p>
            <FormattedMessage id="SectionForOwners.listItem4" />
          </p>
        </div>
        <div className={css.locations}>
        <Carousel
          className={css.swiperContainer}
          draggable={false}
          infinite={false}
          dots
          slidesToShow={1}
        >
            {images.map((image, index) => {
              return (
                <div key={index} className={css.location}>
                  <div className={css.imageWrapper}>
                    <div className={css.aspectWrapper}>
                      <LazyImage
                        src={image}
                        alt={`For Owners Section picture #${index + 1}`}
                        className={css.locationImage}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>

      <NamedLink name="NewListingPage" className={css.outsideButton}>
        <FormattedMessage id="SectionForOwners.addYourBar" />
      </NamedLink>
    </div>
  );
};

SectionForOwners.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForOwners.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForOwners;

import React, { Component } from 'react';
import PropTypes, { number, shape } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { NamedLink, Carousel } from '../../components';
import { viewportMedium } from '../../util/viewportHelpers';
import { compose } from 'redux';

import css from './SectionMobileBarTypes.module.css';

import airstreamBarsImage from './images/type_airstreamBars.jpg';
import convertedCamperBarsImage from './images/type_convertedCamperBars.jpg';
import horseTrailerBarsImage from './images/type_horseTrailerBars.jpg';
import mobileCoffeeBarsImage from './images/type_mobileCoffeeBars.jpg';
import vintageVehiclesImage from './images/type_vintageVehicles.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const mobileBarTypeLink = ({ name, image, searchQuery }) => {
  const nameText = <h3 className={css.locationName}>{name}</h3>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        {nameText}
      </div>
    </NamedLink>
  );
};

const mobileBarTypes = [
  {
    name: 'Horse trailer bars',
    image: horseTrailerBarsImage,
    searchQuery: '?meta_mobileBarTypes=has_any%3Ahorse_trailer_bar',
  },
  {
    name: 'Airstream bars',
    image: airstreamBarsImage,
    searchQuery: '?meta_mobileBarTypes=has_any%3Aairstream_bar',
  },
  {
    name: 'Mobile coffee carts',
    image: mobileCoffeeBarsImage,
    searchQuery: '?meta_mobileBarTypes=has_any%3Acoffee_cart',
  },
  {
    name: 'Vintage vehicles',
    image: vintageVehiclesImage,
    searchQuery: '?meta_mobileBarTypes=has_any%3Avintage_vehicle',
  },
  {
    name: 'Converted camping trailers',
    image: convertedCamperBarsImage,
    searchQuery: '?meta_mobileBarTypes=has_any%3Aconverted_camping_trailer',
  },
];

const SectionMobileBarTypes = props => {
  const { rootClassName, className, viewport } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionMobileBarTypes.title" tagName=""/>
      </h2>
      <div className={css.locations}>
      <Carousel
          className={css.swiperContainer}
          draggable={false}
          infinite={false}
          dots
          slidesToShow={viewport.width < viewportMedium ? 1 : 3}
        >
          {mobileBarTypes.map(location => {
            return (
              <div key={location.searchQuery} className={css.swiperSlide}>{mobileBarTypeLink({ ...location })}</div>
            );
          })}
            <div className={css.endOfSlideContainer}>
              <NamedLink name="SearchPage" className={css.endOfSlideButton}>
                <FormattedMessage id="SectionMobileBarTypes.seeAllLocations" />
              </NamedLink>
            </div>
        </Carousel>
      </div>
      <NamedLink name="SearchPage" className={css.outsideButton}>
        <FormattedMessage id="SectionMobileBarTypes.seeAllLocations" />
      </NamedLink>
    </div>
  );
};

SectionMobileBarTypes.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionMobileBarTypes.propTypes = {
  rootClassName: string,
  className: string,

  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default compose(withViewport)(SectionMobileBarTypes);

export const US_STATES = [
  { value: 'US-AL', label: 'USA - Alabama' },

  { value: 'US-AK', label: 'USA - Alaska' },

  { value: 'US-AZ', label: 'USA - Arizona' },

  { value: 'US-AR', label: 'USA - Arkansas' },

  { value: 'US-CA', label: 'USA - California' },

  { value: 'US-CO', label: 'USA - Colorado' },

  { value: 'US-CT', label: 'USA - Connecticut' },

  { value: 'US-DE', label: 'USA - Delaware' },

  { value: 'US-FL', label: 'USA - Florida' },

  { value: 'US-GA', label: 'USA - Georgia' },

  { value: 'US-HI', label: 'USA - Hawaii' },

  { value: 'US-ID', label: 'USA - Idaho' },

  { value: 'US-IL', label: 'USA - Illinois' },

  { value: 'US-IN', label: 'USA - Indiana' },

  { value: 'US-IA', label: 'USA - Iowa' },

  { value: 'US-KS', label: 'USA - Kansas' },

  { value: 'US-KY', label: 'USA - Kentucky' },

  { value: 'US-LA', label: 'USA - Louisiana' },

  { value: 'US-ME', label: 'USA - Maine' },

  { value: 'US-MD', label: 'USA - Maryland' },

  { value: 'US-MA', label: 'USA - Massachusetts' },

  { value: 'US-MI', label: 'USA - Michigan' },

  { value: 'US-MN', label: 'USA - Minnesota' },

  { value: 'US-MS', label: 'USA - Mississippi' },

  { value: 'US-MO', label: 'USA - Missouri' },

  { value: 'US-MT', label: 'USA - Montana' },

  { value: 'US-NE', label: 'USA - Nebraska' },

  { value: 'US-NV', label: 'USA - Nevada' },

  { value: 'US-NH', label: 'USA - New Hampshire' },

  { value: 'US-NJ', label: 'USA - New Jersey' },

  { value: 'US-NM', label: 'USA - New Mexico' },

  { value: 'US-NY', label: 'USA - New York' },

  { value: 'US-NC', label: 'USA - North Carolina' },

  { value: 'US-ND', label: 'USA - North Dakota' },

  { value: 'US-OH', label: 'USA - Ohio' },

  { value: 'US-OK', label: 'USA - Oklahoma' },

  { value: 'US-OR', label: 'USA - Oregon' },

  { value: 'US-PA', label: 'USA - Pennsylvania' },

  { value: 'US-RI', label: 'USA - Rhode Island' },

  { value: 'US-SC', label: 'USA - South Carolina' },

  { value: 'US-SD', label: 'USA - South Dakota' },

  { value: 'US-TN', label: 'USA - Tennessee' },

  { value: 'US-TX', label: 'USA - Texas' },

  { value: 'US-UT', label: 'USA - Utah' },

  { value: 'US-VT', label: 'USA - Vermont' },

  { value: 'US-VI', label: 'USA - Virginia' },

  { value: 'US-WA', label: 'USA - Washington' },

  { value: 'US-WV', label: 'USA - West Virginia' },

  { value: 'US-WI', label: 'USA - Wisconsin' },

  { value: 'US-WY', label: 'USA - Wyoming' },
];

export const US_CITIES = [
  { value: 'AL_alexander_city', label: 'AL - Alexander City', state: 'US-AL' },
  { value: 'AL_andalusia', label: 'AL - Andalusia', state: 'US-AL' },
  { value: 'AL_anniston', label: 'AL - Anniston', state: 'US-AL' },
  { value: 'AL_athens', label: 'AL - Athens', state: 'US-AL' },
  { value: 'AL_atmore', label: 'AL - Atmore', state: 'US-AL' },
  { value: 'AL_auburn', label: 'AL - Auburn', state: 'US-AL' },
  { value: 'AL_bessemer', label: 'AL - Bessemer', state: 'US-AL' },
  { value: 'AL_birmingham', label: 'AL - Birmingham', state: 'US-AL' },
  { value: 'AL_chickasaw', label: 'AL - Chickasaw', state: 'US-AL' },
  { value: 'AL_clanton', label: 'AL - Clanton', state: 'US-AL' },
  { value: 'AL_cullman', label: 'AL - Cullman', state: 'US-AL' },
  { value: 'AL_decatur', label: 'AL - Decatur', state: 'US-AL' },
  { value: 'AL_demopolis', label: 'AL - Demopolis', state: 'US-AL' },
  { value: 'AL_dothan', label: 'AL - Dothan', state: 'US-AL' },
  { value: 'AL_enterprise', label: 'AL - Enterprise', state: 'US-AL' },
  { value: 'AL_eufaula', label: 'AL - Eufaula', state: 'US-AL' },
  { value: 'AL_florence', label: 'AL - Florence', state: 'US-AL' },
  { value: 'AL_fort_payne', label: 'AL - Fort Payne', state: 'US-AL' },
  { value: 'AL_gadsden', label: 'AL - Gadsden', state: 'US-AL' },
  { value: 'AL_greenville', label: 'AL - Greenville', state: 'US-AL' },
  { value: 'AL_guntersville', label: 'AL - Guntersville', state: 'US-AL' },
  { value: 'AL_huntsville', label: 'AL - Huntsville', state: 'US-AL' },
  { value: 'AL_jasper', label: 'AL - Jasper', state: 'US-AL' },
  { value: 'AL_marion', label: 'AL - Marion', state: 'US-AL' },
  { value: 'AL_mobile', label: 'AL - Mobile', state: 'US-AL' },
  { value: 'AL_montgomery', label: 'AL - Montgomery', state: 'US-AL' },
  { value: 'AL_opelika', label: 'AL - Opelika', state: 'US-AL' },
  { value: 'AL_ozark', label: 'AL - Ozark', state: 'US-AL' },
  { value: 'AL_phenix_city', label: 'AL - Phenix City', state: 'US-AL' },
  { value: 'AL_prichard', label: 'AL - Prichard', state: 'US-AL' },
  { value: 'AL_scottsboro', label: 'AL - Scottsboro', state: 'US-AL' },
  { value: 'AL_selma', label: 'AL - Selma', state: 'US-AL' },
  { value: 'AL_sheffield', label: 'AL - Sheffield', state: 'US-AL' },
  { value: 'AL_sylacauga', label: 'AL - Sylacauga', state: 'US-AL' },
  { value: 'AL_talladega', label: 'AL - Talladega', state: 'US-AL' },
  { value: 'AL_troy', label: 'AL - Troy', state: 'US-AL' },
  { value: 'AL_tuscaloosa', label: 'AL - Tuscaloosa', state: 'US-AL' },
  { value: 'AL_tuscumbia', label: 'AL - Tuscumbia', state: 'US-AL' },
  { value: 'AL_tuskegee', label: 'AL - Tuskegee', state: 'US-AL' },

  { value: 'AK_anchorage', label: 'AK - Anchorage', state: 'US-AK' },
  { value: 'AK_cordova', label: 'AK - Cordova', state: 'US-AK' },
  { value: 'AK_fairbanks', label: 'AK - Fairbanks', state: 'US-AK' },
  { value: 'AK_haines', label: 'AK - Haines', state: 'US-AK' },
  { value: 'AK_homer', label: 'AK - Homer', state: 'US-AK' },
  { value: 'AK_juneau', label: 'AK - Juneau', state: 'US-AK' },
  { value: 'AK_ketchikan', label: 'AK - Ketchikan', state: 'US-AK' },
  { value: 'AK_kodiak', label: 'AK - Kodiak', state: 'US-AK' },
  { value: 'AK_kotzebue', label: 'AK - Kotzebue', state: 'US-AK' },
  { value: 'AK_nome', label: 'AK - Nome', state: 'US-AK' },
  { value: 'AK_palmer', label: 'AK - Palmer', state: 'US-AK' },
  { value: 'AK_seward', label: 'AK - Seward', state: 'US-AK' },
  { value: 'AK_sitka', label: 'AK - Sitka', state: 'US-AK' },
  { value: 'AK_skagway', label: 'AK - Skagway', state: 'US-AK' },
  { value: 'AK_valdez', label: 'AK - Valdez', state: 'US-AK' },

  { value: 'AZ_ajo', label: 'AZ - Ajo', state: 'US-AZ' },
  { value: 'AZ_avondale', label: 'AZ - Avondale', state: 'US-AZ' },
  { value: 'AZ_bisbee', label: 'AZ - Bisbee', state: 'US-AZ' },
  { value: 'AZ_casa_grande', label: 'AZ - Casa Grande', state: 'US-AZ' },
  { value: 'AZ_chandler', label: 'AZ - Chandler', state: 'US-AZ' },
  { value: 'AZ_clifton', label: 'AZ - Clifton', state: 'US-AZ' },
  { value: 'AZ_douglas', label: 'AZ - Douglas', state: 'US-AZ' },
  { value: 'AZ_flagstaff', label: 'AZ - Flagstaff', state: 'US-AZ' },
  { value: 'AZ_florence', label: 'AZ - Florence', state: 'US-AZ' },
  { value: 'AZ_gila_bend', label: 'AZ - Gila Bend', state: 'US-AZ' },
  { value: 'AZ_glendale', label: 'AZ - Glendale', state: 'US-AZ' },
  { value: 'AZ_globe', label: 'AZ - Globe', state: 'US-AZ' },
  { value: 'AZ_kingman', label: 'AZ - Kingman', state: 'US-AZ' },
  { value: 'AZ_lake_havasu_city', label: 'AZ - Lake Havasu City', state: 'US-AZ' },
  { value: 'AZ_mesa', label: 'AZ - Mesa', state: 'US-AZ' },
  { value: 'AZ_nogales', label: 'AZ - Nogales', state: 'US-AZ' },
  { value: 'AZ_oraibi', label: 'AZ - Oraibi', state: 'US-AZ' },
  { value: 'AZ_phoenix', label: 'AZ - Phoenix', state: 'US-AZ' },
  { value: 'AZ_prescott', label: 'AZ - Prescott', state: 'US-AZ' },
  { value: 'AZ_scottsdale', label: 'AZ - Scottsdale', state: 'US-AZ' },
  { value: 'AZ_sierra_vista', label: 'AZ - Sierra Vista', state: 'US-AZ' },
  { value: 'AZ_tempe', label: 'AZ - Tempe', state: 'US-AZ' },
  { value: 'AZ_tombstone', label: 'AZ - Tombstone', state: 'US-AZ' },
  { value: 'AZ_tucson', label: 'AZ - Tucson', state: 'US-AZ' },
  { value: 'AZ_walpi', label: 'AZ - Walpi', state: 'US-AZ' },
  { value: 'AZ_window_rock', label: 'AZ - Window Rock', state: 'US-AZ' },
  { value: 'AZ_winslow', label: 'AZ - Winslow', state: 'US-AZ' },
  { value: 'AZ_yuma', label: 'AZ - Yuma', state: 'US-AZ' },

  { value: 'AR_arkadelphia', label: 'AR - Arkadelphia', state: 'US-AR' },
  { value: 'AR_arkansas_post', label: 'AR - Arkansas Post', state: 'US-AR' },
  { value: 'AR_batesville', label: 'AR - Batesville', state: 'US-AR' },
  { value: 'AR_benton', label: 'AR - Benton', state: 'US-AR' },
  { value: 'AR_blytheville', label: 'AR - Blytheville', state: 'US-AR' },
  { value: 'AR_camden', label: 'AR - Camden', state: 'US-AR' },
  { value: 'AR_conway', label: 'AR - Conway', state: 'US-AR' },
  { value: 'AR_crossett', label: 'AR - Crossett', state: 'US-AR' },
  { value: 'AR_el_dorado', label: 'AR - El Dorado', state: 'US-AR' },
  { value: 'AR_fayetteville', label: 'AR - Fayetteville', state: 'US-AR' },
  { value: 'AR_forrest_city', label: 'AR - Forrest City', state: 'US-AR' },
  { value: 'AR_fort_smith', label: 'AR - Fort Smith', state: 'US-AR' },
  { value: 'AR_harrison', label: 'AR - Harrison', state: 'US-AR' },
  { value: 'AR_helena', label: 'AR - Helena', state: 'US-AR' },
  { value: 'AR_hope', label: 'AR - Hope', state: 'US-AR' },
  { value: 'AR_hot_springs', label: 'AR - Hot Springs', state: 'US-AR' },
  { value: 'AR_jacksonville', label: 'AR - Jacksonville', state: 'US-AR' },
  { value: 'AR_jonesboro', label: 'AR - Jonesboro', state: 'US-AR' },
  { value: 'AR_little_rock', label: 'AR - Little Rock', state: 'US-AR' },
  { value: 'AR_magnolia', label: 'AR - Magnolia', state: 'US-AR' },
  { value: 'AR_morrilton', label: 'AR - Morrilton', state: 'US-AR' },
  { value: 'AR_newport', label: 'AR - Newport', state: 'US-AR' },
  { value: 'AR_north_little_rock', label: 'AR - North Little Rock', state: 'US-AR' },
  { value: 'AR_osceola', label: 'AR - Osceola', state: 'US-AR' },
  { value: 'AR_pine_bluff', label: 'AR - Pine Bluff', state: 'US-AR' },
  { value: 'AR_rogers', label: 'AR - Rogers', state: 'US-AR' },
  { value: 'AR_searcy', label: 'AR - Searcy', state: 'US-AR' },
  { value: 'AR_stuttgart', label: 'AR - Stuttgart', state: 'US-AR' },
  { value: 'AR_van_buren', label: 'AR - Van Buren', state: 'US-AR' },
  { value: 'AR_west_memphis', label: 'AR - West Memphis', state: 'US-AR' },

  { value: 'CA_alameda', label: 'CA - Alameda', state: 'US-CA' },
  { value: 'CA_alhambra', label: 'CA - Alhambra', state: 'US-CA' },
  { value: 'CA_anaheim', label: 'CA - Anaheim', state: 'US-CA' },
  { value: 'CA_antioch', label: 'CA - Antioch', state: 'US-CA' },
  { value: 'CA_arcadia', label: 'CA - Arcadia', state: 'US-CA' },
  { value: 'CA_bakersfield', label: 'CA - Bakersfield', state: 'US-CA' },
  { value: 'CA_barstow', label: 'CA - Barstow', state: 'US-CA' },
  { value: 'CA_belmont', label: 'CA - Belmont', state: 'US-CA' },
  { value: 'CA_berkeley', label: 'CA - Berkeley', state: 'US-CA' },
  { value: 'CA_beverly_hills', label: 'CA - Beverly Hills', state: 'US-CA' },
  { value: 'CA_brea', label: 'CA - Brea', state: 'US-CA' },
  { value: 'CA_buena_park', label: 'CA - Buena Park', state: 'US-CA' },
  { value: 'CA_burbank', label: 'CA - Burbank', state: 'US-CA' },
  { value: 'CA_calexico', label: 'CA - Calexico', state: 'US-CA' },
  { value: 'CA_calistoga', label: 'CA - Calistoga', state: 'US-CA' },
  { value: 'CA_carlsbad', label: 'CA - Carlsbad', state: 'US-CA' },
  { value: 'CA_carmel', label: 'CA - Carmel', state: 'US-CA' },
  { value: 'CA_chico', label: 'CA - Chico', state: 'US-CA' },
  { value: 'CA_chula_vista', label: 'CA - Chula Vista', state: 'US-CA' },
  { value: 'CA_claremont', label: 'CA - Claremont', state: 'US-CA' },
  { value: 'CA_compton', label: 'CA - Compton', state: 'US-CA' },
  { value: 'CA_concord', label: 'CA - Concord', state: 'US-CA' },
  { value: 'CA_corona', label: 'CA - Corona', state: 'US-CA' },
  { value: 'CA_coronado', label: 'CA - Coronado', state: 'US-CA' },
  { value: 'CA_costa_mesa', label: 'CA - Costa Mesa', state: 'US-CA' },
  { value: 'CA_culver_city', label: 'CA - Culver City', state: 'US-CA' },
  { value: 'CA_daly_city', label: 'CA - Daly City', state: 'US-CA' },
  { value: 'CA_davis', label: 'CA - Davis', state: 'US-CA' },
  { value: 'CA_downey', label: 'CA - Downey', state: 'US-CA' },
  { value: 'CA_el_centro', label: 'CA - El Centro', state: 'US-CA' },
  { value: 'CA_el_cerrito', label: 'CA - El Cerrito', state: 'US-CA' },
  { value: 'CA_el_monte', label: 'CA - El Monte', state: 'US-CA' },
  { value: 'CA_escondido', label: 'CA - Escondido', state: 'US-CA' },
  { value: 'CA_eureka', label: 'CA - Eureka', state: 'US-CA' },
  { value: 'CA_fairfield', label: 'CA - Fairfield', state: 'US-CA' },
  { value: 'CA_fontana', label: 'CA - Fontana', state: 'US-CA' },
  { value: 'CA_fremont', label: 'CA - Fremont', state: 'US-CA' },
  { value: 'CA_fresno', label: 'CA - Fresno', state: 'US-CA' },
  { value: 'CA_fullerton', label: 'CA - Fullerton', state: 'US-CA' },
  { value: 'CA_garden_grove', label: 'CA - Garden Grove', state: 'US-CA' },
  { value: 'CA_glendale', label: 'CA - Glendale', state: 'US-CA' },
  { value: 'CA_hayward', label: 'CA - Hayward', state: 'US-CA' },
  { value: 'CA_hollywood', label: 'CA - Hollywood', state: 'US-CA' },
  { value: 'CA_huntington_beach', label: 'CA - Huntington Beach', state: 'US-CA' },
  { value: 'CA_indio', label: 'CA - Indio', state: 'US-CA' },
  { value: 'CA_inglewood', label: 'CA - Inglewood', state: 'US-CA' },
  { value: 'CA_irvine', label: 'CA - Irvine', state: 'US-CA' },
  { value: 'CA_la_habra', label: 'CA - La Habra', state: 'US-CA' },
  { value: 'CA_laguna_beach', label: 'CA - Laguna Beach', state: 'US-CA' },
  { value: 'CA_lancaster', label: 'CA - Lancaster', state: 'US-CA' },
  { value: 'CA_livermore', label: 'CA - Livermore', state: 'US-CA' },
  { value: 'CA_lodi', label: 'CA - Lodi', state: 'US-CA' },
  { value: 'CA_lompoc', label: 'CA - Lompoc', state: 'US-CA' },
  { value: 'CA_long_beach', label: 'CA - Long Beach', state: 'US-CA' },
  { value: 'CA_los_angeles', label: 'CA - Los Angeles', state: 'US-CA' },
  { value: 'CA_malibu', label: 'CA - Malibu', state: 'US-CA' },
  { value: 'CA_martinez', label: 'CA - Martinez', state: 'US-CA' },
  { value: 'CA_marysville', label: 'CA - Marysville', state: 'US-CA' },
  { value: 'CA_menlo_park', label: 'CA - Menlo Park', state: 'US-CA' },
  { value: 'CA_merced', label: 'CA - Merced', state: 'US-CA' },
  { value: 'CA_modesto', label: 'CA - Modesto', state: 'US-CA' },
  { value: 'CA_monterey', label: 'CA - Monterey', state: 'US-CA' },
  { value: 'CA_mountain_view', label: 'CA - Mountain View', state: 'US-CA' },
  { value: 'CA_napa', label: 'CA - Napa', state: 'US-CA' },
  { value: 'CA_needles', label: 'CA - Needles', state: 'US-CA' },
  { value: 'CA_newport_beach', label: 'CA - Newport Beach', state: 'US-CA' },
  { value: 'CA_norwalk', label: 'CA - Norwalk', state: 'US-CA' },
  { value: 'CA_novato', label: 'CA - Novato', state: 'US-CA' },
  { value: 'CA_oakland', label: 'CA - Oakland', state: 'US-CA' },
  { value: 'CA_oceanside', label: 'CA - Oceanside', state: 'US-CA' },
  { value: 'CA_ojai', label: 'CA - Ojai', state: 'US-CA' },
  { value: 'CA_ontario', label: 'CA - Ontario', state: 'US-CA' },
  { value: 'CA_orange', label: 'CA - Orange', state: 'US-CA' },
  { value: 'CA_oroville', label: 'CA - Oroville', state: 'US-CA' },
  { value: 'CA_oxnard', label: 'CA - Oxnard', state: 'US-CA' },
  { value: 'CA_pacific_grove', label: 'CA - Pacific Grove', state: 'US-CA' },
  { value: 'CA_palm_springs', label: 'CA - Palm Springs', state: 'US-CA' },
  { value: 'CA_palmdale', label: 'CA - Palmdale', state: 'US-CA' },
  { value: 'CA_palo_alto', label: 'CA - Palo Alto', state: 'US-CA' },
  { value: 'CA_pasadena', label: 'CA - Pasadena', state: 'US-CA' },
  { value: 'CA_petaluma', label: 'CA - Petaluma', state: 'US-CA' },
  { value: 'CA_pomona', label: 'CA - Pomona', state: 'US-CA' },
  { value: 'CA_port_hueneme', label: 'CA - Port Hueneme', state: 'US-CA' },
  { value: 'CA_rancho_cucamonga', label: 'CA - Rancho Cucamonga', state: 'US-CA' },
  { value: 'CA_red_bluff', label: 'CA - Red Bluff', state: 'US-CA' },
  { value: 'CA_redding', label: 'CA - Redding', state: 'US-CA' },
  { value: 'CA_redlands', label: 'CA - Redlands', state: 'US-CA' },
  { value: 'CA_redondo_beach', label: 'CA - Redondo Beach', state: 'US-CA' },
  { value: 'CA_redwood_city', label: 'CA - Redwood City', state: 'US-CA' },
  { value: 'CA_richmond', label: 'CA - Richmond', state: 'US-CA' },
  { value: 'CA_riverside', label: 'CA - Riverside', state: 'US-CA' },
  { value: 'CA_roseville', label: 'CA - Roseville', state: 'US-CA' },
  { value: 'CA_sacramento', label: 'CA - Sacramento', state: 'US-CA' },
  { value: 'CA_salinas', label: 'CA - Salinas', state: 'US-CA' },
  { value: 'CA_san_bernardino', label: 'CA - San Bernardino', state: 'US-CA' },
  { value: 'CA_san_clemente', label: 'CA - San Clemente', state: 'US-CA' },
  { value: 'CA_san_diego', label: 'CA - San Diego', state: 'US-CA' },
  { value: 'CA_san_fernando', label: 'CA - San Fernando', state: 'US-CA' },
  { value: 'CA_san_francisco', label: 'CA - San Francisco', state: 'US-CA' },
  { value: 'CA_san_gabriel', label: 'CA - San Gabriel', state: 'US-CA' },
  { value: 'CA_san_jose', label: 'CA - San Jose', state: 'US-CA' },
  { value: 'CA_san_juan_capistrano', label: 'CA - San Juan Capistrano', state: 'US-CA' },
  { value: 'CA_san_leandro', label: 'CA - San Leandro', state: 'US-CA' },
  { value: 'CA_san_luis_obispo', label: 'CA - San Luis Obispo', state: 'US-CA' },
  { value: 'CA_san_marino', label: 'CA - San Marino', state: 'US-CA' },
  { value: 'CA_san_mateo', label: 'CA - San Mateo', state: 'US-CA' },
  { value: 'CA_san_pedro', label: 'CA - San Pedro', state: 'US-CA' },
  { value: 'CA_san_rafael', label: 'CA - San Rafael', state: 'US-CA' },
  { value: 'CA_san_simeon', label: 'CA - San Simeon', state: 'US-CA' },
  { value: 'CA_santa_ana', label: 'CA - Santa Ana', state: 'US-CA' },
  { value: 'CA_santa_barbara', label: 'CA - Santa Barbara', state: 'US-CA' },
  { value: 'CA_santa_clara', label: 'CA - Santa Clara', state: 'US-CA' },
  { value: 'CA_santa_clarita', label: 'CA - Santa Clarita', state: 'US-CA' },
  { value: 'CA_santa_cruz', label: 'CA - Santa Cruz', state: 'US-CA' },
  { value: 'CA_santa_monica', label: 'CA - Santa Monica', state: 'US-CA' },
  { value: 'CA_santa_rosa', label: 'CA - Santa Rosa', state: 'US-CA' },
  { value: 'CA_sausalito', label: 'CA - Sausalito', state: 'US-CA' },
  { value: 'CA_simi_valley', label: 'CA - Simi Valley', state: 'US-CA' },
  { value: 'CA_sonoma', label: 'CA - Sonoma', state: 'US-CA' },
  { value: 'CA_stockton', label: 'CA - Stockton', state: 'US-CA' },
  { value: 'CA_sunnyvale', label: 'CA - Sunnyvale', state: 'US-CA' },
  { value: 'CA_susanville', label: 'CA - Susanville', state: 'US-CA' },
  { value: 'CA_thousand_oaks', label: 'CA - Thousand Oaks', state: 'US-CA' },
  { value: 'CA_torrance', label: 'CA - Torrance', state: 'US-CA' },
  { value: 'CA_truckee', label: 'CA - Truckee', state: 'US-CA' },
  { value: 'CA_turlock', label: 'CA - Turlock', state: 'US-CA' },
  { value: 'CA_ukiah', label: 'CA - Ukiah', state: 'US-CA' },
  { value: 'CA_vallejo', label: 'CA - Vallejo', state: 'US-CA' },
  { value: 'CA_ventura', label: 'CA - Ventura', state: 'US-CA' },
  { value: 'CA_victorville', label: 'CA - Victorville', state: 'US-CA' },
  { value: 'CA_visalia', label: 'CA - Visalia', state: 'US-CA' },
  { value: 'CA_walnut_creek', label: 'CA - Walnut Creek', state: 'US-CA' },
  { value: 'CA_watts', label: 'CA - Watts', state: 'US-CA' },
  { value: 'CA_west_covina', label: 'CA - West Covina', state: 'US-CA' },
  { value: 'CA_whittier', label: 'CA - Whittier', state: 'US-CA' },
  { value: 'CA_woodland', label: 'CA - Woodland', state: 'US-CA' },
  { value: 'CA_yorba_linda', label: 'CA - Yorba Linda', state: 'US-CA' },
  { value: 'CA_yuba_city', label: 'CA - Yuba City', state: 'US-CA' },

  { value: 'CO_alamosa', label: 'CO - Alamosa', state: 'US-CO' },
  { value: 'CO_aspen', label: 'CO - Aspen', state: 'US-CO' },
  { value: 'CO_aurora', label: 'CO - Aurora', state: 'US-CO' },
  { value: 'CO_boulder', label: 'CO - Boulder', state: 'US-CO' },
  { value: 'CO_breckenridge', label: 'CO - Breckenridge', state: 'US-CO' },
  { value: 'CO_brighton', label: 'CO - Brighton', state: 'US-CO' },
  { value: 'CO_canon_city', label: 'CO - Canon City', state: 'US-CO' },
  { value: 'CO_central_city', label: 'CO - Central City', state: 'US-CO' },
  { value: 'CO_climax', label: 'CO - Climax', state: 'US-CO' },
  { value: 'CO_colorado_springs', label: 'CO - Colorado Springs', state: 'US-CO' },
  { value: 'CO_cortez', label: 'CO - Cortez', state: 'US-CO' },
  { value: 'CO_cripple_creek', label: 'CO - Cripple Creek', state: 'US-CO' },
  { value: 'CO_denver', label: 'CO - Denver', state: 'US-CO' },
  { value: 'CO_durango', label: 'CO - Durango', state: 'US-CO' },
  { value: 'CO_englewood', label: 'CO - Englewood', state: 'US-CO' },
  { value: 'CO_estes_park', label: 'CO - Estes Park', state: 'US-CO' },
  { value: 'CO_fort_collins', label: 'CO - Fort Collins', state: 'US-CO' },
  { value: 'CO_fort_morgan', label: 'CO - Fort Morgan', state: 'US-CO' },
  { value: 'CO_georgetown', label: 'CO - Georgetown', state: 'US-CO' },
  { value: 'CO_glenwood_springs', label: 'CO - Glenwood Springs', state: 'US-CO' },
  { value: 'CO_golden', label: 'CO - Golden', state: 'US-CO' },
  { value: 'CO_grand_junction', label: 'CO - Grand Junction', state: 'US-CO' },
  { value: 'CO_greeley', label: 'CO - Greeley', state: 'US-CO' },
  { value: 'CO_gunnison', label: 'CO - Gunnison', state: 'US-CO' },
  { value: 'CO_la_junta', label: 'CO - La Junta', state: 'US-CO' },
  { value: 'CO_leadville', label: 'CO - Leadville', state: 'US-CO' },
  { value: 'CO_littleton', label: 'CO - Littleton', state: 'US-CO' },
  { value: 'CO_longmont', label: 'CO - Longmont', state: 'US-CO' },
  { value: 'CO_loveland', label: 'CO - Loveland', state: 'US-CO' },
  { value: 'CO_montrose', label: 'CO - Montrose', state: 'US-CO' },
  { value: 'CO_ouray', label: 'CO - Ouray', state: 'US-CO' },
  { value: 'CO_pagosa_springs', label: 'CO - Pagosa Springs', state: 'US-CO' },
  { value: 'CO_pueblo', label: 'CO - Pueblo', state: 'US-CO' },
  { value: 'CO_silverton', label: 'CO - Silverton', state: 'US-CO' },
  { value: 'CO_steamboat_springs', label: 'CO - Steamboat Springs', state: 'US-CO' },
  { value: 'CO_sterling', label: 'CO - Sterling', state: 'US-CO' },
  { value: 'CO_telluride', label: 'CO - Telluride', state: 'US-CO' },
  { value: 'CO_trinidad', label: 'CO - Trinidad', state: 'US-CO' },
  { value: 'CO_vail', label: 'CO - Vail', state: 'US-CO' },
  { value: 'CO_walsenburg', label: 'CO - Walsenburg', state: 'US-CO' },
  { value: 'CO_westminster', label: 'CO - Westminster', state: 'US-CO' },

  { value: 'CT_ansonia', label: 'CT - Ansonia', state: 'US-CT' },
  { value: 'CT_berlin', label: 'CT - Berlin', state: 'US-CT' },
  { value: 'CT_bloomfield', label: 'CT - Bloomfield', state: 'US-CT' },
  { value: 'CT_branford', label: 'CT - Branford', state: 'US-CT' },
  { value: 'CT_bridgeport', label: 'CT - Bridgeport', state: 'US-CT' },
  { value: 'CT_bristol', label: 'CT - Bristol', state: 'US-CT' },
  { value: 'CT_coventry', label: 'CT - Coventry', state: 'US-CT' },
  { value: 'CT_danbury', label: 'CT - Danbury', state: 'US-CT' },
  { value: 'CT_darien', label: 'CT - Darien', state: 'US-CT' },
  { value: 'CT_derby', label: 'CT - Derby', state: 'US-CT' },
  { value: 'CT_east_hartford', label: 'CT - East Hartford', state: 'US-CT' },
  { value: 'CT_east_haven', label: 'CT - East Haven', state: 'US-CT' },
  { value: 'CT_enfield', label: 'CT - Enfield', state: 'US-CT' },
  { value: 'CT_fairfield', label: 'CT - Fairfield', state: 'US-CT' },
  { value: 'CT_farmington', label: 'CT - Farmington', state: 'US-CT' },
  { value: 'CT_greenwich', label: 'CT - Greenwich', state: 'US-CT' },
  { value: 'CT_groton', label: 'CT - Groton', state: 'US-CT' },
  { value: 'CT_guilford', label: 'CT - Guilford', state: 'US-CT' },
  { value: 'CT_hamden', label: 'CT - Hamden', state: 'US-CT' },
  { value: 'CT_hartford', label: 'CT - Hartford', state: 'US-CT' },
  { value: 'CT_lebanon', label: 'CT - Lebanon', state: 'US-CT' },
  { value: 'CT_litchfield', label: 'CT - Litchfield', state: 'US-CT' },
  { value: 'CT_manchester', label: 'CT - Manchester', state: 'US-CT' },
  { value: 'CT_mansfield', label: 'CT - Mansfield', state: 'US-CT' },
  { value: 'CT_meriden', label: 'CT - Meriden', state: 'US-CT' },
  { value: 'CT_middletown', label: 'CT - Middletown', state: 'US-CT' },
  { value: 'CT_milford', label: 'CT - Milford', state: 'US-CT' },
  { value: 'CT_mystic', label: 'CT - Mystic', state: 'US-CT' },
  { value: 'CT_naugatuck', label: 'CT - Naugatuck', state: 'US-CT' },
  { value: 'CT_new_britain', label: 'CT - New Britain', state: 'US-CT' },
  { value: 'CT_new_haven', label: 'CT - New Haven', state: 'US-CT' },
  { value: 'CT_new_london', label: 'CT - New London', state: 'US-CT' },
  { value: 'CT_north_haven', label: 'CT - North Haven', state: 'US-CT' },
  { value: 'CT_norwalk', label: 'CT - Norwalk', state: 'US-CT' },
  { value: 'CT_norwich', label: 'CT - Norwich', state: 'US-CT' },
  { value: 'CT_old_saybrook', label: 'CT - Old Saybrook', state: 'US-CT' },
  { value: 'CT_orange', label: 'CT - Orange', state: 'US-CT' },
  { value: 'CT_seymour', label: 'CT - Seymour', state: 'US-CT' },
  { value: 'CT_shelton', label: 'CT - Shelton', state: 'US-CT' },
  { value: 'CT_simsbury', label: 'CT - Simsbury', state: 'US-CT' },
  { value: 'CT_southington', label: 'CT - Southington', state: 'US-CT' },
  { value: 'CT_stamford', label: 'CT - Stamford', state: 'US-CT' },
  { value: 'CT_stonington', label: 'CT - Stonington', state: 'US-CT' },
  { value: 'CT_stratford', label: 'CT - Stratford', state: 'US-CT' },
  { value: 'CT_torrington', label: 'CT - Torrington', state: 'US-CT' },
  { value: 'CT_wallingford', label: 'CT - Wallingford', state: 'US-CT' },
  { value: 'CT_waterbury', label: 'CT - Waterbury', state: 'US-CT' },
  { value: 'CT_waterford', label: 'CT - Waterford', state: 'US-CT' },
  { value: 'CT_watertown', label: 'CT - Watertown', state: 'US-CT' },
  { value: 'CT_west_hartford', label: 'CT - West Hartford', state: 'US-CT' },
  { value: 'CT_west_haven', label: 'CT - West Haven', state: 'US-CT' },
  { value: 'CT_westport', label: 'CT - Westport', state: 'US-CT' },
  { value: 'CT_wethersfield', label: 'CT - Wethersfield', state: 'US-CT' },
  { value: 'CT_willimantic', label: 'CT - Willimantic', state: 'US-CT' },
  { value: 'CT_windham', label: 'CT - Windham', state: 'US-CT' },
  { value: 'CT_windsor', label: 'CT - Windsor', state: 'US-CT' },
  { value: 'CT_windsor_locks', label: 'CT - Windsor Locks', state: 'US-CT' },
  { value: 'CT_winsted', label: 'CT - Winsted', state: 'US-CT' },

  { value: 'DE_dover', label: 'DE - Dover', state: 'US-DE' },
  { value: 'DE_lewes', label: 'DE - Lewes', state: 'US-DE' },
  { value: 'DE_milford', label: 'DE - Milford', state: 'US-DE' },
  { value: 'DE_new_castle', label: 'DE - New Castle', state: 'US-DE' },
  { value: 'DE_newark', label: 'DE - Newark', state: 'US-DE' },
  { value: 'DE_smyrna', label: 'DE - Smyrna', state: 'US-DE' },
  { value: 'DE_wilmington', label: 'DE - Wilmington', state: 'US-DE' },
  { value: 'DE_middleton', label: 'DE - Middleton', state: 'US-DE' },
  { value: 'DE_bear', label: 'DE - Bear', state: 'US-DE' },
  { value: 'DE_glasgow', label: 'DE - Glasgow', state: 'US-DE' },
  { value: 'DE_hockessin', label: 'DE - Hockessin', state: 'US-DE' },
  { value: 'DE_brookside', label: 'DE - Brookside', state: 'US-DE' },

  { value: 'FL_apalachicola', label: 'FL - Apalachicola', state: 'US-FL' },
  { value: 'FL_bartow', label: 'FL - Bartow', state: 'US-FL' },
  { value: 'FL_belle_glade', label: 'FL - Belle Glade', state: 'US-FL' },
  { value: 'FL_boca_raton', label: 'FL - Boca Raton', state: 'US-FL' },
  { value: 'FL_bradenton', label: 'FL - Bradenton', state: 'US-FL' },
  { value: 'FL_cape_coral', label: 'FL - Cape Coral', state: 'US-FL' },
  { value: 'FL_clearwater', label: 'FL - Clearwater', state: 'US-FL' },
  { value: 'FL_cocoa_beach', label: 'FL - Cocoa Beach', state: 'US-FL' },
  { value: 'FL_cocoa-rockledge', label: 'FL - Cocoa-Rockledge', state: 'US-FL' },
  { value: 'FL_coral_gables', label: 'FL - Coral Gables', state: 'US-FL' },
  { value: 'FL_daytona_beach', label: 'FL - Daytona Beach', state: 'US-FL' },
  { value: 'FL_de_land', label: 'FL - De Land', state: 'US-FL' },
  { value: 'FL_deerfield_beach', label: 'FL - Deerfield Beach', state: 'US-FL' },
  { value: 'FL_delray_beach', label: 'FL - Delray Beach', state: 'US-FL' },
  { value: 'FL_fernandina_beach', label: 'FL - Fernandina Beach', state: 'US-FL' },
  { value: 'FL_fort_lauderdale', label: 'FL - Fort Lauderdale', state: 'US-FL' },
  { value: 'FL_fort_myers', label: 'FL - Fort Myers', state: 'US-FL' },
  { value: 'FL_fort_pierce', label: 'FL - Fort Pierce', state: 'US-FL' },
  { value: 'FL_fort_walton_beach', label: 'FL - Fort Walton Beach', state: 'US-FL' },
  { value: 'FL_gainesville', label: 'FL - Gainesville', state: 'US-FL' },
  { value: 'FL_hallandale_beach', label: 'FL - Hallandale Beach', state: 'US-FL' },
  { value: 'FL_hialeah', label: 'FL - Hialeah', state: 'US-FL' },
  { value: 'FL_hollywood', label: 'FL - Hollywood', state: 'US-FL' },
  { value: 'FL_homestead', label: 'FL - Homestead', state: 'US-FL' },
  { value: 'FL_jacksonville', label: 'FL - Jacksonville', state: 'US-FL' },
  { value: 'FL_key_west', label: 'FL - Key West', state: 'US-FL' },
  { value: 'FL_lake_city', label: 'FL - Lake City', state: 'US-FL' },
  { value: 'FL_lake_wales', label: 'FL - Lake Wales', state: 'US-FL' },
  { value: 'FL_lakeland', label: 'FL - Lakeland', state: 'US-FL' },
  { value: 'FL_largo', label: 'FL - Largo', state: 'US-FL' },
  { value: 'FL_melbourne', label: 'FL - Melbourne', state: 'US-FL' },
  { value: 'FL_miami', label: 'FL - Miami', state: 'US-FL' },
  { value: 'FL_miami_beach', label: 'FL - Miami Beach', state: 'US-FL' },
  { value: 'FL_naples', label: 'FL - Naples', state: 'US-FL' },
  { value: 'FL_new_smyrna_beach', label: 'FL - New Smyrna Beach', state: 'US-FL' },
  { value: 'FL_ocala', label: 'FL - Ocala', state: 'US-FL' },
  { value: 'FL_orlando', label: 'FL - Orlando', state: 'US-FL' },
  { value: 'FL_ormond_beach', label: 'FL - Ormond Beach', state: 'US-FL' },
  { value: 'FL_palatka', label: 'FL - Palatka', state: 'US-FL' },
  { value: 'FL_palm_bay', label: 'FL - Palm Bay', state: 'US-FL' },
  { value: 'FL_palm_beach', label: 'FL - Palm Beach', state: 'US-FL' },
  { value: 'FL_panama_city', label: 'FL - Panama City', state: 'US-FL' },
  { value: 'FL_pensacola', label: 'FL - Pensacola', state: 'US-FL' },
  { value: 'FL_pompano_beach', label: 'FL - Pompano Beach', state: 'US-FL' },
  { value: 'FL_saint_augustine', label: 'FL - Saint Augustine', state: 'US-FL' },
  { value: 'FL_saint_petersburg', label: 'FL - Saint Petersburg', state: 'US-FL' },
  { value: 'FL_sanford', label: 'FL - Sanford', state: 'US-FL' },
  { value: 'FL_sarasota', label: 'FL - Sarasota', state: 'US-FL' },
  { value: 'FL_sebring', label: 'FL - Sebring', state: 'US-FL' },
  { value: 'FL_tallahassee', label: 'FL - Tallahassee', state: 'US-FL' },
  { value: 'FL_tampa', label: 'FL - Tampa', state: 'US-FL' },
  { value: 'FL_tarpon_springs', label: 'FL - Tarpon Springs', state: 'US-FL' },
  { value: 'FL_titusville', label: 'FL - Titusville', state: 'US-FL' },
  { value: 'FL_venice', label: 'FL - Venice', state: 'US-FL' },
  { value: 'FL_west_palm_beach', label: 'FL - West Palm Beach', state: 'US-FL' },
  { value: 'FL_white_springs', label: 'FL - White Springs', state: 'US-FL' },
  { value: 'FL_winter_haven', label: 'FL - Winter Haven', state: 'US-FL' },
  { value: 'FL_winter_park', label: 'FL - Winter Park', state: 'US-FL' },

  { value: 'GA_albany', label: 'GA - Albany', state: 'US-GA' },
  { value: 'GA_americus', label: 'GA - Americus', state: 'US-GA' },
  { value: 'GA_andersonville', label: 'GA - Andersonville', state: 'US-GA' },
  { value: 'GA_athens', label: 'GA - Athens', state: 'US-GA' },
  { value: 'GA_atlanta', label: 'GA - Atlanta', state: 'US-GA' },
  { value: 'GA_augusta', label: 'GA - Augusta', state: 'US-GA' },
  { value: 'GA_bainbridge', label: 'GA - Bainbridge', state: 'US-GA' },
  { value: 'GA_blairsville', label: 'GA - Blairsville', state: 'US-GA' },
  { value: 'GA_brunswick', label: 'GA - Brunswick', state: 'US-GA' },
  { value: 'GA_calhoun', label: 'GA - Calhoun', state: 'US-GA' },
  { value: 'GA_carrollton', label: 'GA - Carrollton', state: 'US-GA' },
  { value: 'GA_columbus', label: 'GA - Columbus', state: 'US-GA' },
  { value: 'GA_dahlonega', label: 'GA - Dahlonega', state: 'US-GA' },
  { value: 'GA_dalton', label: 'GA - Dalton', state: 'US-GA' },
  { value: 'GA_darien', label: 'GA - Darien', state: 'US-GA' },
  { value: 'GA_decatur', label: 'GA - Decatur', state: 'US-GA' },
  { value: 'GA_douglas', label: 'GA - Douglas', state: 'US-GA' },
  { value: 'GA_east_point', label: 'GA - East Point', state: 'US-GA' },
  { value: 'GA_fitzgerald', label: 'GA - Fitzgerald', state: 'US-GA' },
  { value: 'GA_fort_valley', label: 'GA - Fort Valley', state: 'US-GA' },
  { value: 'GA_gainesville', label: 'GA - Gainesville', state: 'US-GA' },
  { value: 'GA_la_grange', label: 'GA - La Grange', state: 'US-GA' },
  { value: 'GA_macon', label: 'GA - Macon', state: 'US-GA' },
  { value: 'GA_marietta', label: 'GA - Marietta', state: 'US-GA' },
  { value: 'GA_milledgeville', label: 'GA - Milledgeville', state: 'US-GA' },
  { value: 'GA_plains', label: 'GA - Plains', state: 'US-GA' },
  { value: 'GA_rome', label: 'GA - Rome', state: 'US-GA' },
  { value: 'GA_savannah', label: 'GA - Savannah', state: 'US-GA' },
  { value: 'GA_toccoa', label: 'GA - Toccoa', state: 'US-GA' },
  { value: 'GA_valdosta', label: 'GA - Valdosta', state: 'US-GA' },
  { value: 'GA_warm_springs', label: 'GA - Warm Springs', state: 'US-GA' },
  { value: 'GA_warner_robins', label: 'GA - Warner Robins', state: 'US-GA' },
  { value: 'GA_washington', label: 'GA - Washington', state: 'US-GA' },
  { value: 'GA_waycross', label: 'GA - Waycross', state: 'US-GA' },

  { value: 'HI_hanalei', label: 'HI - Hanalei', state: 'US-HI' },
  { value: 'HI_hilo', label: 'HI - Hilo', state: 'US-HI' },
  { value: 'HI_honaunau', label: 'HI - Honaunau', state: 'US-HI' },
  { value: 'HI_honolulu', label: 'HI - Honolulu', state: 'US-HI' },
  { value: 'HI_kahului', label: 'HI - Kahului', state: 'US-HI' },
  { value: 'HI_kaneohe', label: 'HI - Kaneohe', state: 'US-HI' },
  { value: 'HI_kapaa', label: 'HI - Kapaa', state: 'US-HI' },
  { value: 'HI_kawaihae', label: 'HI - Kawaihae', state: 'US-HI' },
  { value: 'HI_lahaina', label: 'HI - Lahaina', state: 'US-HI' },
  { value: 'HI_laie', label: 'HI - Laie', state: 'US-HI' },
  { value: 'HI_wahiawa', label: 'HI - Wahiawa', state: 'US-HI' },
  { value: 'HI_wailuku', label: 'HI - Wailuku', state: 'US-HI' },
  { value: 'HI_waimea', label: 'HI - Waimea', state: 'US-HI' },

  { value: 'ID_blackfoot', label: 'ID - Blackfoot', state: 'US-ID' },
  { value: 'ID_boise', label: 'ID - Boise', state: 'US-ID' },
  { value: 'ID_bonners_ferry', label: 'ID - Bonners Ferry', state: 'US-ID' },
  { value: 'ID_caldwell', label: 'ID - Caldwell', state: 'US-ID' },
  { value: 'ID_coeur_d’alene', label: 'ID - Coeur d’Alene', state: 'US-ID' },
  { value: 'ID_idaho_city', label: 'ID - Idaho City', state: 'US-ID' },
  { value: 'ID_idaho_falls', label: 'ID - Idaho Falls', state: 'US-ID' },
  { value: 'ID_kellogg', label: 'ID - Kellogg', state: 'US-ID' },
  { value: 'ID_lewiston', label: 'ID - Lewiston', state: 'US-ID' },
  { value: 'ID_moscow', label: 'ID - Moscow', state: 'US-ID' },
  { value: 'ID_nampa', label: 'ID - Nampa', state: 'US-ID' },
  { value: 'ID_pocatello', label: 'ID - Pocatello', state: 'US-ID' },
  { value: 'ID_priest_river', label: 'ID - Priest River', state: 'US-ID' },
  { value: 'ID_rexburg', label: 'ID - Rexburg', state: 'US-ID' },
  { value: 'ID_sun_valley', label: 'ID - Sun Valley', state: 'US-ID' },
  { value: 'ID_twin_falls', label: 'ID - Twin Falls', state: 'US-ID' },

  { value: 'IL_alton', label: 'IL - Alton', state: 'US-IL' },
  { value: 'IL_arlington_heights', label: 'IL - Arlington Heights', state: 'US-IL' },
  { value: 'IL_arthur', label: 'IL - Arthur', state: 'US-IL' },
  { value: 'IL_aurora', label: 'IL - Aurora', state: 'US-IL' },
  { value: 'IL_belleville', label: 'IL - Belleville', state: 'US-IL' },
  { value: 'IL_belvidere', label: 'IL - Belvidere', state: 'US-IL' },
  { value: 'IL_bloomington', label: 'IL - Bloomington', state: 'US-IL' },
  { value: 'IL_brookfield', label: 'IL - Brookfield', state: 'US-IL' },
  { value: 'IL_cahokia', label: 'IL - Cahokia', state: 'US-IL' },
  { value: 'IL_cairo', label: 'IL - Cairo', state: 'US-IL' },
  { value: 'IL_calumet_city', label: 'IL - Calumet City', state: 'US-IL' },
  { value: 'IL_canton', label: 'IL - Canton', state: 'US-IL' },
  { value: 'IL_carbondale', label: 'IL - Carbondale', state: 'US-IL' },
  { value: 'IL_carlinville', label: 'IL - Carlinville', state: 'US-IL' },
  { value: 'IL_carthage', label: 'IL - Carthage', state: 'US-IL' },
  { value: 'IL_centralia', label: 'IL - Centralia', state: 'US-IL' },
  { value: 'IL_champaign', label: 'IL - Champaign', state: 'US-IL' },
  { value: 'IL_charleston', label: 'IL - Charleston', state: 'US-IL' },
  { value: 'IL_chester', label: 'IL - Chester', state: 'US-IL' },
  { value: 'IL_chicago', label: 'IL - Chicago', state: 'US-IL' },
  { value: 'IL_chicago_heights', label: 'IL - Chicago Heights', state: 'US-IL' },
  { value: 'IL_cicero', label: 'IL - Cicero', state: 'US-IL' },
  { value: 'IL_collinsville', label: 'IL - Collinsville', state: 'US-IL' },
  { value: 'IL_danville', label: 'IL - Danville', state: 'US-IL' },
  { value: 'IL_decatur', label: 'IL - Decatur', state: 'US-IL' },
  { value: 'IL_dekalb', label: 'IL - DeKalb', state: 'US-IL' },
  { value: 'IL_des_plaines', label: 'IL - Des Plaines', state: 'US-IL' },
  { value: 'IL_dixon', label: 'IL - Dixon', state: 'US-IL' },
  { value: 'IL_east_moline', label: 'IL - East Moline', state: 'US-IL' },
  { value: 'IL_east_saint_louis', label: 'IL - East Saint Louis', state: 'US-IL' },
  { value: 'IL_effingham', label: 'IL - Effingham', state: 'US-IL' },
  { value: 'IL_elgin', label: 'IL - Elgin', state: 'US-IL' },
  { value: 'IL_elmhurst', label: 'IL - Elmhurst', state: 'US-IL' },
  { value: 'IL_evanston', label: 'IL - Evanston', state: 'US-IL' },
  { value: 'IL_freeport', label: 'IL - Freeport', state: 'US-IL' },
  { value: 'IL_galena', label: 'IL - Galena', state: 'US-IL' },
  { value: 'IL_galesburg', label: 'IL - Galesburg', state: 'US-IL' },
  { value: 'IL_glen_ellyn', label: 'IL - Glen Ellyn', state: 'US-IL' },
  { value: 'IL_glenview', label: 'IL - Glenview', state: 'US-IL' },
  { value: 'IL_granite_city', label: 'IL - Granite City', state: 'US-IL' },
  { value: 'IL_harrisburg', label: 'IL - Harrisburg', state: 'US-IL' },
  { value: 'IL_herrin', label: 'IL - Herrin', state: 'US-IL' },
  { value: 'IL_highland_park', label: 'IL - Highland Park', state: 'US-IL' },
  { value: 'IL_jacksonville', label: 'IL - Jacksonville', state: 'US-IL' },
  { value: 'IL_joliet', label: 'IL - Joliet', state: 'US-IL' },
  { value: 'IL_kankakee', label: 'IL - Kankakee', state: 'US-IL' },
  { value: 'IL_kaskaskia', label: 'IL - Kaskaskia', state: 'US-IL' },
  { value: 'IL_kewanee', label: 'IL - Kewanee', state: 'US-IL' },
  { value: 'IL_la_salle', label: 'IL - La Salle', state: 'US-IL' },
  { value: 'IL_lake_forest', label: 'IL - Lake Forest', state: 'US-IL' },
  { value: 'IL_libertyville', label: 'IL - Libertyville', state: 'US-IL' },
  { value: 'IL_lincoln', label: 'IL - Lincoln', state: 'US-IL' },
  { value: 'IL_lisle', label: 'IL - Lisle', state: 'US-IL' },
  { value: 'IL_lombard', label: 'IL - Lombard', state: 'US-IL' },
  { value: 'IL_macomb', label: 'IL - Macomb', state: 'US-IL' },
  { value: 'IL_mattoon', label: 'IL - Mattoon', state: 'US-IL' },
  { value: 'IL_moline', label: 'IL - Moline', state: 'US-IL' },
  { value: 'IL_monmouth', label: 'IL - Monmouth', state: 'US-IL' },
  { value: 'IL_mount_vernon', label: 'IL - Mount Vernon', state: 'US-IL' },
  { value: 'IL_mundelein', label: 'IL - Mundelein', state: 'US-IL' },
  { value: 'IL_naperville', label: 'IL - Naperville', state: 'US-IL' },
  { value: 'IL_nauvoo', label: 'IL - Nauvoo', state: 'US-IL' },
  { value: 'IL_normal', label: 'IL - Normal', state: 'US-IL' },
  { value: 'IL_north_chicago', label: 'IL - North Chicago', state: 'US-IL' },
  { value: 'IL_oak_park', label: 'IL - Oak Park', state: 'US-IL' },
  { value: 'IL_oregon', label: 'IL - Oregon', state: 'US-IL' },
  { value: 'IL_ottawa', label: 'IL - Ottawa', state: 'US-IL' },
  { value: 'IL_palatine', label: 'IL - Palatine', state: 'US-IL' },
  { value: 'IL_park_forest', label: 'IL - Park Forest', state: 'US-IL' },
  { value: 'IL_park_ridge', label: 'IL - Park Ridge', state: 'US-IL' },
  { value: 'IL_pekin', label: 'IL - Pekin', state: 'US-IL' },
  { value: 'IL_peoria', label: 'IL - Peoria', state: 'US-IL' },
  { value: 'IL_petersburg', label: 'IL - Petersburg', state: 'US-IL' },
  { value: 'IL_pontiac', label: 'IL - Pontiac', state: 'US-IL' },
  { value: 'IL_quincy', label: 'IL - Quincy', state: 'US-IL' },
  { value: 'IL_rantoul', label: 'IL - Rantoul', state: 'US-IL' },
  { value: 'IL_river_forest', label: 'IL - River Forest', state: 'US-IL' },
  { value: 'IL_rock_island', label: 'IL - Rock Island', state: 'US-IL' },
  { value: 'IL_rockford', label: 'IL - Rockford', state: 'US-IL' },
  { value: 'IL_salem', label: 'IL - Salem', state: 'US-IL' },
  { value: 'IL_shawneetown', label: 'IL - Shawneetown', state: 'US-IL' },
  { value: 'IL_skokie', label: 'IL - Skokie', state: 'US-IL' },
  { value: 'IL_south_holland', label: 'IL - South Holland', state: 'US-IL' },
  { value: 'IL_springfield', label: 'IL - Springfield', state: 'US-IL' },
  { value: 'IL_streator', label: 'IL - Streator', state: 'US-IL' },
  { value: 'IL_summit', label: 'IL - Summit', state: 'US-IL' },
  { value: 'IL_urbana', label: 'IL - Urbana', state: 'US-IL' },
  { value: 'IL_vandalia', label: 'IL - Vandalia', state: 'US-IL' },
  { value: 'IL_virden', label: 'IL - Virden', state: 'US-IL' },
  { value: 'IL_waukegan', label: 'IL - Waukegan', state: 'US-IL' },
  { value: 'IL_wheaton', label: 'IL - Wheaton', state: 'US-IL' },
  { value: 'IL_wilmette', label: 'IL - Wilmette', state: 'US-IL' },
  { value: 'IL_winnetka', label: 'IL - Winnetka', state: 'US-IL' },
  { value: 'IL_wood_river', label: 'IL - Wood River', state: 'US-IL' },
  { value: 'IL_zion', label: 'IL - Zion', state: 'US-IL' },

  { value: 'IN_anderson', label: 'IN - Anderson', state: 'US-IN' },
  { value: 'IN_bedford', label: 'IN - Bedford', state: 'US-IN' },
  { value: 'IN_bloomington', label: 'IN - Bloomington', state: 'US-IN' },
  { value: 'IN_columbus', label: 'IN - Columbus', state: 'US-IN' },
  { value: 'IN_connersville', label: 'IN - Connersville', state: 'US-IN' },
  { value: 'IN_corydon', label: 'IN - Corydon', state: 'US-IN' },
  { value: 'IN_crawfordsville', label: 'IN - Crawfordsville', state: 'US-IN' },
  { value: 'IN_east_chicago', label: 'IN - East Chicago', state: 'US-IN' },
  { value: 'IN_elkhart', label: 'IN - Elkhart', state: 'US-IN' },
  { value: 'IN_elwood', label: 'IN - Elwood', state: 'US-IN' },
  { value: 'IN_evansville', label: 'IN - Evansville', state: 'US-IN' },
  { value: 'IN_fort_wayne', label: 'IN - Fort Wayne', state: 'US-IN' },
  { value: 'IN_french_lick', label: 'IN - French Lick', state: 'US-IN' },
  { value: 'IN_gary', label: 'IN - Gary', state: 'US-IN' },
  { value: 'IN_geneva', label: 'IN - Geneva', state: 'US-IN' },
  { value: 'IN_goshen', label: 'IN - Goshen', state: 'US-IN' },
  { value: 'IN_greenfield', label: 'IN - Greenfield', state: 'US-IN' },
  { value: 'IN_hammond', label: 'IN - Hammond', state: 'US-IN' },
  { value: 'IN_hobart', label: 'IN - Hobart', state: 'US-IN' },
  { value: 'IN_huntington', label: 'IN - Huntington', state: 'US-IN' },
  { value: 'IN_indianapolis', label: 'IN - Indianapolis', state: 'US-IN' },
  { value: 'IN_jeffersonville', label: 'IN - Jeffersonville', state: 'US-IN' },
  { value: 'IN_kokomo', label: 'IN - Kokomo', state: 'US-IN' },
  { value: 'IN_lafayette', label: 'IN - Lafayette', state: 'US-IN' },
  { value: 'IN_madison', label: 'IN - Madison', state: 'US-IN' },
  { value: 'IN_marion', label: 'IN - Marion', state: 'US-IN' },
  { value: 'IN_michigan_city', label: 'IN - Michigan City', state: 'US-IN' },
  { value: 'IN_mishawaka', label: 'IN - Mishawaka', state: 'US-IN' },
  { value: 'IN_muncie', label: 'IN - Muncie', state: 'US-IN' },
  { value: 'IN_nappanee', label: 'IN - Nappanee', state: 'US-IN' },
  { value: 'IN_nashville', label: 'IN - Nashville', state: 'US-IN' },
  { value: 'IN_new_albany', label: 'IN - New Albany', state: 'US-IN' },
  { value: 'IN_new_castle', label: 'IN - New Castle', state: 'US-IN' },
  { value: 'IN_new_harmony', label: 'IN - New Harmony', state: 'US-IN' },
  { value: 'IN_peru', label: 'IN - Peru', state: 'US-IN' },
  { value: 'IN_plymouth', label: 'IN - Plymouth', state: 'US-IN' },
  { value: 'IN_richmond', label: 'IN - Richmond', state: 'US-IN' },
  { value: 'IN_santa_claus', label: 'IN - Santa Claus', state: 'US-IN' },
  { value: 'IN_shelbyville', label: 'IN - Shelbyville', state: 'US-IN' },
  { value: 'IN_south_bend', label: 'IN - South Bend', state: 'US-IN' },
  { value: 'IN_terre_haute', label: 'IN - Terre Haute', state: 'US-IN' },
  { value: 'IN_valparaiso', label: 'IN - Valparaiso', state: 'US-IN' },
  { value: 'IN_vincennes', label: 'IN - Vincennes', state: 'US-IN' },
  { value: 'IN_wabash', label: 'IN - Wabash', state: 'US-IN' },
  { value: 'IN_west_lafayette', label: 'IN - West Lafayette', state: 'US-IN' },

  { value: 'IA_amana_colonies', label: 'IA - Amana Colonies', state: 'US-IA' },
  { value: 'IA_ames', label: 'IA - Ames', state: 'US-IA' },
  { value: 'IA_boone', label: 'IA - Boone', state: 'US-IA' },
  { value: 'IA_burlington', label: 'IA - Burlington', state: 'US-IA' },
  { value: 'IA_cedar_falls', label: 'IA - Cedar Falls', state: 'US-IA' },
  { value: 'IA_cedar_rapids', label: 'IA - Cedar Rapids', state: 'US-IA' },
  { value: 'IA_charles_city', label: 'IA - Charles City', state: 'US-IA' },
  { value: 'IA_cherokee', label: 'IA - Cherokee', state: 'US-IA' },
  { value: 'IA_clinton', label: 'IA - Clinton', state: 'US-IA' },
  { value: 'IA_council_bluffs', label: 'IA - Council Bluffs', state: 'US-IA' },
  { value: 'IA_davenport', label: 'IA - Davenport', state: 'US-IA' },
  { value: 'IA_des_moines', label: 'IA - Des Moines', state: 'US-IA' },
  { value: 'IA_dubuque', label: 'IA - Dubuque', state: 'US-IA' },
  { value: 'IA_estherville', label: 'IA - Estherville', state: 'US-IA' },
  { value: 'IA_fairfield', label: 'IA - Fairfield', state: 'US-IA' },
  { value: 'IA_fort_dodge', label: 'IA - Fort Dodge', state: 'US-IA' },
  { value: 'IA_grinnell', label: 'IA - Grinnell', state: 'US-IA' },
  { value: 'IA_indianola', label: 'IA - Indianola', state: 'US-IA' },
  { value: 'IA_iowa_city', label: 'IA - Iowa City', state: 'US-IA' },
  { value: 'IA_keokuk', label: 'IA - Keokuk', state: 'US-IA' },
  { value: 'IA_mason_city', label: 'IA - Mason City', state: 'US-IA' },
  { value: 'IA_mount_pleasant', label: 'IA - Mount Pleasant', state: 'US-IA' },
  { value: 'IA_muscatine', label: 'IA - Muscatine', state: 'US-IA' },
  { value: 'IA_newton', label: 'IA - Newton', state: 'US-IA' },
  { value: 'IA_oskaloosa', label: 'IA - Oskaloosa', state: 'US-IA' },
  { value: 'IA_ottumwa', label: 'IA - Ottumwa', state: 'US-IA' },
  { value: 'IA_sioux_city', label: 'IA - Sioux City', state: 'US-IA' },
  { value: 'IA_waterloo', label: 'IA - Waterloo', state: 'US-IA' },
  { value: 'IA_webster_city', label: 'IA - Webster City', state: 'US-IA' },
  { value: 'IA_west_des_moines', label: 'IA - West Des Moines', state: 'US-IA' },

  { value: 'KS_abilene', label: 'KS - Abilene', state: 'US-KS' },
  { value: 'KS_arkansas_city', label: 'KS - Arkansas City', state: 'US-KS' },
  { value: 'KS_atchison', label: 'KS - Atchison', state: 'US-KS' },
  { value: 'KS_chanute', label: 'KS - Chanute', state: 'US-KS' },
  { value: 'KS_coffeyville', label: 'KS - Coffeyville', state: 'US-KS' },
  { value: 'KS_council_grove', label: 'KS - Council Grove', state: 'US-KS' },
  { value: 'KS_dodge_city', label: 'KS - Dodge City', state: 'US-KS' },
  { value: 'KS_emporia', label: 'KS - Emporia', state: 'US-KS' },
  { value: 'KS_fort_scott', label: 'KS - Fort Scott', state: 'US-KS' },
  { value: 'KS_garden_city', label: 'KS - Garden City', state: 'US-KS' },
  { value: 'KS_great_bend', label: 'KS - Great Bend', state: 'US-KS' },
  { value: 'KS_hays', label: 'KS - Hays', state: 'US-KS' },
  { value: 'KS_hutchinson', label: 'KS - Hutchinson', state: 'US-KS' },
  { value: 'KS_independence', label: 'KS - Independence', state: 'US-KS' },
  { value: 'KS_junction_city', label: 'KS - Junction City', state: 'US-KS' },
  { value: 'KS_kansas_city', label: 'KS - Kansas City', state: 'US-KS' },
  { value: 'KS_lawrence', label: 'KS - Lawrence', state: 'US-KS' },
  { value: 'KS_leavenworth', label: 'KS - Leavenworth', state: 'US-KS' },
  { value: 'KS_liberal', label: 'KS - Liberal', state: 'US-KS' },
  { value: 'KS_manhattan', label: 'KS - Manhattan', state: 'US-KS' },
  { value: 'KS_mcpherson', label: 'KS - McPherson', state: 'US-KS' },
  { value: 'KS_medicine_lodge', label: 'KS - Medicine Lodge', state: 'US-KS' },
  { value: 'KS_newton', label: 'KS - Newton', state: 'US-KS' },
  { value: 'KS_olathe', label: 'KS - Olathe', state: 'US-KS' },
  { value: 'KS_osawatomie', label: 'KS - Osawatomie', state: 'US-KS' },
  { value: 'KS_ottawa', label: 'KS - Ottawa', state: 'US-KS' },
  { value: 'KS_overland_park', label: 'KS - Overland Park', state: 'US-KS' },
  { value: 'KS_pittsburg', label: 'KS - Pittsburg', state: 'US-KS' },
  { value: 'KS_salina', label: 'KS - Salina', state: 'US-KS' },
  { value: 'KS_shawnee', label: 'KS - Shawnee', state: 'US-KS' },
  { value: 'KS_smith_center', label: 'KS - Smith Center', state: 'US-KS' },
  { value: 'KS_topeka', label: 'KS - Topeka', state: 'US-KS' },
  { value: 'KS_wichita', label: 'KS - Wichita', state: 'US-KS' },

  { value: 'KY_ashland', label: 'KY - Ashland', state: 'US-KY' },
  { value: 'KY_barbourville', label: 'KY - Barbourville', state: 'US-KY' },
  { value: 'KY_bardstown', label: 'KY - Bardstown', state: 'US-KY' },
  { value: 'KY_berea', label: 'KY - Berea', state: 'US-KY' },
  { value: 'KY_boonesborough', label: 'KY - Boonesborough', state: 'US-KY' },
  { value: 'KY_bowling_green', label: 'KY - Bowling Green', state: 'US-KY' },
  { value: 'KY_campbellsville', label: 'KY - Campbellsville', state: 'US-KY' },
  { value: 'KY_covington', label: 'KY - Covington', state: 'US-KY' },
  { value: 'KY_danville', label: 'KY - Danville', state: 'US-KY' },
  { value: 'KY_elizabethtown', label: 'KY - Elizabethtown', state: 'US-KY' },
  { value: 'KY_frankfort', label: 'KY - Frankfort', state: 'US-KY' },
  { value: 'KY_harlan', label: 'KY - Harlan', state: 'US-KY' },
  { value: 'KY_harrodsburg', label: 'KY - Harrodsburg', state: 'US-KY' },
  { value: 'KY_hazard', label: 'KY - Hazard', state: 'US-KY' },
  { value: 'KY_henderson', label: 'KY - Henderson', state: 'US-KY' },
  { value: 'KY_hodgenville', label: 'KY - Hodgenville', state: 'US-KY' },
  { value: 'KY_hopkinsville', label: 'KY - Hopkinsville', state: 'US-KY' },
  { value: 'KY_lexington', label: 'KY - Lexington', state: 'US-KY' },
  { value: 'KY_louisville', label: 'KY - Louisville', state: 'US-KY' },
  { value: 'KY_mayfield', label: 'KY - Mayfield', state: 'US-KY' },
  { value: 'KY_maysville', label: 'KY - Maysville', state: 'US-KY' },
  { value: 'KY_middlesboro', label: 'KY - Middlesboro', state: 'US-KY' },
  { value: 'KY_newport', label: 'KY - Newport', state: 'US-KY' },
  { value: 'KY_owensboro', label: 'KY - Owensboro', state: 'US-KY' },
  { value: 'KY_paducah', label: 'KY - Paducah', state: 'US-KY' },
  { value: 'KY_paris', label: 'KY - Paris', state: 'US-KY' },
  { value: 'KY_richmond', label: 'KY - Richmond', state: 'US-KY' },

  { value: 'LA_abbeville', label: 'LA - Abbeville', state: 'US-LA' },
  { value: 'LA_alexandria', label: 'LA - Alexandria', state: 'US-LA' },
  { value: 'LA_bastrop', label: 'LA - Bastrop', state: 'US-LA' },
  { value: 'LA_baton_rouge', label: 'LA - Baton Rouge', state: 'US-LA' },
  { value: 'LA_bogalusa', label: 'LA - Bogalusa', state: 'US-LA' },
  { value: 'LA_bossier_city', label: 'LA - Bossier City', state: 'US-LA' },
  { value: 'LA_gretna', label: 'LA - Gretna', state: 'US-LA' },
  { value: 'LA_houma', label: 'LA - Houma', state: 'US-LA' },
  { value: 'LA_lafayette', label: 'LA - Lafayette', state: 'US-LA' },
  { value: 'LA_lake_charles', label: 'LA - Lake Charles', state: 'US-LA' },
  { value: 'LA_monroe', label: 'LA - Monroe', state: 'US-LA' },
  { value: 'LA_morgan_city', label: 'LA - Morgan City', state: 'US-LA' },
  { value: 'LA_natchitoches', label: 'LA - Natchitoches', state: 'US-LA' },
  { value: 'LA_new_iberia', label: 'LA - New Iberia', state: 'US-LA' },
  { value: 'LA_new_orleans', label: 'LA - New Orleans', state: 'US-LA' },
  { value: 'LA_opelousas', label: 'LA - Opelousas', state: 'US-LA' },
  { value: 'LA_ruston', label: 'LA - Ruston', state: 'US-LA' },
  { value: 'LA_saint_martinville', label: 'LA - Saint Martinville', state: 'US-LA' },
  { value: 'LA_shreveport', label: 'LA - Shreveport', state: 'US-LA' },
  { value: 'LA_thibodaux', label: 'LA - Thibodaux', state: 'US-LA' },

  { value: 'ME_auburn', label: 'ME - Auburn', state: 'US-ME' },
  { value: 'ME_augusta', label: 'ME - Augusta', state: 'US-ME' },
  { value: 'ME_bangor', label: 'ME - Bangor', state: 'US-ME' },
  { value: 'ME_bar_harbor', label: 'ME - Bar Harbor', state: 'US-ME' },
  { value: 'ME_bath', label: 'ME - Bath', state: 'US-ME' },
  { value: 'ME_belfast', label: 'ME - Belfast', state: 'US-ME' },
  { value: 'ME_biddeford', label: 'ME - Biddeford', state: 'US-ME' },
  { value: 'ME_boothbay_harbor', label: 'ME - Boothbay Harbor', state: 'US-ME' },
  { value: 'ME_brunswick', label: 'ME - Brunswick', state: 'US-ME' },
  { value: 'ME_calais', label: 'ME - Calais', state: 'US-ME' },
  { value: 'ME_caribou', label: 'ME - Caribou', state: 'US-ME' },
  { value: 'ME_castine', label: 'ME - Castine', state: 'US-ME' },
  { value: 'ME_eastport', label: 'ME - Eastport', state: 'US-ME' },
  { value: 'ME_ellsworth', label: 'ME - Ellsworth', state: 'US-ME' },
  { value: 'ME_farmington', label: 'ME - Farmington', state: 'US-ME' },
  { value: 'ME_fort_kent', label: 'ME - Fort Kent', state: 'US-ME' },
  { value: 'ME_gardiner', label: 'ME - Gardiner', state: 'US-ME' },
  { value: 'ME_houlton', label: 'ME - Houlton', state: 'US-ME' },
  { value: 'ME_kennebunkport', label: 'ME - Kennebunkport', state: 'US-ME' },
  { value: 'ME_kittery', label: 'ME - Kittery', state: 'US-ME' },
  { value: 'ME_lewiston', label: 'ME - Lewiston', state: 'US-ME' },
  { value: 'ME_lubec', label: 'ME - Lubec', state: 'US-ME' },
  { value: 'ME_machias', label: 'ME - Machias', state: 'US-ME' },
  { value: 'ME_orono', label: 'ME - Orono', state: 'US-ME' },
  { value: 'ME_portland', label: 'ME - Portland', state: 'US-ME' },
  { value: 'ME_presque_isle', label: 'ME - Presque Isle', state: 'US-ME' },
  { value: 'ME_rockland', label: 'ME - Rockland', state: 'US-ME' },
  { value: 'ME_rumford', label: 'ME - Rumford', state: 'US-ME' },
  { value: 'ME_saco', label: 'ME - Saco', state: 'US-ME' },
  { value: 'ME_scarborough', label: 'ME - Scarborough', state: 'US-ME' },
  { value: 'ME_waterville', label: 'ME - Waterville', state: 'US-ME' },
  { value: 'ME_york', label: 'ME - York', state: 'US-ME' },

  { value: 'MD_aberdeen', label: 'MD - Aberdeen', state: 'US-MD' },
  { value: 'MD_annapolis', label: 'MD - Annapolis', state: 'US-MD' },
  { value: 'MD_baltimore', label: 'MD - Baltimore', state: 'US-MD' },
  { value: 'MD_bethesda-chevy_chase', label: 'MD - Bethesda-Chevy Chase', state: 'US-MD' },
  { value: 'MD_bowie', label: 'MD - Bowie', state: 'US-MD' },
  { value: 'MD_cambridge', label: 'MD - Cambridge', state: 'US-MD' },
  { value: 'MD_catonsville', label: 'MD - Catonsville', state: 'US-MD' },
  { value: 'MD_college_park', label: 'MD - College Park', state: 'US-MD' },
  { value: 'MD_columbia', label: 'MD - Columbia', state: 'US-MD' },
  { value: 'MD_cumberland', label: 'MD - Cumberland', state: 'US-MD' },
  { value: 'MD_easton', label: 'MD - Easton', state: 'US-MD' },
  { value: 'MD_elkton', label: 'MD - Elkton', state: 'US-MD' },
  { value: 'MD_emmitsburg', label: 'MD - Emmitsburg', state: 'US-MD' },
  { value: 'MD_frederick', label: 'MD - Frederick', state: 'US-MD' },
  { value: 'MD_greenbelt', label: 'MD - Greenbelt', state: 'US-MD' },
  { value: 'MD_hagerstown', label: 'MD - Hagerstown', state: 'US-MD' },
  { value: 'MD_hyattsville', label: 'MD - Hyattsville', state: 'US-MD' },
  { value: 'MD_laurel', label: 'MD - Laurel', state: 'US-MD' },
  { value: 'MD_oakland', label: 'MD - Oakland', state: 'US-MD' },
  { value: 'MD_ocean_city', label: 'MD - Ocean City', state: 'US-MD' },
  { value: 'MD_rockville', label: 'MD - Rockville', state: 'US-MD' },
  { value: 'MD_saint_marys_city', label: 'MD - Saint Marys City', state: 'US-MD' },
  { value: 'MD_salisbury', label: 'MD - Salisbury', state: 'US-MD' },
  { value: 'MD_silver_spring', label: 'MD - Silver Spring', state: 'US-MD' },
  { value: 'MD_takoma_park', label: 'MD - Takoma Park', state: 'US-MD' },
  { value: 'MD_towson', label: 'MD - Towson', state: 'US-MD' },
  { value: 'MD_westminster', label: 'MD - Westminster', state: 'US-MD' },

  { value: 'MA_abington', label: 'MA - Abington', state: 'US-MA' },
  { value: 'MA_adams', label: 'MA - Adams', state: 'US-MA' },
  { value: 'MA_amesbury', label: 'MA - Amesbury', state: 'US-MA' },
  { value: 'MA_amherst', label: 'MA - Amherst', state: 'US-MA' },
  { value: 'MA_andover', label: 'MA - Andover', state: 'US-MA' },
  { value: 'MA_arlington', label: 'MA - Arlington', state: 'US-MA' },
  { value: 'MA_athol', label: 'MA - Athol', state: 'US-MA' },
  { value: 'MA_attleboro', label: 'MA - Attleboro', state: 'US-MA' },
  { value: 'MA_barnstable', label: 'MA - Barnstable', state: 'US-MA' },
  { value: 'MA_bedford', label: 'MA - Bedford', state: 'US-MA' },
  { value: 'MA_beverly', label: 'MA - Beverly', state: 'US-MA' },
  { value: 'MA_boston', label: 'MA - Boston', state: 'US-MA' },
  { value: 'MA_bourne', label: 'MA - Bourne', state: 'US-MA' },
  { value: 'MA_braintree', label: 'MA - Braintree', state: 'US-MA' },
  { value: 'MA_brockton', label: 'MA - Brockton', state: 'US-MA' },
  { value: 'MA_brookline', label: 'MA - Brookline', state: 'US-MA' },
  { value: 'MA_cambridge', label: 'MA - Cambridge', state: 'US-MA' },
  { value: 'MA_canton', label: 'MA - Canton', state: 'US-MA' },
  { value: 'MA_charlestown', label: 'MA - Charlestown', state: 'US-MA' },
  { value: 'MA_chelmsford', label: 'MA - Chelmsford', state: 'US-MA' },
  { value: 'MA_chelsea', label: 'MA - Chelsea', state: 'US-MA' },
  { value: 'MA_chicopee', label: 'MA - Chicopee', state: 'US-MA' },
  { value: 'MA_clinton', label: 'MA - Clinton', state: 'US-MA' },
  { value: 'MA_cohasset', label: 'MA - Cohasset', state: 'US-MA' },
  { value: 'MA_concord', label: 'MA - Concord', state: 'US-MA' },
  { value: 'MA_danvers', label: 'MA - Danvers', state: 'US-MA' },
  { value: 'MA_dartmouth', label: 'MA - Dartmouth', state: 'US-MA' },
  { value: 'MA_dedham', label: 'MA - Dedham', state: 'US-MA' },
  { value: 'MA_dennis', label: 'MA - Dennis', state: 'US-MA' },
  { value: 'MA_duxbury', label: 'MA - Duxbury', state: 'US-MA' },
  { value: 'MA_eastham', label: 'MA - Eastham', state: 'US-MA' },
  { value: 'MA_edgartown', label: 'MA - Edgartown', state: 'US-MA' },
  { value: 'MA_everett', label: 'MA - Everett', state: 'US-MA' },
  { value: 'MA_fairhaven', label: 'MA - Fairhaven', state: 'US-MA' },
  { value: 'MA_fall_river', label: 'MA - Fall River', state: 'US-MA' },
  { value: 'MA_falmouth', label: 'MA - Falmouth', state: 'US-MA' },
  { value: 'MA_fitchburg', label: 'MA - Fitchburg', state: 'US-MA' },
  { value: 'MA_framingham', label: 'MA - Framingham', state: 'US-MA' },
  { value: 'MA_gloucester', label: 'MA - Gloucester', state: 'US-MA' },
  { value: 'MA_great_barrington', label: 'MA - Great Barrington', state: 'US-MA' },
  { value: 'MA_greenfield', label: 'MA - Greenfield', state: 'US-MA' },
  { value: 'MA_groton', label: 'MA - Groton', state: 'US-MA' },
  { value: 'MA_harwich', label: 'MA - Harwich', state: 'US-MA' },
  { value: 'MA_haverhill', label: 'MA - Haverhill', state: 'US-MA' },
  { value: 'MA_hingham', label: 'MA - Hingham', state: 'US-MA' },
  { value: 'MA_holyoke', label: 'MA - Holyoke', state: 'US-MA' },
  { value: 'MA_hyannis', label: 'MA - Hyannis', state: 'US-MA' },
  { value: 'MA_ipswich', label: 'MA - Ipswich', state: 'US-MA' },
  { value: 'MA_lawrence', label: 'MA - Lawrence', state: 'US-MA' },
  { value: 'MA_lenox', label: 'MA - Lenox', state: 'US-MA' },
  { value: 'MA_leominster', label: 'MA - Leominster', state: 'US-MA' },
  { value: 'MA_lexington', label: 'MA - Lexington', state: 'US-MA' },
  { value: 'MA_lowell', label: 'MA - Lowell', state: 'US-MA' },
  { value: 'MA_ludlow', label: 'MA - Ludlow', state: 'US-MA' },
  { value: 'MA_lynn', label: 'MA - Lynn', state: 'US-MA' },
  { value: 'MA_malden', label: 'MA - Malden', state: 'US-MA' },
  { value: 'MA_marblehead', label: 'MA - Marblehead', state: 'US-MA' },
  { value: 'MA_marlborough', label: 'MA - Marlborough', state: 'US-MA' },
  { value: 'MA_medford', label: 'MA - Medford', state: 'US-MA' },
  { value: 'MA_milton', label: 'MA - Milton', state: 'US-MA' },
  { value: 'MA_nahant', label: 'MA - Nahant', state: 'US-MA' },
  { value: 'MA_natick', label: 'MA - Natick', state: 'US-MA' },
  { value: 'MA_new_bedford', label: 'MA - New Bedford', state: 'US-MA' },
  { value: 'MA_newburyport', label: 'MA - Newburyport', state: 'US-MA' },
  { value: 'MA_newton', label: 'MA - Newton', state: 'US-MA' },
  { value: 'MA_north_adams', label: 'MA - North Adams', state: 'US-MA' },
  { value: 'MA_northampton', label: 'MA - Northampton', state: 'US-MA' },
  { value: 'MA_norton', label: 'MA - Norton', state: 'US-MA' },
  { value: 'MA_norwood', label: 'MA - Norwood', state: 'US-MA' },
  { value: 'MA_peabody', label: 'MA - Peabody', state: 'US-MA' },
  { value: 'MA_pittsfield', label: 'MA - Pittsfield', state: 'US-MA' },
  { value: 'MA_plymouth', label: 'MA - Plymouth', state: 'US-MA' },
  { value: 'MA_provincetown', label: 'MA - Provincetown', state: 'US-MA' },
  { value: 'MA_quincy', label: 'MA - Quincy', state: 'US-MA' },
  { value: 'MA_randolph', label: 'MA - Randolph', state: 'US-MA' },
  { value: 'MA_revere', label: 'MA - Revere', state: 'US-MA' },
  { value: 'MA_salem', label: 'MA - Salem', state: 'US-MA' },
  { value: 'MA_sandwich', label: 'MA - Sandwich', state: 'US-MA' },
  { value: 'MA_saugus', label: 'MA - Saugus', state: 'US-MA' },
  { value: 'MA_somerville', label: 'MA - Somerville', state: 'US-MA' },
  { value: 'MA_hadley', label: 'MA - Hadley', state: 'US-MA' },
  { value: 'MA_springfield', label: 'MA - Springfield', state: 'US-MA' },
  { value: 'MA_stockbridge', label: 'MA - Stockbridge', state: 'US-MA' },
  { value: 'MA_stoughton', label: 'MA - Stoughton', state: 'US-MA' },
  { value: 'MA_sturbridge', label: 'MA - Sturbridge', state: 'US-MA' },
  { value: 'MA_sudbury', label: 'MA - Sudbury', state: 'US-MA' },
  { value: 'MA_taunton', label: 'MA - Taunton', state: 'US-MA' },
  { value: 'MA_tewksbury', label: 'MA - Tewksbury', state: 'US-MA' },
  { value: 'MA_truro', label: 'MA - Truro', state: 'US-MA' },
  { value: 'MA_watertown', label: 'MA - Watertown', state: 'US-MA' },
  { value: 'MA_webster', label: 'MA - Webster', state: 'US-MA' },
  { value: 'MA_wellesley', label: 'MA - Wellesley', state: 'US-MA' },
  { value: 'MA_wellfleet', label: 'MA - Wellfleet', state: 'US-MA' },
  { value: 'MA_west_bridgewater', label: 'MA - West Bridgewater', state: 'US-MA' },
  { value: 'MA_west_springfield', label: 'MA - West Springfield', state: 'US-MA' },
  { value: 'MA_westfield', label: 'MA - Westfield', state: 'US-MA' },
  { value: 'MA_weymouth', label: 'MA - Weymouth', state: 'US-MA' },
  { value: 'MA_whitman', label: 'MA - Whitman', state: 'US-MA' },
  { value: 'MA_williamstown', label: 'MA - Williamstown', state: 'US-MA' },
  { value: 'MA_woburn', label: 'MA - Woburn', state: 'US-MA' },
  { value: 'MA_woods_hole', label: 'MA - Woods Hole', state: 'US-MA' },
  { value: 'MA_worcester', label: 'MA - Worcester', state: 'US-MA' },

  { value: 'MI_adrian', label: 'MI - Adrian', state: 'US-MI' },
  { value: 'MI_alma', label: 'MI - Alma', state: 'US-MI' },
  { value: 'MI_ann_arbor', label: 'MI - Ann Arbor', state: 'US-MI' },
  { value: 'MI_battle_creek', label: 'MI - Battle Creek', state: 'US-MI' },
  { value: 'MI_bay_city', label: 'MI - Bay City', state: 'US-MI' },
  { value: 'MI_benton_harbor', label: 'MI - Benton Harbor', state: 'US-MI' },
  { value: 'MI_bloomfield_hills', label: 'MI - Bloomfield Hills', state: 'US-MI' },
  { value: 'MI_cadillac', label: 'MI - Cadillac', state: 'US-MI' },
  { value: 'MI_charlevoix', label: 'MI - Charlevoix', state: 'US-MI' },
  { value: 'MI_cheboygan', label: 'MI - Cheboygan', state: 'US-MI' },
  { value: 'MI_dearborn', label: 'MI - Dearborn', state: 'US-MI' },
  { value: 'MI_detroit', label: 'MI - Detroit', state: 'US-MI' },
  { value: 'MI_east_lansing', label: 'MI - East Lansing', state: 'US-MI' },
  { value: 'MI_eastpointe', label: 'MI - Eastpointe', state: 'US-MI' },
  { value: 'MI_ecorse', label: 'MI - Ecorse', state: 'US-MI' },
  { value: 'MI_escanaba', label: 'MI - Escanaba', state: 'US-MI' },
  { value: 'MI_flint', label: 'MI - Flint', state: 'US-MI' },
  { value: 'MI_grand_haven', label: 'MI - Grand Haven', state: 'US-MI' },
  { value: 'MI_grand_rapids', label: 'MI - Grand Rapids', state: 'US-MI' },
  { value: 'MI_grayling', label: 'MI - Grayling', state: 'US-MI' },
  { value: 'MI_grosse_pointe', label: 'MI - Grosse Pointe', state: 'US-MI' },
  { value: 'MI_hancock', label: 'MI - Hancock', state: 'US-MI' },
  { value: 'MI_highland_park', label: 'MI - Highland Park', state: 'US-MI' },
  { value: 'MI_holland', label: 'MI - Holland', state: 'US-MI' },
  { value: 'MI_houghton', label: 'MI - Houghton', state: 'US-MI' },
  { value: 'MI_interlochen', label: 'MI - Interlochen', state: 'US-MI' },
  { value: 'MI_iron_mountain', label: 'MI - Iron Mountain', state: 'US-MI' },
  { value: 'MI_ironwood', label: 'MI - Ironwood', state: 'US-MI' },
  { value: 'MI_ishpeming', label: 'MI - Ishpeming', state: 'US-MI' },
  { value: 'MI_jackson', label: 'MI - Jackson', state: 'US-MI' },
  { value: 'MI_kalamazoo', label: 'MI - Kalamazoo', state: 'US-MI' },
  { value: 'MI_lansing', label: 'MI - Lansing', state: 'US-MI' },
  { value: 'MI_livonia', label: 'MI - Livonia', state: 'US-MI' },
  { value: 'MI_ludington', label: 'MI - Ludington', state: 'US-MI' },
  { value: 'MI_mackinaw_city', label: 'MI - Mackinaw City', state: 'US-MI' },
  { value: 'MI_manistee', label: 'MI - Manistee', state: 'US-MI' },
  { value: 'MI_marquette', label: 'MI - Marquette', state: 'US-MI' },
  { value: 'MI_menominee', label: 'MI - Menominee', state: 'US-MI' },
  { value: 'MI_midland', label: 'MI - Midland', state: 'US-MI' },
  { value: 'MI_monroe', label: 'MI - Monroe', state: 'US-MI' },
  { value: 'MI_mount_clemens', label: 'MI - Mount Clemens', state: 'US-MI' },
  { value: 'MI_mount_pleasant', label: 'MI - Mount Pleasant', state: 'US-MI' },
  { value: 'MI_muskegon', label: 'MI - Muskegon', state: 'US-MI' },
  { value: 'MI_niles', label: 'MI - Niles', state: 'US-MI' },
  { value: 'MI_petoskey', label: 'MI - Petoskey', state: 'US-MI' },
  { value: 'MI_pontiac', label: 'MI - Pontiac', state: 'US-MI' },
  { value: 'MI_port_huron', label: 'MI - Port Huron', state: 'US-MI' },
  { value: 'MI_royal_oak', label: 'MI - Royal Oak', state: 'US-MI' },
  { value: 'MI_saginaw', label: 'MI - Saginaw', state: 'US-MI' },
  { value: 'MI_saint_ignace', label: 'MI - Saint Ignace', state: 'US-MI' },
  { value: 'MI_saint_joseph', label: 'MI - Saint Joseph', state: 'US-MI' },
  { value: 'MI_sault_sainte_marie', label: 'MI - Sault Sainte Marie', state: 'US-MI' },
  { value: 'MI_traverse_city', label: 'MI - Traverse City', state: 'US-MI' },
  { value: 'MI_trenton', label: 'MI - Trenton', state: 'US-MI' },
  { value: 'MI_warren', label: 'MI - Warren', state: 'US-MI' },
  { value: 'MI_wyandotte', label: 'MI - Wyandotte', state: 'US-MI' },
  { value: 'MI_ypsilanti', label: 'MI - Ypsilanti', state: 'US-MI' },

  { value: 'MN_albert_lea', label: 'MN - Albert Lea', state: 'US-MN' },
  { value: 'MN_alexandria', label: 'MN - Alexandria', state: 'US-MN' },
  { value: 'MN_austin', label: 'MN - Austin', state: 'US-MN' },
  { value: 'MN_bemidji', label: 'MN - Bemidji', state: 'US-MN' },
  { value: 'MN_bloomington', label: 'MN - Bloomington', state: 'US-MN' },
  { value: 'MN_brainerd', label: 'MN - Brainerd', state: 'US-MN' },
  { value: 'MN_crookston', label: 'MN - Crookston', state: 'US-MN' },
  { value: 'MN_duluth', label: 'MN - Duluth', state: 'US-MN' },
  { value: 'MN_ely', label: 'MN - Ely', state: 'US-MN' },
  { value: 'MN_eveleth', label: 'MN - Eveleth', state: 'US-MN' },
  { value: 'MN_faribault', label: 'MN - Faribault', state: 'US-MN' },
  { value: 'MN_fergus_falls', label: 'MN - Fergus Falls', state: 'US-MN' },
  { value: 'MN_hastings', label: 'MN - Hastings', state: 'US-MN' },
  { value: 'MN_hibbing', label: 'MN - Hibbing', state: 'US-MN' },
  { value: 'MN_international_falls', label: 'MN - International Falls', state: 'US-MN' },
  { value: 'MN_little_falls', label: 'MN - Little Falls', state: 'US-MN' },
  { value: 'MN_mankato', label: 'MN - Mankato', state: 'US-MN' },
  { value: 'MN_minneapolis', label: 'MN - Minneapolis', state: 'US-MN' },
  { value: 'MN_moorhead', label: 'MN - Moorhead', state: 'US-MN' },
  { value: 'MN_new_ulm', label: 'MN - New Ulm', state: 'US-MN' },
  { value: 'MN_northfield', label: 'MN - Northfield', state: 'US-MN' },
  { value: 'MN_owatonna', label: 'MN - Owatonna', state: 'US-MN' },
  { value: 'MN_pipestone', label: 'MN - Pipestone', state: 'US-MN' },
  { value: 'MN_red_wing', label: 'MN - Red Wing', state: 'US-MN' },
  { value: 'MN_rochester', label: 'MN - Rochester', state: 'US-MN' },
  { value: 'MN_saint_cloud', label: 'MN - Saint Cloud', state: 'US-MN' },
  { value: 'MN_saint_paul', label: 'MN - Saint Paul', state: 'US-MN' },
  { value: 'MN_sauk_centre', label: 'MN - Sauk Centre', state: 'US-MN' },
  { value: 'MN_saint_paul', label: 'MN - Saint Paul', state: 'US-MN' },
  { value: 'MN_stillwater', label: 'MN - Stillwater', state: 'US-MN' },
  { value: 'MN_virginia', label: 'MN - Virginia', state: 'US-MN' },
  { value: 'MN_willmar', label: 'MN - Willmar', state: 'US-MN' },
  { value: 'MN_winona', label: 'MN - Winona', state: 'US-MN' },

  { value: 'MS_bay_saint_louis', label: 'MS - Bay Saint Louis', state: 'US-MS' },
  { value: 'MS_biloxi', label: 'MS - Biloxi', state: 'US-MS' },
  { value: 'MS_canton', label: 'MS - Canton', state: 'US-MS' },
  { value: 'MS_clarksdale', label: 'MS - Clarksdale', state: 'US-MS' },
  { value: 'MS_columbia', label: 'MS - Columbia', state: 'US-MS' },
  { value: 'MS_columbus', label: 'MS - Columbus', state: 'US-MS' },
  { value: 'MS_corinth', label: 'MS - Corinth', state: 'US-MS' },
  { value: 'MS_greenville', label: 'MS - Greenville', state: 'US-MS' },
  { value: 'MS_greenwood', label: 'MS - Greenwood', state: 'US-MS' },
  { value: 'MS_grenada', label: 'MS - Grenada', state: 'US-MS' },
  { value: 'MS_gulfport', label: 'MS - Gulfport', state: 'US-MS' },
  { value: 'MS_hattiesburg', label: 'MS - Hattiesburg', state: 'US-MS' },
  { value: 'MS_holly_springs', label: 'MS - Holly Springs', state: 'US-MS' },
  { value: 'MS_jackson', label: 'MS - Jackson', state: 'US-MS' },
  { value: 'MS_laurel', label: 'MS - Laurel', state: 'US-MS' },
  { value: 'MS_meridian', label: 'MS - Meridian', state: 'US-MS' },
  { value: 'MS_natchez', label: 'MS - Natchez', state: 'US-MS' },
  { value: 'MS_ocean_springs', label: 'MS - Ocean Springs', state: 'US-MS' },
  { value: 'MS_oxford', label: 'MS - Oxford', state: 'US-MS' },
  { value: 'MS_pascagoula', label: 'MS - Pascagoula', state: 'US-MS' },
  { value: 'MS_pass_christian', label: 'MS - Pass Christian', state: 'US-MS' },
  { value: 'MS_philadelphia', label: 'MS - Philadelphia', state: 'US-MS' },
  { value: 'MS_port_gibson', label: 'MS - Port Gibson', state: 'US-MS' },
  { value: 'MS_starkville', label: 'MS - Starkville', state: 'US-MS' },
  { value: 'MS_tupelo', label: 'MS - Tupelo', state: 'US-MS' },
  { value: 'MS_vicksburg', label: 'MS - Vicksburg', state: 'US-MS' },
  { value: 'MS_west_point', label: 'MS - West Point', state: 'US-MS' },
  { value: 'MS_yazoo_city', label: 'MS - Yazoo City', state: 'US-MS' },

  { value: 'MO_boonville', label: 'MO - Boonville', state: 'US-MO' },
  { value: 'MO_branson', label: 'MO - Branson', state: 'US-MO' },
  { value: 'MO_cape_girardeau', label: 'MO - Cape Girardeau', state: 'US-MO' },
  { value: 'MO_carthage', label: 'MO - Carthage', state: 'US-MO' },
  { value: 'MO_chillicothe', label: 'MO - Chillicothe', state: 'US-MO' },
  { value: 'MO_clayton', label: 'MO - Clayton', state: 'US-MO' },
  { value: 'MO_columbia', label: 'MO - Columbia', state: 'US-MO' },
  { value: 'MO_excelsior_springs', label: 'MO - Excelsior Springs', state: 'US-MO' },
  { value: 'MO_ferguson', label: 'MO - Ferguson', state: 'US-MO' },
  { value: 'MO_florissant', label: 'MO - Florissant', state: 'US-MO' },
  { value: 'MO_fulton', label: 'MO - Fulton', state: 'US-MO' },
  { value: 'MO_hannibal', label: 'MO - Hannibal', state: 'US-MO' },
  { value: 'MO_independence', label: 'MO - Independence', state: 'US-MO' },
  { value: 'MO_jefferson_city', label: 'MO - Jefferson City', state: 'US-MO' },
  { value: 'MO_joplin', label: 'MO - Joplin', state: 'US-MO' },
  { value: 'MO_kansas_city', label: 'MO - Kansas City', state: 'US-MO' },
  { value: 'MO_kirksville', label: 'MO - Kirksville', state: 'US-MO' },
  { value: 'MO_lamar', label: 'MO - Lamar', state: 'US-MO' },
  { value: 'MO_lebanon', label: 'MO - Lebanon', state: 'US-MO' },
  { value: 'MO_lexington', label: 'MO - Lexington', state: 'US-MO' },
  { value: 'MO_maryville', label: 'MO - Maryville', state: 'US-MO' },
  { value: 'MO_mexico', label: 'MO - Mexico', state: 'US-MO' },
  { value: 'MO_monett', label: 'MO - Monett', state: 'US-MO' },
  { value: 'MO_neosho', label: 'MO - Neosho', state: 'US-MO' },
  { value: 'MO_new_madrid', label: 'MO - New Madrid', state: 'US-MO' },
  { value: 'MO_rolla', label: 'MO - Rolla', state: 'US-MO' },
  { value: 'MO_saint_charles', label: 'MO - Saint Charles', state: 'US-MO' },
  { value: 'MO_saint_joseph', label: 'MO - Saint Joseph', state: 'US-MO' },
  { value: 'MO_saint_louis', label: 'MO - Saint Louis', state: 'US-MO' },
  { value: 'MO_sainte_genevieve', label: 'MO - Sainte Genevieve', state: 'US-MO' },
  { value: 'MO_salem', label: 'MO - Salem', state: 'US-MO' },
  { value: 'MO_sedalia', label: 'MO - Sedalia', state: 'US-MO' },
  { value: 'MO_springfield', label: 'MO - Springfield', state: 'US-MO' },
  { value: 'MO_warrensburg', label: 'MO - Warrensburg', state: 'US-MO' },
  { value: 'MO_west_plains', label: 'MO - West Plains', state: 'US-MO' },

  { value: 'MT_anaconda', label: 'MT - Anaconda', state: 'US-MT' },
  { value: 'MT_billings', label: 'MT - Billings', state: 'US-MT' },
  { value: 'MT_bozeman', label: 'MT - Bozeman', state: 'US-MT' },
  { value: 'MT_butte', label: 'MT - Butte', state: 'US-MT' },
  { value: 'MT_dillon', label: 'MT - Dillon', state: 'US-MT' },
  { value: 'MT_fort_benton', label: 'MT - Fort Benton', state: 'US-MT' },
  { value: 'MT_glendive', label: 'MT - Glendive', state: 'US-MT' },
  { value: 'MT_great_falls', label: 'MT - Great Falls', state: 'US-MT' },
  { value: 'MT_havre', label: 'MT - Havre', state: 'US-MT' },
  { value: 'MT_helena', label: 'MT - Helena', state: 'US-MT' },
  { value: 'MT_kalispell', label: 'MT - Kalispell', state: 'US-MT' },
  { value: 'MT_lewistown', label: 'MT - Lewistown', state: 'US-MT' },
  { value: 'MT_livingston', label: 'MT - Livingston', state: 'US-MT' },
  { value: 'MT_miles_city', label: 'MT - Miles City', state: 'US-MT' },
  { value: 'MT_missoula', label: 'MT - Missoula', state: 'US-MT' },
  { value: 'MT_virginia_city', label: 'MT - Virginia City', state: 'US-MT' },

  { value: 'NE_beatrice', label: 'NE - Beatrice', state: 'US-NE' },
  { value: 'NE_bellevue', label: 'NE - Bellevue', state: 'US-NE' },
  { value: 'NE_boys_town', label: 'NE - Boys Town', state: 'US-NE' },
  { value: 'NE_chadron', label: 'NE - Chadron', state: 'US-NE' },
  { value: 'NE_columbus', label: 'NE - Columbus', state: 'US-NE' },
  { value: 'NE_fremont', label: 'NE - Fremont', state: 'US-NE' },
  { value: 'NE_grand_island', label: 'NE - Grand Island', state: 'US-NE' },
  { value: 'NE_hastings', label: 'NE - Hastings', state: 'US-NE' },
  { value: 'NE_kearney', label: 'NE - Kearney', state: 'US-NE' },
  { value: 'NE_lincoln', label: 'NE - Lincoln', state: 'US-NE' },
  { value: 'NE_mccook', label: 'NE - McCook', state: 'US-NE' },
  { value: 'NE_minden', label: 'NE - Minden', state: 'US-NE' },
  { value: 'NE_nebraska_city', label: 'NE - Nebraska City', state: 'US-NE' },
  { value: 'NE_norfolk', label: 'NE - Norfolk', state: 'US-NE' },
  { value: 'NE_north_platte', label: 'NE - North Platte', state: 'US-NE' },
  { value: 'NE_omaha', label: 'NE - Omaha', state: 'US-NE' },
  { value: 'NE_plattsmouth', label: 'NE - Plattsmouth', state: 'US-NE' },
  { value: 'NE_red_cloud', label: 'NE - Red Cloud', state: 'US-NE' },
  { value: 'NE_sidney', label: 'NE - Sidney', state: 'US-NE' },

  { value: 'NV_boulder_city', label: 'NV - Boulder City', state: 'US-NV' },
  { value: 'NV_carson_city', label: 'NV - Carson City', state: 'US-NV' },
  { value: 'NV_elko', label: 'NV - Elko', state: 'US-NV' },
  { value: 'NV_ely', label: 'NV - Ely', state: 'US-NV' },
  { value: 'NV_fallon', label: 'NV - Fallon', state: 'US-NV' },
  { value: 'NV_genoa', label: 'NV - Genoa', state: 'US-NV' },
  { value: 'NV_goldfield', label: 'NV - Goldfield', state: 'US-NV' },
  { value: 'NV_henderson', label: 'NV - Henderson', state: 'US-NV' },
  { value: 'NV_las_vegas', label: 'NV - Las Vegas', state: 'US-NV' },
  { value: 'NV_north_las_vegas', label: 'NV - North Las Vegas', state: 'US-NV' },
  { value: 'NV_reno', label: 'NV - Reno', state: 'US-NV' },
  { value: 'NV_sparks', label: 'NV - Sparks', state: 'US-NV' },
  { value: 'NV_virginia_city', label: 'NV - Virginia City', state: 'US-NV' },
  { value: 'NV_winnemucca', label: 'NV - Winnemucca', state: 'US-NV' },

  { value: 'NH_berlin', label: 'NH - Berlin', state: 'US-NH' },
  { value: 'NH_claremont', label: 'NH - Claremont', state: 'US-NH' },
  { value: 'NH_concord', label: 'NH - Concord', state: 'US-NH' },
  { value: 'NH_derry', label: 'NH - Derry', state: 'US-NH' },
  { value: 'NH_dover', label: 'NH - Dover', state: 'US-NH' },
  { value: 'NH_durham', label: 'NH - Durham', state: 'US-NH' },
  { value: 'NH_exeter', label: 'NH - Exeter', state: 'US-NH' },
  { value: 'NH_franklin', label: 'NH - Franklin', state: 'US-NH' },
  { value: 'NH_hanover', label: 'NH - Hanover', state: 'US-NH' },
  { value: 'NH_hillsborough', label: 'NH - Hillsborough', state: 'US-NH' },
  { value: 'NH_keene', label: 'NH - Keene', state: 'US-NH' },
  { value: 'NH_laconia', label: 'NH - Laconia', state: 'US-NH' },
  { value: 'NH_lebanon', label: 'NH - Lebanon', state: 'US-NH' },
  { value: 'NH_manchester', label: 'NH - Manchester', state: 'US-NH' },
  { value: 'NH_nashua', label: 'NH - Nashua', state: 'US-NH' },
  { value: 'NH_peterborough', label: 'NH - Peterborough', state: 'US-NH' },
  { value: 'NH_plymouth', label: 'NH - Plymouth', state: 'US-NH' },
  { value: 'NH_portsmouth', label: 'NH - Portsmouth', state: 'US-NH' },
  { value: 'NH_rochester', label: 'NH - Rochester', state: 'US-NH' },
  { value: 'NH_salem', label: 'NH - Salem', state: 'US-NH' },
  { value: 'NH_somersworth', label: 'NH - Somersworth', state: 'US-NH' },

  { value: 'NJ_asbury_park', label: 'NJ - Asbury Park', state: 'US-NJ' },
  { value: 'NJ_atlantic_city', label: 'NJ - Atlantic City', state: 'US-NJ' },
  { value: 'NJ_bayonne', label: 'NJ - Bayonne', state: 'US-NJ' },
  { value: 'NJ_bloomfield', label: 'NJ - Bloomfield', state: 'US-NJ' },
  { value: 'NJ_bordentown', label: 'NJ - Bordentown', state: 'US-NJ' },
  { value: 'NJ_bound_brook', label: 'NJ - Bound Brook', state: 'US-NJ' },
  { value: 'NJ_bridgeton', label: 'NJ - Bridgeton', state: 'US-NJ' },
  { value: 'NJ_burlington', label: 'NJ - Burlington', state: 'US-NJ' },
  { value: 'NJ_caldwell', label: 'NJ - Caldwell', state: 'US-NJ' },
  { value: 'NJ_camden', label: 'NJ - Camden', state: 'US-NJ' },
  { value: 'NJ_cape_may', label: 'NJ - Cape May', state: 'US-NJ' },
  { value: 'NJ_clifton', label: 'NJ - Clifton', state: 'US-NJ' },
  { value: 'NJ_cranford', label: 'NJ - Cranford', state: 'US-NJ' },
  { value: 'NJ_east_orange', label: 'NJ - East Orange', state: 'US-NJ' },
  { value: 'NJ_edison', label: 'NJ - Edison', state: 'US-NJ' },
  { value: 'NJ_elizabeth', label: 'NJ - Elizabeth', state: 'US-NJ' },
  { value: 'NJ_englewood', label: 'NJ - Englewood', state: 'US-NJ' },
  { value: 'NJ_fort_lee', label: 'NJ - Fort Lee', state: 'US-NJ' },
  { value: 'NJ_glassboro', label: 'NJ - Glassboro', state: 'US-NJ' },
  { value: 'NJ_hackensack', label: 'NJ - Hackensack', state: 'US-NJ' },
  { value: 'NJ_haddonfield', label: 'NJ - Haddonfield', state: 'US-NJ' },
  { value: 'NJ_hoboken', label: 'NJ - Hoboken', state: 'US-NJ' },
  { value: 'NJ_irvington', label: 'NJ - Irvington', state: 'US-NJ' },
  { value: 'NJ_jersey_city', label: 'NJ - Jersey City', state: 'US-NJ' },
  { value: 'NJ_lakehurst', label: 'NJ - Lakehurst', state: 'US-NJ' },
  { value: 'NJ_lakewood', label: 'NJ - Lakewood', state: 'US-NJ' },
  { value: 'NJ_long_beach', label: 'NJ - Long Beach', state: 'US-NJ' },
  { value: 'NJ_long_branch', label: 'NJ - Long Branch', state: 'US-NJ' },
  { value: 'NJ_madison', label: 'NJ - Madison', state: 'US-NJ' },
  { value: 'NJ_menlo_park', label: 'NJ - Menlo Park', state: 'US-NJ' },
  { value: 'NJ_millburn', label: 'NJ - Millburn', state: 'US-NJ' },
  { value: 'NJ_millville', label: 'NJ - Millville', state: 'US-NJ' },
  { value: 'NJ_montclair', label: 'NJ - Montclair', state: 'US-NJ' },
  { value: 'NJ_morristown', label: 'NJ - Morristown', state: 'US-NJ' },
  { value: 'NJ_mount_holly', label: 'NJ - Mount Holly', state: 'US-NJ' },
  { value: 'NJ_new_brunswick', label: 'NJ - New Brunswick', state: 'US-NJ' },
  { value: 'NJ_new_milford', label: 'NJ - New Milford', state: 'US-NJ' },
  { value: 'NJ_newark', label: 'NJ - Newark', state: 'US-NJ' },
  { value: 'NJ_ocean_city', label: 'NJ - Ocean City', state: 'US-NJ' },
  { value: 'NJ_orange', label: 'NJ - Orange', state: 'US-NJ' },
  { value: 'NJ_parsippany–troy_hills', label: 'NJ - Parsippany–Troy Hills', state: 'US-NJ' },
  { value: 'NJ_passaic', label: 'NJ - Passaic', state: 'US-NJ' },
  { value: 'NJ_paterson', label: 'NJ - Paterson', state: 'US-NJ' },
  { value: 'NJ_perth_amboy', label: 'NJ - Perth Amboy', state: 'US-NJ' },
  { value: 'NJ_plainfield', label: 'NJ - Plainfield', state: 'US-NJ' },
  { value: 'NJ_princeton', label: 'NJ - Princeton', state: 'US-NJ' },
  { value: 'NJ_ridgewood', label: 'NJ - Ridgewood', state: 'US-NJ' },
  { value: 'NJ_roselle', label: 'NJ - Roselle', state: 'US-NJ' },
  { value: 'NJ_rutherford', label: 'NJ - Rutherford', state: 'US-NJ' },
  { value: 'NJ_salem', label: 'NJ - Salem', state: 'US-NJ' },
  { value: 'NJ_somerville', label: 'NJ - Somerville', state: 'US-NJ' },
  { value: 'NJ_south_orange_village', label: 'NJ - South Orange Village', state: 'US-NJ' },
  { value: 'NJ_totowa', label: 'NJ - Totowa', state: 'US-NJ' },
  { value: 'NJ_trenton', label: 'NJ - Trenton', state: 'US-NJ' },
  { value: 'NJ_union', label: 'NJ - Union', state: 'US-NJ' },
  { value: 'NJ_union_city', label: 'NJ - Union City', state: 'US-NJ' },
  { value: 'NJ_vineland', label: 'NJ - Vineland', state: 'US-NJ' },
  { value: 'NJ_wayne', label: 'NJ - Wayne', state: 'US-NJ' },
  { value: 'NJ_weehawken', label: 'NJ - Weehawken', state: 'US-NJ' },
  { value: 'NJ_west_new_york', label: 'NJ - West New York', state: 'US-NJ' },
  { value: 'NJ_west_orange', label: 'NJ - West Orange', state: 'US-NJ' },
  { value: 'NJ_willingboro', label: 'NJ - Willingboro', state: 'US-NJ' },
  { value: 'NJ_woodbridge', label: 'NJ - Woodbridge', state: 'US-NJ' },

  { value: 'NM_acoma', label: 'NM - Acoma', state: 'US-NM' },
  { value: 'NM_alamogordo', label: 'NM - Alamogordo', state: 'US-NM' },
  { value: 'NM_albuquerque', label: 'NM - Albuquerque', state: 'US-NM' },
  { value: 'NM_artesia', label: 'NM - Artesia', state: 'US-NM' },
  { value: 'NM_belen', label: 'NM - Belen', state: 'US-NM' },
  { value: 'NM_carlsbad', label: 'NM - Carlsbad', state: 'US-NM' },
  { value: 'NM_clovis', label: 'NM - Clovis', state: 'US-NM' },
  { value: 'NM_deming', label: 'NM - Deming', state: 'US-NM' },
  { value: 'NM_farmington', label: 'NM - Farmington', state: 'US-NM' },
  { value: 'NM_gallup', label: 'NM - Gallup', state: 'US-NM' },
  { value: 'NM_grants', label: 'NM - Grants', state: 'US-NM' },
  { value: 'NM_hobbs', label: 'NM - Hobbs', state: 'US-NM' },
  { value: 'NM_las_cruces', label: 'NM - Las Cruces', state: 'US-NM' },
  { value: 'NM_las_vegas', label: 'NM - Las Vegas', state: 'US-NM' },
  { value: 'NM_los_alamos', label: 'NM - Los Alamos', state: 'US-NM' },
  { value: 'NM_lovington', label: 'NM - Lovington', state: 'US-NM' },
  { value: 'NM_portales', label: 'NM - Portales', state: 'US-NM' },
  { value: 'NM_raton', label: 'NM - Raton', state: 'US-NM' },
  { value: 'NM_roswell', label: 'NM - Roswell', state: 'US-NM' },
  { value: 'NM_santa_fe', label: 'NM - Santa Fe', state: 'US-NM' },
  { value: 'NM_shiprock', label: 'NM - Shiprock', state: 'US-NM' },
  { value: 'NM_silver_city', label: 'NM - Silver City', state: 'US-NM' },
  { value: 'NM_socorro', label: 'NM - Socorro', state: 'US-NM' },
  { value: 'NM_taos', label: 'NM - Taos', state: 'US-NM' },
  { value: 'NM_truth_or_consequences', label: 'NM - Truth or Consequences', state: 'US-NM' },
  { value: 'NM_tucumcari', label: 'NM - Tucumcari', state: 'US-NM' },

  { value: 'NY_albany', label: 'NY - Albany', state: 'US-NY' },
  { value: 'NY_amsterdam', label: 'NY - Amsterdam', state: 'US-NY' },
  { value: 'NY_auburn', label: 'NY - Auburn', state: 'US-NY' },
  { value: 'NY_babylon', label: 'NY - Babylon', state: 'US-NY' },
  { value: 'NY_batavia', label: 'NY - Batavia', state: 'US-NY' },
  { value: 'NY_beacon', label: 'NY - Beacon', state: 'US-NY' },
  { value: 'NY_bedford', label: 'NY - Bedford', state: 'US-NY' },
  { value: 'NY_binghamton', label: 'NY - Binghamton', state: 'US-NY' },
  { value: 'NY_bronx', label: 'NY - Bronx', state: 'US-NY' },
  { value: 'NY_brooklyn', label: 'NY - Brooklyn', state: 'US-NY' },
  { value: 'NY_buffalo', label: 'NY - Buffalo', state: 'US-NY' },
  { value: 'NY_chautauqua', label: 'NY - Chautauqua', state: 'US-NY' },
  { value: 'NY_cheektowaga', label: 'NY - Cheektowaga', state: 'US-NY' },
  { value: 'NY_clinton', label: 'NY - Clinton', state: 'US-NY' },
  { value: 'NY_cohoes', label: 'NY - Cohoes', state: 'US-NY' },
  { value: 'NY_coney_island', label: 'NY - Coney Island', state: 'US-NY' },
  { value: 'NY_cooperstown', label: 'NY - Cooperstown', state: 'US-NY' },
  { value: 'NY_corning', label: 'NY - Corning', state: 'US-NY' },
  { value: 'NY_cortland', label: 'NY - Cortland', state: 'US-NY' },
  { value: 'NY_crown_point', label: 'NY - Crown Point', state: 'US-NY' },
  { value: 'NY_dunkirk', label: 'NY - Dunkirk', state: 'US-NY' },
  { value: 'NY_east_aurora', label: 'NY - East Aurora', state: 'US-NY' },
  { value: 'NY_east_hampton', label: 'NY - East Hampton', state: 'US-NY' },
  { value: 'NY_eastchester', label: 'NY - Eastchester', state: 'US-NY' },
  { value: 'NY_elmira', label: 'NY - Elmira', state: 'US-NY' },
  { value: 'NY_flushing', label: 'NY - Flushing', state: 'US-NY' },
  { value: 'NY_forest_hills', label: 'NY - Forest Hills', state: 'US-NY' },
  { value: 'NY_fredonia', label: 'NY - Fredonia', state: 'US-NY' },
  { value: 'NY_garden_city', label: 'NY - Garden City', state: 'US-NY' },
  { value: 'NY_geneva', label: 'NY - Geneva', state: 'US-NY' },
  { value: 'NY_glens_falls', label: 'NY - Glens Falls', state: 'US-NY' },
  { value: 'NY_gloversville', label: 'NY - Gloversville', state: 'US-NY' },
  { value: 'NY_great_neck', label: 'NY - Great Neck', state: 'US-NY' },
  { value: 'NY_hammondsport', label: 'NY - Hammondsport', state: 'US-NY' },
  { value: 'NY_harlem', label: 'NY - Harlem', state: 'US-NY' },
  { value: 'NY_hempstead', label: 'NY - Hempstead', state: 'US-NY' },
  { value: 'NY_herkimer', label: 'NY - Herkimer', state: 'US-NY' },
  { value: 'NY_hudson', label: 'NY - Hudson', state: 'US-NY' },
  { value: 'NY_huntington', label: 'NY - Huntington', state: 'US-NY' },
  { value: 'NY_hyde_park', label: 'NY - Hyde Park', state: 'US-NY' },
  { value: 'NY_ilion', label: 'NY - Ilion', state: 'US-NY' },
  { value: 'NY_ithaca', label: 'NY - Ithaca', state: 'US-NY' },
  { value: 'NY_jamestown', label: 'NY - Jamestown', state: 'US-NY' },
  { value: 'NY_johnstown', label: 'NY - Johnstown', state: 'US-NY' },
  { value: 'NY_kingston', label: 'NY - Kingston', state: 'US-NY' },
  { value: 'NY_lackawanna', label: 'NY - Lackawanna', state: 'US-NY' },
  { value: 'NY_lake_placid', label: 'NY - Lake Placid', state: 'US-NY' },
  { value: 'NY_levittown', label: 'NY - Levittown', state: 'US-NY' },
  { value: 'NY_lockport', label: 'NY - Lockport', state: 'US-NY' },
  { value: 'NY_mamaroneck', label: 'NY - Mamaroneck', state: 'US-NY' },
  { value: 'NY_manhattan', label: 'NY - Manhattan', state: 'US-NY' },
  { value: 'NY_massena', label: 'NY - Massena', state: 'US-NY' },
  { value: 'NY_middletown', label: 'NY - Middletown', state: 'US-NY' },
  { value: 'NY_mineola', label: 'NY - Mineola', state: 'US-NY' },
  { value: 'NY_mount_vernon', label: 'NY - Mount Vernon', state: 'US-NY' },
  { value: 'NY_new_paltz', label: 'NY - New Paltz', state: 'US-NY' },
  { value: 'NY_new_rochelle', label: 'NY - New Rochelle', state: 'US-NY' },
  { value: 'NY_new_windsor', label: 'NY - New Windsor', state: 'US-NY' },
  { value: 'NY_new_york_city', label: 'NY - New York City', state: 'US-NY' },
  { value: 'NY_newburgh', label: 'NY - Newburgh', state: 'US-NY' },
  { value: 'NY_niagara_falls', label: 'NY - Niagara Falls', state: 'US-NY' },
  { value: 'NY_north_hempstead', label: 'NY - North Hempstead', state: 'US-NY' },
  { value: 'NY_nyack', label: 'NY - Nyack', state: 'US-NY' },
  { value: 'NY_ogdensburg', label: 'NY - Ogdensburg', state: 'US-NY' },
  { value: 'NY_olean', label: 'NY - Olean', state: 'US-NY' },
  { value: 'NY_oneida', label: 'NY - Oneida', state: 'US-NY' },
  { value: 'NY_oneonta', label: 'NY - Oneonta', state: 'US-NY' },
  { value: 'NY_ossining', label: 'NY - Ossining', state: 'US-NY' },
  { value: 'NY_oswego', label: 'NY - Oswego', state: 'US-NY' },
  { value: 'NY_oyster_bay', label: 'NY - Oyster Bay', state: 'US-NY' },
  { value: 'NY_palmyra', label: 'NY - Palmyra', state: 'US-NY' },
  { value: 'NY_peekskill', label: 'NY - Peekskill', state: 'US-NY' },
  { value: 'NY_plattsburgh', label: 'NY - Plattsburgh', state: 'US-NY' },
  { value: 'NY_port_washington', label: 'NY - Port Washington', state: 'US-NY' },
  { value: 'NY_potsdam', label: 'NY - Potsdam', state: 'US-NY' },
  { value: 'NY_poughkeepsie', label: 'NY - Poughkeepsie', state: 'US-NY' },
  { value: 'NY_queens', label: 'NY - Queens', state: 'US-NY' },
  { value: 'NY_rensselaer', label: 'NY - Rensselaer', state: 'US-NY' },
  { value: 'NY_rochester', label: 'NY - Rochester', state: 'US-NY' },
  { value: 'NY_rome', label: 'NY - Rome', state: 'US-NY' },
  { value: 'NY_rotterdam', label: 'NY - Rotterdam', state: 'US-NY' },
  { value: 'NY_rye', label: 'NY - Rye', state: 'US-NY' },
  { value: 'NY_sag_harbor', label: 'NY - Sag Harbor', state: 'US-NY' },
  { value: 'NY_saranac_lake', label: 'NY - Saranac Lake', state: 'US-NY' },
  { value: 'NY_saratoga_springs', label: 'NY - Saratoga Springs', state: 'US-NY' },
  { value: 'NY_scarsdale', label: 'NY - Scarsdale', state: 'US-NY' },
  { value: 'NY_schenectady', label: 'NY - Schenectady', state: 'US-NY' },
  { value: 'NY_seneca_falls', label: 'NY - Seneca Falls', state: 'US-NY' },
  { value: 'NY_southampton', label: 'NY - Southampton', state: 'US-NY' },
  { value: 'NY_staten_island', label: 'NY - Staten Island', state: 'US-NY' },
  { value: 'NY_stony_brook', label: 'NY - Stony Brook', state: 'US-NY' },
  { value: 'NY_stony_point', label: 'NY - Stony Point', state: 'US-NY' },
  { value: 'NY_syracuse', label: 'NY - Syracuse', state: 'US-NY' },
  { value: 'NY_tarrytown', label: 'NY - Tarrytown', state: 'US-NY' },
  { value: 'NY_ticonderoga', label: 'NY - Ticonderoga', state: 'US-NY' },
  { value: 'NY_tonawanda', label: 'NY - Tonawanda', state: 'US-NY' },
  { value: 'NY_troy', label: 'NY - Troy', state: 'US-NY' },
  { value: 'NY_utica', label: 'NY - Utica', state: 'US-NY' },
  { value: 'NY_watertown', label: 'NY - Watertown', state: 'US-NY' },
  { value: 'NY_watervliet', label: 'NY - Watervliet', state: 'US-NY' },
  { value: 'NY_watkins_glen', label: 'NY - Watkins Glen', state: 'US-NY' },
  { value: 'NY_west_seneca', label: 'NY - West Seneca', state: 'US-NY' },
  { value: 'NY_white_plains', label: 'NY - White Plains', state: 'US-NY' },
  { value: 'NY_woodstock', label: 'NY - Woodstock', state: 'US-NY' },
  { value: 'NY_yonkers', label: 'NY - Yonkers', state: 'US-NY' },

  { value: 'NC_asheboro', label: 'NC - Asheboro', state: 'US-NC' },
  { value: 'NC_asheville', label: 'NC - Asheville', state: 'US-NC' },
  { value: 'NC_bath', label: 'NC - Bath', state: 'US-NC' },
  { value: 'NC_beaufort', label: 'NC - Beaufort', state: 'US-NC' },
  { value: 'NC_boone', label: 'NC - Boone', state: 'US-NC' },
  { value: 'NC_burlington', label: 'NC - Burlington', state: 'US-NC' },
  { value: 'NC_chapel_hill', label: 'NC - Chapel Hill', state: 'US-NC' },
  { value: 'NC_charlotte', label: 'NC - Charlotte', state: 'US-NC' },
  { value: 'NC_concord', label: 'NC - Concord', state: 'US-NC' },
  { value: 'NC_durham', label: 'NC - Durham', state: 'US-NC' },
  { value: 'NC_edenton', label: 'NC - Edenton', state: 'US-NC' },
  { value: 'NC_elizabeth_city', label: 'NC - Elizabeth City', state: 'US-NC' },
  { value: 'NC_fayetteville', label: 'NC - Fayetteville', state: 'US-NC' },
  { value: 'NC_gastonia', label: 'NC - Gastonia', state: 'US-NC' },
  { value: 'NC_goldsboro', label: 'NC - Goldsboro', state: 'US-NC' },
  { value: 'NC_greensboro', label: 'NC - Greensboro', state: 'US-NC' },
  { value: 'NC_greenville', label: 'NC - Greenville', state: 'US-NC' },
  { value: 'NC_halifax', label: 'NC - Halifax', state: 'US-NC' },
  { value: 'NC_henderson', label: 'NC - Henderson', state: 'US-NC' },
  { value: 'NC_hickory', label: 'NC - Hickory', state: 'US-NC' },
  { value: 'NC_high_point', label: 'NC - High Point', state: 'US-NC' },
  { value: 'NC_hillsborough', label: 'NC - Hillsborough', state: 'US-NC' },
  { value: 'NC_jacksonville', label: 'NC - Jacksonville', state: 'US-NC' },
  { value: 'NC_kinston', label: 'NC - Kinston', state: 'US-NC' },
  { value: 'NC_kitty_hawk', label: 'NC - Kitty Hawk', state: 'US-NC' },
  { value: 'NC_lumberton', label: 'NC - Lumberton', state: 'US-NC' },
  { value: 'NC_morehead_city', label: 'NC - Morehead City', state: 'US-NC' },
  { value: 'NC_morganton', label: 'NC - Morganton', state: 'US-NC' },
  { value: 'NC_nags_head', label: 'NC - Nags Head', state: 'US-NC' },
  { value: 'NC_new_bern', label: 'NC - New Bern', state: 'US-NC' },
  { value: 'NC_pinehurst', label: 'NC - Pinehurst', state: 'US-NC' },
  { value: 'NC_raleigh', label: 'NC - Raleigh', state: 'US-NC' },
  { value: 'NC_rocky_mount', label: 'NC - Rocky Mount', state: 'US-NC' },
  { value: 'NC_salisbury', label: 'NC - Salisbury', state: 'US-NC' },
  { value: 'NC_shelby', label: 'NC - Shelby', state: 'US-NC' },
  { value: 'NC_washington', label: 'NC - Washington', state: 'US-NC' },
  { value: 'NC_wilmington', label: 'NC - Wilmington', state: 'US-NC' },
  { value: 'NC_wilson', label: 'NC - Wilson', state: 'US-NC' },
  { value: 'NC_winston-salem', label: 'NC - Winston-Salem', state: 'US-NC' },

  { value: 'ND_bismarck', label: 'ND - Bismarck', state: 'US-ND' },
  { value: 'ND_devils_lake', label: 'ND - Devils Lake', state: 'US-ND' },
  { value: 'ND_dickinson', label: 'ND - Dickinson', state: 'US-ND' },
  { value: 'ND_fargo', label: 'ND - Fargo', state: 'US-ND' },
  { value: 'ND_grand_forks', label: 'ND - Grand Forks', state: 'US-ND' },
  { value: 'ND_jamestown', label: 'ND - Jamestown', state: 'US-ND' },
  { value: 'ND_mandan', label: 'ND - Mandan', state: 'US-ND' },
  { value: 'ND_minot', label: 'ND - Minot', state: 'US-ND' },
  { value: 'ND_rugby', label: 'ND - Rugby', state: 'US-ND' },
  { value: 'ND_valley_city', label: 'ND - Valley City', state: 'US-ND' },
  { value: 'ND_wahpeton', label: 'ND - Wahpeton', state: 'US-ND' },
  { value: 'ND_williston', label: 'ND - Williston', state: 'US-ND' },

  { value: 'OH_akron', label: 'OH - Akron', state: 'US-OH' },
  { value: 'OH_alliance', label: 'OH - Alliance', state: 'US-OH' },
  { value: 'OH_ashtabula', label: 'OH - Ashtabula', state: 'US-OH' },
  { value: 'OH_athens', label: 'OH - Athens', state: 'US-OH' },
  { value: 'OH_barberton', label: 'OH - Barberton', state: 'US-OH' },
  { value: 'OH_bedford', label: 'OH - Bedford', state: 'US-OH' },
  { value: 'OH_bellefontaine', label: 'OH - Bellefontaine', state: 'US-OH' },
  { value: 'OH_bowling_green', label: 'OH - Bowling Green', state: 'US-OH' },
  { value: 'OH_canton', label: 'OH - Canton', state: 'US-OH' },
  { value: 'OH_chillicothe', label: 'OH - Chillicothe', state: 'US-OH' },
  { value: 'OH_cincinnati', label: 'OH - Cincinnati', state: 'US-OH' },
  { value: 'OH_cleveland', label: 'OH - Cleveland', state: 'US-OH' },
  { value: 'OH_cleveland_heights', label: 'OH - Cleveland Heights', state: 'US-OH' },
  { value: 'OH_columbus', label: 'OH - Columbus', state: 'US-OH' },
  { value: 'OH_conneaut', label: 'OH - Conneaut', state: 'US-OH' },
  { value: 'OH_cuyahoga_falls', label: 'OH - Cuyahoga Falls', state: 'US-OH' },
  { value: 'OH_dayton', label: 'OH - Dayton', state: 'US-OH' },
  { value: 'OH_defiance', label: 'OH - Defiance', state: 'US-OH' },
  { value: 'OH_delaware', label: 'OH - Delaware', state: 'US-OH' },
  { value: 'OH_east_cleveland', label: 'OH - East Cleveland', state: 'US-OH' },
  { value: 'OH_east_liverpool', label: 'OH - East Liverpool', state: 'US-OH' },
  { value: 'OH_elyria', label: 'OH - Elyria', state: 'US-OH' },
  { value: 'OH_euclid', label: 'OH - Euclid', state: 'US-OH' },
  { value: 'OH_findlay', label: 'OH - Findlay', state: 'US-OH' },
  { value: 'OH_gallipolis', label: 'OH - Gallipolis', state: 'US-OH' },
  { value: 'OH_greenville', label: 'OH - Greenville', state: 'US-OH' },
  { value: 'OH_hamilton', label: 'OH - Hamilton', state: 'US-OH' },
  { value: 'OH_kent', label: 'OH - Kent', state: 'US-OH' },
  { value: 'OH_kettering', label: 'OH - Kettering', state: 'US-OH' },
  { value: 'OH_lakewood', label: 'OH - Lakewood', state: 'US-OH' },
  { value: 'OH_lancaster', label: 'OH - Lancaster', state: 'US-OH' },
  { value: 'OH_lima', label: 'OH - Lima', state: 'US-OH' },
  { value: 'OH_lorain', label: 'OH - Lorain', state: 'US-OH' },
  { value: 'OH_mansfield', label: 'OH - Mansfield', state: 'US-OH' },
  { value: 'OH_marietta', label: 'OH - Marietta', state: 'US-OH' },
  { value: 'OH_marion', label: 'OH - Marion', state: 'US-OH' },
  { value: 'OH_martins_ferry', label: 'OH - Martins Ferry', state: 'US-OH' },
  { value: 'OH_massillon', label: 'OH - Massillon', state: 'US-OH' },
  { value: 'OH_mentor', label: 'OH - Mentor', state: 'US-OH' },
  { value: 'OH_middletown', label: 'OH - Middletown', state: 'US-OH' },
  { value: 'OH_milan', label: 'OH - Milan', state: 'US-OH' },
  { value: 'OH_mount_vernon', label: 'OH - Mount Vernon', state: 'US-OH' },
  { value: 'OH_new_philadelphia', label: 'OH - New Philadelphia', state: 'US-OH' },
  { value: 'OH_newark', label: 'OH - Newark', state: 'US-OH' },
  { value: 'OH_niles', label: 'OH - Niles', state: 'US-OH' },
  { value: 'OH_north_college_hill', label: 'OH - North College Hill', state: 'US-OH' },
  { value: 'OH_norwalk', label: 'OH - Norwalk', state: 'US-OH' },
  { value: 'OH_oberlin', label: 'OH - Oberlin', state: 'US-OH' },
  { value: 'OH_painesville', label: 'OH - Painesville', state: 'US-OH' },
  { value: 'OH_parma', label: 'OH - Parma', state: 'US-OH' },
  { value: 'OH_piqua', label: 'OH - Piqua', state: 'US-OH' },
  { value: 'OH_portsmouth', label: 'OH - Portsmouth', state: 'US-OH' },
  { value: 'OH_put-in-bay', label: 'OH - Put-in-Bay', state: 'US-OH' },
  { value: 'OH_salem', label: 'OH - Salem', state: 'US-OH' },
  { value: 'OH_sandusky', label: 'OH - Sandusky', state: 'US-OH' },
  { value: 'OH_shaker_heights', label: 'OH - Shaker Heights', state: 'US-OH' },
  { value: 'OH_springfield', label: 'OH - Springfield', state: 'US-OH' },
  { value: 'OH_steubenville', label: 'OH - Steubenville', state: 'US-OH' },
  { value: 'OH_tiffin', label: 'OH - Tiffin', state: 'US-OH' },
  { value: 'OH_toledo', label: 'OH - Toledo', state: 'US-OH' },
  { value: 'OH_urbana', label: 'OH - Urbana', state: 'US-OH' },
  { value: 'OH_warren', label: 'OH - Warren', state: 'US-OH' },
  { value: 'OH_wooster', label: 'OH - Wooster', state: 'US-OH' },
  { value: 'OH_worthington', label: 'OH - Worthington', state: 'US-OH' },
  { value: 'OH_xenia', label: 'OH - Xenia', state: 'US-OH' },
  { value: 'OH_yellow_springs', label: 'OH - Yellow Springs', state: 'US-OH' },
  { value: 'OH_youngstown', label: 'OH - Youngstown', state: 'US-OH' },
  { value: 'OH_zanesville', label: 'OH - Zanesville', state: 'US-OH' },

  { value: 'OK_ada', label: 'OK - Ada', state: 'US-OK' },
  { value: 'OK_altus', label: 'OK - Altus', state: 'US-OK' },
  { value: 'OK_alva', label: 'OK - Alva', state: 'US-OK' },
  { value: 'OK_anadarko', label: 'OK - Anadarko', state: 'US-OK' },
  { value: 'OK_ardmore', label: 'OK - Ardmore', state: 'US-OK' },
  { value: 'OK_bartlesville', label: 'OK - Bartlesville', state: 'US-OK' },
  { value: 'OK_bethany', label: 'OK - Bethany', state: 'US-OK' },
  { value: 'OK_chickasha', label: 'OK - Chickasha', state: 'US-OK' },
  { value: 'OK_claremore', label: 'OK - Claremore', state: 'US-OK' },
  { value: 'OK_clinton', label: 'OK - Clinton', state: 'US-OK' },
  { value: 'OK_cushing', label: 'OK - Cushing', state: 'US-OK' },
  { value: 'OK_duncan', label: 'OK - Duncan', state: 'US-OK' },
  { value: 'OK_durant', label: 'OK - Durant', state: 'US-OK' },
  { value: 'OK_edmond', label: 'OK - Edmond', state: 'US-OK' },
  { value: 'OK_el_reno', label: 'OK - El Reno', state: 'US-OK' },
  { value: 'OK_elk_city', label: 'OK - Elk City', state: 'US-OK' },
  { value: 'OK_enid', label: 'OK - Enid', state: 'US-OK' },
  { value: 'OK_eufaula', label: 'OK - Eufaula', state: 'US-OK' },
  { value: 'OK_frederick', label: 'OK - Frederick', state: 'US-OK' },
  { value: 'OK_guthrie', label: 'OK - Guthrie', state: 'US-OK' },
  { value: 'OK_guymon', label: 'OK - Guymon', state: 'US-OK' },
  { value: 'OK_hobart', label: 'OK - Hobart', state: 'US-OK' },
  { value: 'OK_holdenville', label: 'OK - Holdenville', state: 'US-OK' },
  { value: 'OK_hugo', label: 'OK - Hugo', state: 'US-OK' },
  { value: 'OK_lawton', label: 'OK - Lawton', state: 'US-OK' },
  { value: 'OK_mcalester', label: 'OK - McAlester', state: 'US-OK' },
  { value: 'OK_miami', label: 'OK - Miami', state: 'US-OK' },
  { value: 'OK_midwest_city', label: 'OK - Midwest City', state: 'US-OK' },
  { value: 'OK_moore', label: 'OK - Moore', state: 'US-OK' },
  { value: 'OK_muskogee', label: 'OK - Muskogee', state: 'US-OK' },
  { value: 'OK_norman', label: 'OK - Norman', state: 'US-OK' },
  { value: 'OK_oklahoma_city', label: 'OK - Oklahoma City', state: 'US-OK' },
  { value: 'OK_okmulgee', label: 'OK - Okmulgee', state: 'US-OK' },
  { value: 'OK_pauls_valley', label: 'OK - Pauls Valley', state: 'US-OK' },
  { value: 'OK_pawhuska', label: 'OK - Pawhuska', state: 'US-OK' },
  { value: 'OK_perry', label: 'OK - Perry', state: 'US-OK' },
  { value: 'OK_ponca_city', label: 'OK - Ponca City', state: 'US-OK' },
  { value: 'OK_pryor', label: 'OK - Pryor', state: 'US-OK' },
  { value: 'OK_sallisaw', label: 'OK - Sallisaw', state: 'US-OK' },
  { value: 'OK_sand_springs', label: 'OK - Sand Springs', state: 'US-OK' },
  { value: 'OK_sapulpa', label: 'OK - Sapulpa', state: 'US-OK' },
  { value: 'OK_seminole', label: 'OK - Seminole', state: 'US-OK' },
  { value: 'OK_shawnee', label: 'OK - Shawnee', state: 'US-OK' },
  { value: 'OK_stillwater', label: 'OK - Stillwater', state: 'US-OK' },
  { value: 'OK_tahlequah', label: 'OK - Tahlequah', state: 'US-OK' },
  { value: 'OK_the_village', label: 'OK - The Village', state: 'US-OK' },
  { value: 'OK_tulsa', label: 'OK - Tulsa', state: 'US-OK' },
  { value: 'OK_vinita', label: 'OK - Vinita', state: 'US-OK' },
  { value: 'OK_wewoka', label: 'OK - Wewoka', state: 'US-OK' },
  { value: 'OK_woodward', label: 'OK - Woodward', state: 'US-OK' },

  { value: 'OR_albany', label: 'OR - Albany', state: 'US-OR' },
  { value: 'OR_ashland', label: 'OR - Ashland', state: 'US-OR' },
  { value: 'OR_astoria', label: 'OR - Astoria', state: 'US-OR' },
  { value: 'OR_baker_city', label: 'OR - Baker City', state: 'US-OR' },
  { value: 'OR_beaverton', label: 'OR - Beaverton', state: 'US-OR' },
  { value: 'OR_bend', label: 'OR - Bend', state: 'US-OR' },
  { value: 'OR_brookings', label: 'OR - Brookings', state: 'US-OR' },
  { value: 'OR_burns', label: 'OR - Burns', state: 'US-OR' },
  { value: 'OR_coos_bay', label: 'OR - Coos Bay', state: 'US-OR' },
  { value: 'OR_corvallis', label: 'OR - Corvallis', state: 'US-OR' },
  { value: 'OR_eugene', label: 'OR - Eugene', state: 'US-OR' },
  { value: 'OR_grants_pass', label: 'OR - Grants Pass', state: 'US-OR' },
  { value: 'OR_hillsboro', label: 'OR - Hillsboro', state: 'US-OR' },
  { value: 'OR_hood_river', label: 'OR - Hood River', state: 'US-OR' },
  { value: 'OR_jacksonville', label: 'OR - Jacksonville', state: 'US-OR' },
  { value: 'OR_john_day', label: 'OR - John Day', state: 'US-OR' },
  { value: 'OR_klamath_falls', label: 'OR - Klamath Falls', state: 'US-OR' },
  { value: 'OR_la_grande', label: 'OR - La Grande', state: 'US-OR' },
  { value: 'OR_lake_oswego', label: 'OR - Lake Oswego', state: 'US-OR' },
  { value: 'OR_lakeview', label: 'OR - Lakeview', state: 'US-OR' },
  { value: 'OR_mcminnville', label: 'OR - McMinnville', state: 'US-OR' },
  { value: 'OR_medford', label: 'OR - Medford', state: 'US-OR' },
  { value: 'OR_newberg', label: 'OR - Newberg', state: 'US-OR' },
  { value: 'OR_newport', label: 'OR - Newport', state: 'US-OR' },
  { value: 'OR_ontario', label: 'OR - Ontario', state: 'US-OR' },
  { value: 'OR_oregon_city', label: 'OR - Oregon City', state: 'US-OR' },
  { value: 'OR_pendleton', label: 'OR - Pendleton', state: 'US-OR' },
  { value: 'OR_port_orford', label: 'OR - Port Orford', state: 'US-OR' },
  { value: 'OR_portland', label: 'OR - Portland', state: 'US-OR' },
  { value: 'OR_prineville', label: 'OR - Prineville', state: 'US-OR' },
  { value: 'OR_redmond', label: 'OR - Redmond', state: 'US-OR' },
  { value: 'OR_reedsport', label: 'OR - Reedsport', state: 'US-OR' },
  { value: 'OR_roseburg', label: 'OR - Roseburg', state: 'US-OR' },
  { value: 'OR_salem', label: 'OR - Salem', state: 'US-OR' },
  { value: 'OR_seaside', label: 'OR - Seaside', state: 'US-OR' },
  { value: 'OR_springfield', label: 'OR - Springfield', state: 'US-OR' },
  { value: 'OR_the_dalles', label: 'OR - The Dalles', state: 'US-OR' },
  { value: 'OR_tillamook', label: 'OR - Tillamook', state: 'US-OR' },

  { value: 'PA_abington', label: 'PA - Abington', state: 'US-PA' },
  { value: 'PA_aliquippa', label: 'PA - Aliquippa', state: 'US-PA' },
  { value: 'PA_allentown', label: 'PA - Allentown', state: 'US-PA' },
  { value: 'PA_altoona', label: 'PA - Altoona', state: 'US-PA' },
  { value: 'PA_ambridge', label: 'PA - Ambridge', state: 'US-PA' },
  { value: 'PA_bedford', label: 'PA - Bedford', state: 'US-PA' },
  { value: 'PA_bethlehem', label: 'PA - Bethlehem', state: 'US-PA' },
  { value: 'PA_bloomsburg', label: 'PA - Bloomsburg', state: 'US-PA' },
  { value: 'PA_bradford', label: 'PA - Bradford', state: 'US-PA' },
  { value: 'PA_bristol', label: 'PA - Bristol', state: 'US-PA' },
  { value: 'PA_carbondale', label: 'PA - Carbondale', state: 'US-PA' },
  { value: 'PA_carlisle', label: 'PA - Carlisle', state: 'US-PA' },
  { value: 'PA_chambersburg', label: 'PA - Chambersburg', state: 'US-PA' },
  { value: 'PA_chester', label: 'PA - Chester', state: 'US-PA' },
  { value: 'PA_columbia', label: 'PA - Columbia', state: 'US-PA' },
  { value: 'PA_easton', label: 'PA - Easton', state: 'US-PA' },
  { value: 'PA_erie', label: 'PA - Erie', state: 'US-PA' },
  { value: 'PA_franklin', label: 'PA - Franklin', state: 'US-PA' },
  { value: 'PA_germantown', label: 'PA - Germantown', state: 'US-PA' },
  { value: 'PA_gettysburg', label: 'PA - Gettysburg', state: 'US-PA' },
  { value: 'PA_greensburg', label: 'PA - Greensburg', state: 'US-PA' },
  { value: 'PA_hanover', label: 'PA - Hanover', state: 'US-PA' },
  { value: 'PA_harmony', label: 'PA - Harmony', state: 'US-PA' },
  { value: 'PA_harrisburg', label: 'PA - Harrisburg', state: 'US-PA' },
  { value: 'PA_hazleton', label: 'PA - Hazleton', state: 'US-PA' },
  { value: 'PA_hershey', label: 'PA - Hershey', state: 'US-PA' },
  { value: 'PA_homestead', label: 'PA - Homestead', state: 'US-PA' },
  { value: 'PA_honesdale', label: 'PA - Honesdale', state: 'US-PA' },
  { value: 'PA_indiana', label: 'PA - Indiana', state: 'US-PA' },
  { value: 'PA_jeannette', label: 'PA - Jeannette', state: 'US-PA' },
  { value: 'PA_jim_thorpe', label: 'PA - Jim Thorpe', state: 'US-PA' },
  { value: 'PA_johnstown', label: 'PA - Johnstown', state: 'US-PA' },
  { value: 'PA_lancaster', label: 'PA - Lancaster', state: 'US-PA' },
  { value: 'PA_lebanon', label: 'PA - Lebanon', state: 'US-PA' },
  { value: 'PA_levittown', label: 'PA - Levittown', state: 'US-PA' },
  { value: 'PA_lewistown', label: 'PA - Lewistown', state: 'US-PA' },
  { value: 'PA_lock_haven', label: 'PA - Lock Haven', state: 'US-PA' },
  { value: 'PA_lower_southampton', label: 'PA - Lower Southampton', state: 'US-PA' },
  { value: 'PA_mckeesport', label: 'PA - McKeesport', state: 'US-PA' },
  { value: 'PA_meadville', label: 'PA - Meadville', state: 'US-PA' },
  { value: 'PA_middletown', label: 'PA - Middletown', state: 'US-PA' },
  { value: 'PA_monroeville', label: 'PA - Monroeville', state: 'US-PA' },
  { value: 'PA_nanticoke', label: 'PA - Nanticoke', state: 'US-PA' },
  { value: 'PA_new_castle', label: 'PA - New Castle', state: 'US-PA' },
  { value: 'PA_new_hope', label: 'PA - New Hope', state: 'US-PA' },
  { value: 'PA_new_kensington', label: 'PA - New Kensington', state: 'US-PA' },
  { value: 'PA_norristown', label: 'PA - Norristown', state: 'US-PA' },
  { value: 'PA_oil_city', label: 'PA - Oil City', state: 'US-PA' },
  { value: 'PA_philadelphia', label: 'PA - Philadelphia', state: 'US-PA' },
  { value: 'PA_phoenixville', label: 'PA - Phoenixville', state: 'US-PA' },
  { value: 'PA_pittsburgh', label: 'PA - Pittsburgh', state: 'US-PA' },
  { value: 'PA_pottstown', label: 'PA - Pottstown', state: 'US-PA' },
  { value: 'PA_pottsville', label: 'PA - Pottsville', state: 'US-PA' },
  { value: 'PA_reading', label: 'PA - Reading', state: 'US-PA' },
  { value: 'PA_scranton', label: 'PA - Scranton', state: 'US-PA' },
  { value: 'PA_shamokin', label: 'PA - Shamokin', state: 'US-PA' },
  { value: 'PA_sharon', label: 'PA - Sharon', state: 'US-PA' },
  { value: 'PA_state_college', label: 'PA - State College', state: 'US-PA' },
  { value: 'PA_stroudsburg', label: 'PA - Stroudsburg', state: 'US-PA' },
  { value: 'PA_sunbury', label: 'PA - Sunbury', state: 'US-PA' },
  { value: 'PA_swarthmore', label: 'PA - Swarthmore', state: 'US-PA' },
  { value: 'PA_tamaqua', label: 'PA - Tamaqua', state: 'US-PA' },
  { value: 'PA_titusville', label: 'PA - Titusville', state: 'US-PA' },
  { value: 'PA_uniontown', label: 'PA - Uniontown', state: 'US-PA' },
  { value: 'PA_warren', label: 'PA - Warren', state: 'US-PA' },
  { value: 'PA_washington', label: 'PA - Washington', state: 'US-PA' },
  { value: 'PA_west_chester', label: 'PA - West Chester', state: 'US-PA' },
  { value: 'PA_wilkes-barre', label: 'PA - Wilkes-Barre', state: 'US-PA' },
  { value: 'PA_williamsport', label: 'PA - Williamsport', state: 'US-PA' },
  { value: 'PA_york', label: 'PA - York', state: 'US-PA' },

  { value: 'RI_barrington', label: 'RI - Barrington', state: 'US-RI' },
  { value: 'RI_bristol', label: 'RI - Bristol', state: 'US-RI' },
  { value: 'RI_central_falls', label: 'RI - Central Falls', state: 'US-RI' },
  { value: 'RI_cranston', label: 'RI - Cranston', state: 'US-RI' },
  { value: 'RI_east_greenwich', label: 'RI - East Greenwich', state: 'US-RI' },
  { value: 'RI_east_providence', label: 'RI - East Providence', state: 'US-RI' },
  { value: 'RI_kingston', label: 'RI - Kingston', state: 'US-RI' },
  { value: 'RI_middletown', label: 'RI - Middletown', state: 'US-RI' },
  { value: 'RI_narragansett', label: 'RI - Narragansett', state: 'US-RI' },
  { value: 'RI_newport', label: 'RI - Newport', state: 'US-RI' },
  { value: 'RI_north_kingstown', label: 'RI - North Kingstown', state: 'US-RI' },
  { value: 'RI_pawtucket', label: 'RI - Pawtucket', state: 'US-RI' },
  { value: 'RI_portsmouth', label: 'RI - Portsmouth', state: 'US-RI' },
  { value: 'RI_providence', label: 'RI - Providence', state: 'US-RI' },
  { value: 'RI_south_kingstown', label: 'RI - South Kingstown', state: 'US-RI' },
  { value: 'RI_tiverton', label: 'RI - Tiverton', state: 'US-RI' },
  { value: 'RI_warren', label: 'RI - Warren', state: 'US-RI' },
  { value: 'RI_warwick', label: 'RI - Warwick', state: 'US-RI' },
  { value: 'RI_westerly', label: 'RI - Westerly', state: 'US-RI' },
  { value: 'RI_wickford', label: 'RI - Wickford', state: 'US-RI' },
  { value: 'RI_woonsocket', label: 'RI - Woonsocket', state: 'US-RI' },

  { value: 'SC_abbeville', label: 'SC - Abbeville', state: 'US-SC' },
  { value: 'SC_aiken', label: 'SC - Aiken', state: 'US-SC' },
  { value: 'SC_anderson', label: 'SC - Anderson', state: 'US-SC' },
  { value: 'SC_beaufort', label: 'SC - Beaufort', state: 'US-SC' },
  { value: 'SC_camden', label: 'SC - Camden', state: 'US-SC' },
  { value: 'SC_charleston', label: 'SC - Charleston', state: 'US-SC' },
  { value: 'SC_columbia', label: 'SC - Columbia', state: 'US-SC' },
  { value: 'SC_darlington', label: 'SC - Darlington', state: 'US-SC' },
  { value: 'SC_florence', label: 'SC - Florence', state: 'US-SC' },
  { value: 'SC_gaffney', label: 'SC - Gaffney', state: 'US-SC' },
  { value: 'SC_georgetown', label: 'SC - Georgetown', state: 'US-SC' },
  { value: 'SC_greenville', label: 'SC - Greenville', state: 'US-SC' },
  { value: 'SC_greenwood', label: 'SC - Greenwood', state: 'US-SC' },
  { value: 'SC_hartsville', label: 'SC - Hartsville', state: 'US-SC' },
  { value: 'SC_lancaster', label: 'SC - Lancaster', state: 'US-SC' },
  { value: 'SC_mount_pleasant', label: 'SC - Mount Pleasant', state: 'US-SC' },
  { value: 'SC_myrtle_beach', label: 'SC - Myrtle Beach', state: 'US-SC' },
  { value: 'SC_orangeburg', label: 'SC - Orangeburg', state: 'US-SC' },
  { value: 'SC_rock_hill', label: 'SC - Rock Hill', state: 'US-SC' },
  { value: 'SC_spartanburg', label: 'SC - Spartanburg', state: 'US-SC' },
  { value: 'SC_sumter', label: 'SC - Sumter', state: 'US-SC' },
  { value: 'SC_union', label: 'SC - Union', state: 'US-SC' },

  { value: 'SD_aberdeen', label: 'SD - Aberdeen', state: 'US-SD' },
  { value: 'SD_belle_fourche', label: 'SD - Belle Fourche', state: 'US-SD' },
  { value: 'SD_brookings', label: 'SD - Brookings', state: 'US-SD' },
  { value: 'SD_canton', label: 'SD - Canton', state: 'US-SD' },
  { value: 'SD_custer', label: 'SD - Custer', state: 'US-SD' },
  { value: 'SD_de_smet', label: 'SD - De Smet', state: 'US-SD' },
  { value: 'SD_deadwood', label: 'SD - Deadwood', state: 'US-SD' },
  { value: 'SD_hot_springs', label: 'SD - Hot Springs', state: 'US-SD' },
  { value: 'SD_huron', label: 'SD - Huron', state: 'US-SD' },
  { value: 'SD_lead', label: 'SD - Lead', state: 'US-SD' },
  { value: 'SD_madison', label: 'SD - Madison', state: 'US-SD' },
  { value: 'SD_milbank', label: 'SD - Milbank', state: 'US-SD' },
  { value: 'SD_mitchell', label: 'SD - Mitchell', state: 'US-SD' },
  { value: 'SD_mobridge', label: 'SD - Mobridge', state: 'US-SD' },
  { value: 'SD_pierre', label: 'SD - Pierre', state: 'US-SD' },
  { value: 'SD_rapid_city', label: 'SD - Rapid City', state: 'US-SD' },
  { value: 'SD_sioux_falls', label: 'SD - Sioux Falls', state: 'US-SD' },
  { value: 'SD_spearfish', label: 'SD - Spearfish', state: 'US-SD' },
  { value: 'SD_sturgis', label: 'SD - Sturgis', state: 'US-SD' },
  { value: 'SD_vermillion', label: 'SD - Vermillion', state: 'US-SD' },
  { value: 'SD_watertown', label: 'SD - Watertown', state: 'US-SD' },
  { value: 'SD_yankton', label: 'SD - Yankton', state: 'US-SD' },

  { value: 'TN_alcoa', label: 'TN - Alcoa', state: 'US-TN' },
  { value: 'TN_athens', label: 'TN - Athens', state: 'US-TN' },
  { value: 'TN_chattanooga', label: 'TN - Chattanooga', state: 'US-TN' },
  { value: 'TN_clarksville', label: 'TN - Clarksville', state: 'US-TN' },
  { value: 'TN_cleveland', label: 'TN - Cleveland', state: 'US-TN' },
  { value: 'TN_columbia', label: 'TN - Columbia', state: 'US-TN' },
  { value: 'TN_cookeville', label: 'TN - Cookeville', state: 'US-TN' },
  { value: 'TN_dayton', label: 'TN - Dayton', state: 'US-TN' },
  { value: 'TN_elizabethton', label: 'TN - Elizabethton', state: 'US-TN' },
  { value: 'TN_franklin', label: 'TN - Franklin', state: 'US-TN' },
  { value: 'TN_gallatin', label: 'TN - Gallatin', state: 'US-TN' },
  { value: 'TN_gatlinburg', label: 'TN - Gatlinburg', state: 'US-TN' },
  { value: 'TN_greeneville', label: 'TN - Greeneville', state: 'US-TN' },
  { value: 'TN_jackson', label: 'TN - Jackson', state: 'US-TN' },
  { value: 'TN_johnson_city', label: 'TN - Johnson City', state: 'US-TN' },
  { value: 'TN_jonesborough', label: 'TN - Jonesborough', state: 'US-TN' },
  { value: 'TN_kingsport', label: 'TN - Kingsport', state: 'US-TN' },
  { value: 'TN_knoxville', label: 'TN - Knoxville', state: 'US-TN' },
  { value: 'TN_lebanon', label: 'TN - Lebanon', state: 'US-TN' },
  { value: 'TN_maryville', label: 'TN - Maryville', state: 'US-TN' },
  { value: 'TN_memphis', label: 'TN - Memphis', state: 'US-TN' },
  { value: 'TN_morristown', label: 'TN - Morristown', state: 'US-TN' },
  { value: 'TN_murfreesboro', label: 'TN - Murfreesboro', state: 'US-TN' },
  { value: 'TN_nashville', label: 'TN - Nashville', state: 'US-TN' },
  { value: 'TN_norris', label: 'TN - Norris', state: 'US-TN' },
  { value: 'TN_oak_ridge', label: 'TN - Oak Ridge', state: 'US-TN' },
  { value: 'TN_shelbyville', label: 'TN - Shelbyville', state: 'US-TN' },
  { value: 'TN_tullahoma', label: 'TN - Tullahoma', state: 'US-TN' },

  { value: 'TX_abilene', label: 'TX - Abilene', state: 'US-TX' },
  { value: 'TX_alpine', label: 'TX - Alpine', state: 'US-TX' },
  { value: 'TX_amarillo', label: 'TX - Amarillo', state: 'US-TX' },
  { value: 'TX_arlington', label: 'TX - Arlington', state: 'US-TX' },
  { value: 'TX_austin', label: 'TX - Austin', state: 'US-TX' },
  { value: 'TX_baytown', label: 'TX - Baytown', state: 'US-TX' },
  { value: 'TX_beaumont', label: 'TX - Beaumont', state: 'US-TX' },
  { value: 'TX_big_spring', label: 'TX - Big Spring', state: 'US-TX' },
  { value: 'TX_borger', label: 'TX - Borger', state: 'US-TX' },
  { value: 'TX_brownsville', label: 'TX - Brownsville', state: 'US-TX' },
  { value: 'TX_bryan', label: 'TX - Bryan', state: 'US-TX' },
  { value: 'TX_canyon', label: 'TX - Canyon', state: 'US-TX' },
  { value: 'TX_cleburne', label: 'TX - Cleburne', state: 'US-TX' },
  { value: 'TX_college_station', label: 'TX - College Station', state: 'US-TX' },
  { value: 'TX_corpus_christi', label: 'TX - Corpus Christi', state: 'US-TX' },
  { value: 'TX_crystal_city', label: 'TX - Crystal City', state: 'US-TX' },
  { value: 'TX_dallas', label: 'TX - Dallas', state: 'US-TX' },
  { value: 'TX_del_rio', label: 'TX - Del Rio', state: 'US-TX' },
  { value: 'TX_denison', label: 'TX - Denison', state: 'US-TX' },
  { value: 'TX_denton', label: 'TX - Denton', state: 'US-TX' },
  { value: 'TX_eagle_pass', label: 'TX - Eagle Pass', state: 'US-TX' },
  { value: 'TX_edinburg', label: 'TX - Edinburg', state: 'US-TX' },
  { value: 'TX_el_paso', label: 'TX - El Paso', state: 'US-TX' },
  { value: 'TX_fort_worth', label: 'TX - Fort Worth', state: 'US-TX' },
  { value: 'TX_freeport', label: 'TX - Freeport', state: 'US-TX' },
  { value: 'TX_galveston', label: 'TX - Galveston', state: 'US-TX' },
  { value: 'TX_garland', label: 'TX - Garland', state: 'US-TX' },
  { value: 'TX_goliad', label: 'TX - Goliad', state: 'US-TX' },
  { value: 'TX_greenville', label: 'TX - Greenville', state: 'US-TX' },
  { value: 'TX_harlingen', label: 'TX - Harlingen', state: 'US-TX' },
  { value: 'TX_houston', label: 'TX - Houston', state: 'US-TX' },
  { value: 'TX_huntsville', label: 'TX - Huntsville', state: 'US-TX' },
  { value: 'TX_irving', label: 'TX - Irving', state: 'US-TX' },
  { value: 'TX_johnson_city', label: 'TX - Johnson City', state: 'US-TX' },
  { value: 'TX_kilgore', label: 'TX - Kilgore', state: 'US-TX' },
  { value: 'TX_killeen', label: 'TX - Killeen', state: 'US-TX' },
  { value: 'TX_kingsville', label: 'TX - Kingsville', state: 'US-TX' },
  { value: 'TX_laredo', label: 'TX - Laredo', state: 'US-TX' },
  { value: 'TX_longview', label: 'TX - Longview', state: 'US-TX' },
  { value: 'TX_lubbock', label: 'TX - Lubbock', state: 'US-TX' },
  { value: 'TX_lufkin', label: 'TX - Lufkin', state: 'US-TX' },
  { value: 'TX_marshall', label: 'TX - Marshall', state: 'US-TX' },
  { value: 'TX_mcallen', label: 'TX - McAllen', state: 'US-TX' },
  { value: 'TX_mckinney', label: 'TX - McKinney', state: 'US-TX' },
  { value: 'TX_mesquite', label: 'TX - Mesquite', state: 'US-TX' },
  { value: 'TX_midland', label: 'TX - Midland', state: 'US-TX' },
  { value: 'TX_mission', label: 'TX - Mission', state: 'US-TX' },
  { value: 'TX_nacogdoches', label: 'TX - Nacogdoches', state: 'US-TX' },
  { value: 'TX_new_braunfels', label: 'TX - New Braunfels', state: 'US-TX' },
  { value: 'TX_odessa', label: 'TX - Odessa', state: 'US-TX' },
  { value: 'TX_orange', label: 'TX - Orange', state: 'US-TX' },
  { value: 'TX_pampa', label: 'TX - Pampa', state: 'US-TX' },
  { value: 'TX_paris', label: 'TX - Paris', state: 'US-TX' },
  { value: 'TX_pasadena', label: 'TX - Pasadena', state: 'US-TX' },
  { value: 'TX_pecos', label: 'TX - Pecos', state: 'US-TX' },
  { value: 'TX_pharr', label: 'TX - Pharr', state: 'US-TX' },
  { value: 'TX_plainview', label: 'TX - Plainview', state: 'US-TX' },
  { value: 'TX_plano', label: 'TX - Plano', state: 'US-TX' },
  { value: 'TX_port_arthur', label: 'TX - Port Arthur', state: 'US-TX' },
  { value: 'TX_port_lavaca', label: 'TX - Port Lavaca', state: 'US-TX' },
  { value: 'TX_richardson', label: 'TX - Richardson', state: 'US-TX' },
  { value: 'TX_san_angelo', label: 'TX - San Angelo', state: 'US-TX' },
  { value: 'TX_san_antonio', label: 'TX - San Antonio', state: 'US-TX' },
  { value: 'TX_san_felipe', label: 'TX - San Felipe', state: 'US-TX' },
  { value: 'TX_san_marcos', label: 'TX - San Marcos', state: 'US-TX' },
  { value: 'TX_sherman', label: 'TX - Sherman', state: 'US-TX' },
  { value: 'TX_sweetwater', label: 'TX - Sweetwater', state: 'US-TX' },
  { value: 'TX_temple', label: 'TX - Temple', state: 'US-TX' },
  { value: 'TX_texarkana', label: 'TX - Texarkana', state: 'US-TX' },
  { value: 'TX_texas_city', label: 'TX - Texas City', state: 'US-TX' },
  { value: 'TX_tyler', label: 'TX - Tyler', state: 'US-TX' },
  { value: 'TX_uvalde', label: 'TX - Uvalde', state: 'US-TX' },
  { value: 'TX_victoria', label: 'TX - Victoria', state: 'US-TX' },
  { value: 'TX_waco', label: 'TX - Waco', state: 'US-TX' },
  { value: 'TX_weatherford', label: 'TX - Weatherford', state: 'US-TX' },
  { value: 'TX_wichita_falls', label: 'TX - Wichita Falls', state: 'US-TX' },
  { value: 'TX_ysleta', label: 'TX - Ysleta', state: 'US-TX' },

  { value: 'UT_alta', label: 'UT - Alta', state: 'US-UT' },
  { value: 'UT_american_fork', label: 'UT - American Fork', state: 'US-UT' },
  { value: 'UT_bountiful', label: 'UT - Bountiful', state: 'US-UT' },
  { value: 'UT_brigham_city', label: 'UT - Brigham City', state: 'US-UT' },
  { value: 'UT_cedar_city', label: 'UT - Cedar City', state: 'US-UT' },
  { value: 'UT_clearfield', label: 'UT - Clearfield', state: 'US-UT' },
  { value: 'UT_delta', label: 'UT - Delta', state: 'US-UT' },
  { value: 'UT_fillmore', label: 'UT - Fillmore', state: 'US-UT' },
  { value: 'UT_green_river', label: 'UT - Green River', state: 'US-UT' },
  { value: 'UT_heber_city', label: 'UT - Heber City', state: 'US-UT' },
  { value: 'UT_kanab', label: 'UT - Kanab', state: 'US-UT' },
  { value: 'UT_layton', label: 'UT - Layton', state: 'US-UT' },
  { value: 'UT_lehi', label: 'UT - Lehi', state: 'US-UT' },
  { value: 'UT_logan', label: 'UT - Logan', state: 'US-UT' },
  { value: 'UT_manti', label: 'UT - Manti', state: 'US-UT' },
  { value: 'UT_moab', label: 'UT - Moab', state: 'US-UT' },
  { value: 'UT_monticello', label: 'UT - Monticello', state: 'US-UT' },
  { value: 'UT_murray', label: 'UT - Murray', state: 'US-UT' },
  { value: 'UT_nephi', label: 'UT - Nephi', state: 'US-UT' },
  { value: 'UT_ogden', label: 'UT - Ogden', state: 'US-UT' },
  { value: 'UT_orderville', label: 'UT - Orderville', state: 'US-UT' },
  { value: 'UT_orem', label: 'UT - Orem', state: 'US-UT' },
  { value: 'UT_panguitch', label: 'UT - Panguitch', state: 'US-UT' },
  { value: 'UT_park_city', label: 'UT - Park City', state: 'US-UT' },
  { value: 'UT_payson', label: 'UT - Payson', state: 'US-UT' },
  { value: 'UT_price', label: 'UT - Price', state: 'US-UT' },
  { value: 'UT_provo', label: 'UT - Provo', state: 'US-UT' },
  { value: 'UT_saint_george', label: 'UT - Saint George', state: 'US-UT' },
  { value: 'UT_salt_lake_city', label: 'UT - Salt Lake City', state: 'US-UT' },
  { value: 'UT_spanish_fork', label: 'UT - Spanish Fork', state: 'US-UT' },
  { value: 'UT_springville', label: 'UT - Springville', state: 'US-UT' },
  { value: 'UT_tooele', label: 'UT - Tooele', state: 'US-UT' },
  { value: 'UT_vernal', label: 'UT - Vernal', state: 'US-UT' },

  { value: 'VT_barre', label: 'VT - Barre', state: 'US-VT' },
  { value: 'VT_bellows_falls', label: 'VT - Bellows Falls', state: 'US-VT' },
  { value: 'VT_bennington', label: 'VT - Bennington', state: 'US-VT' },
  { value: 'VT_brattleboro', label: 'VT - Brattleboro', state: 'US-VT' },
  { value: 'VT_burlington', label: 'VT - Burlington', state: 'US-VT' },
  { value: 'VT_essex', label: 'VT - Essex', state: 'US-VT' },
  { value: 'VT_manchester', label: 'VT - Manchester', state: 'US-VT' },
  { value: 'VT_middlebury', label: 'VT - Middlebury', state: 'US-VT' },
  { value: 'VT_montpelier', label: 'VT - Montpelier', state: 'US-VT' },
  { value: 'VT_newport', label: 'VT - Newport', state: 'US-VT' },
  { value: 'VT_plymouth', label: 'VT - Plymouth', state: 'US-VT' },
  { value: 'VT_rutland', label: 'VT - Rutland', state: 'US-VT' },
  { value: 'VT_saint_albans', label: 'VT - Saint Albans', state: 'US-VT' },
  { value: 'VT_saint_johnsbury', label: 'VT - Saint Johnsbury', state: 'US-VT' },
  { value: 'VT_sharon', label: 'VT - Sharon', state: 'US-VT' },
  { value: 'VT_winooski', label: 'VT - Winooski', state: 'US-VT' },

  { value: 'VI_abingdon', label: 'VI - Abingdon', state: 'US-VI' },
  { value: 'VI_alexandria', label: 'VI - Alexandria', state: 'US-VI' },
  { value: 'VI_bristol', label: 'VI - Bristol', state: 'US-VI' },
  { value: 'VI_charlottesville', label: 'VI - Charlottesville', state: 'US-VI' },
  { value: 'VI_chesapeake', label: 'VI - Chesapeake', state: 'US-VI' },
  { value: 'VI_danville', label: 'VI - Danville', state: 'US-VI' },
  { value: 'VI_fairfax', label: 'VI - Fairfax', state: 'US-VI' },
  { value: 'VI_falls_church', label: 'VI - Falls Church', state: 'US-VI' },
  { value: 'VI_fredericksburg', label: 'VI - Fredericksburg', state: 'US-VI' },
  { value: 'VI_hampton', label: 'VI - Hampton', state: 'US-VI' },
  { value: 'VI_hanover', label: 'VI - Hanover', state: 'US-VI' },
  { value: 'VI_hopewell', label: 'VI - Hopewell', state: 'US-VI' },
  { value: 'VI_lexington', label: 'VI - Lexington', state: 'US-VI' },
  { value: 'VI_lynchburg', label: 'VI - Lynchburg', state: 'US-VI' },
  { value: 'VI_manassas', label: 'VI - Manassas', state: 'US-VI' },
  { value: 'VI_martinsville', label: 'VI - Martinsville', state: 'US-VI' },
  { value: 'VI_new_market', label: 'VI - New Market', state: 'US-VI' },
  { value: 'VI_newport_news', label: 'VI - Newport News', state: 'US-VI' },
  { value: 'VI_norfolk', label: 'VI - Norfolk', state: 'US-VI' },
  { value: 'VI_petersburg', label: 'VI - Petersburg', state: 'US-VI' },
  { value: 'VI_portsmouth', label: 'VI - Portsmouth', state: 'US-VI' },
  { value: 'VI_reston', label: 'VI - Reston', state: 'US-VI' },
  { value: 'VI_richmond', label: 'VI - Richmond', state: 'US-VI' },
  { value: 'VI_roanoke', label: 'VI - Roanoke', state: 'US-VI' },
  { value: 'VI_staunton', label: 'VI - Staunton', state: 'US-VI' },
  { value: 'VI_suffolk', label: 'VI - Suffolk', state: 'US-VI' },
  { value: 'VI_virginia_beach', label: 'VI - Virginia Beach', state: 'US-VI' },
  { value: 'VI_waynesboro', label: 'VI - Waynesboro', state: 'US-VI' },
  { value: 'VI_williamsburg', label: 'VI - Williamsburg', state: 'US-VI' },
  { value: 'VI_winchester', label: 'VI - Winchester', state: 'US-VI' },

  { value: 'WA_aberdeen', label: 'WA - Aberdeen', state: 'US-WA' },
  { value: 'WA_anacortes', label: 'WA - Anacortes', state: 'US-WA' },
  { value: 'WA_auburn', label: 'WA - Auburn', state: 'US-WA' },
  { value: 'WA_bellevue', label: 'WA - Bellevue', state: 'US-WA' },
  { value: 'WA_bellingham', label: 'WA - Bellingham', state: 'US-WA' },
  { value: 'WA_bremerton', label: 'WA - Bremerton', state: 'US-WA' },
  { value: 'WA_centralia', label: 'WA - Centralia', state: 'US-WA' },
  { value: 'WA_coulee_dam', label: 'WA - Coulee Dam', state: 'US-WA' },
  { value: 'WA_coupeville', label: 'WA - Coupeville', state: 'US-WA' },
  { value: 'WA_ellensburg', label: 'WA - Ellensburg', state: 'US-WA' },
  { value: 'WA_ephrata', label: 'WA - Ephrata', state: 'US-WA' },
  { value: 'WA_everett', label: 'WA - Everett', state: 'US-WA' },
  { value: 'WA_hoquiam', label: 'WA - Hoquiam', state: 'US-WA' },
  { value: 'WA_kelso', label: 'WA - Kelso', state: 'US-WA' },
  { value: 'WA_kennewick', label: 'WA - Kennewick', state: 'US-WA' },
  { value: 'WA_longview', label: 'WA - Longview', state: 'US-WA' },
  { value: 'WA_moses_lake', label: 'WA - Moses Lake', state: 'US-WA' },
  { value: 'WA_oak_harbor', label: 'WA - Oak Harbor', state: 'US-WA' },
  { value: 'WA_olympia', label: 'WA - Olympia', state: 'US-WA' },
  { value: 'WA_pasco', label: 'WA - Pasco', state: 'US-WA' },
  { value: 'WA_point_roberts', label: 'WA - Point Roberts', state: 'US-WA' },
  { value: 'WA_port_angeles', label: 'WA - Port Angeles', state: 'US-WA' },
  { value: 'WA_pullman', label: 'WA - Pullman', state: 'US-WA' },
  { value: 'WA_puyallup', label: 'WA - Puyallup', state: 'US-WA' },
  { value: 'WA_redmond', label: 'WA - Redmond', state: 'US-WA' },
  { value: 'WA_renton', label: 'WA - Renton', state: 'US-WA' },
  { value: 'WA_richland', label: 'WA - Richland', state: 'US-WA' },
  { value: 'WA_seattle', label: 'WA - Seattle', state: 'US-WA' },
  { value: 'WA_spokane', label: 'WA - Spokane', state: 'US-WA' },
  { value: 'WA_tacoma', label: 'WA - Tacoma', state: 'US-WA' },
  { value: 'WA_vancouver', label: 'WA - Vancouver', state: 'US-WA' },
  { value: 'WA_walla_walla', label: 'WA - Walla Walla', state: 'US-WA' },
  { value: 'WA_wenatchee', label: 'WA - Wenatchee', state: 'US-WA' },
  { value: 'WA_yakima', label: 'WA - Yakima', state: 'US-WA' },

  { value: 'WV_bath', label: 'WV - Bath', state: 'US-WV' },
  { value: 'WV_beckley', label: 'WV - Beckley', state: 'US-WV' },
  { value: 'WV_bluefield', label: 'WV - Bluefield', state: 'US-WV' },
  { value: 'WV_buckhannon', label: 'WV - Buckhannon', state: 'US-WV' },
  { value: 'WV_charles_town', label: 'WV - Charles Town', state: 'US-WV' },
  { value: 'WV_charleston', label: 'WV - Charleston', state: 'US-WV' },
  { value: 'WV_clarksburg', label: 'WV - Clarksburg', state: 'US-WV' },
  { value: 'WV_elkins', label: 'WV - Elkins', state: 'US-WV' },
  { value: 'WV_fairmont', label: 'WV - Fairmont', state: 'US-WV' },
  { value: 'WV_grafton', label: 'WV - Grafton', state: 'US-WV' },
  { value: 'WV_harpers_ferry', label: 'WV - Harpers Ferry', state: 'US-WV' },
  { value: 'WV_hillsboro', label: 'WV - Hillsboro', state: 'US-WV' },
  { value: 'WV_hinton', label: 'WV - Hinton', state: 'US-WV' },
  { value: 'WV_huntington', label: 'WV - Huntington', state: 'US-WV' },
  { value: 'WV_keyser', label: 'WV - Keyser', state: 'US-WV' },
  { value: 'WV_lewisburg', label: 'WV - Lewisburg', state: 'US-WV' },
  { value: 'WV_logan', label: 'WV - Logan', state: 'US-WV' },
  { value: 'WV_martinsburg', label: 'WV - Martinsburg', state: 'US-WV' },
  { value: 'WV_morgantown', label: 'WV - Morgantown', state: 'US-WV' },
  { value: 'WV_moundsville', label: 'WV - Moundsville', state: 'US-WV' },
  { value: 'WV_new_martinsville', label: 'WV - New Martinsville', state: 'US-WV' },
  { value: 'WV_parkersburg', label: 'WV - Parkersburg', state: 'US-WV' },
  { value: 'WV_philippi', label: 'WV - Philippi', state: 'US-WV' },
  { value: 'WV_point_pleasant', label: 'WV - Point Pleasant', state: 'US-WV' },
  { value: 'WV_princeton', label: 'WV - Princeton', state: 'US-WV' },
  { value: 'WV_romney', label: 'WV - Romney', state: 'US-WV' },
  { value: 'WV_shepherdstown', label: 'WV - Shepherdstown', state: 'US-WV' },
  { value: 'WV_summersville', label: 'WV - Summersville', state: 'US-WV' },
  { value: 'WV_weirton', label: 'WV - Weirton', state: 'US-WV' },
  { value: 'WV_welch', label: 'WV - Welch', state: 'US-WV' },
  { value: 'WV_wellsburg', label: 'WV - Wellsburg', state: 'US-WV' },
  { value: 'WV_weston', label: 'WV - Weston', state: 'US-WV' },
  { value: 'WV_wheeling', label: 'WV - Wheeling', state: 'US-WV' },
  { value: 'WV_white_sulphur_springs', label: 'WV - White Sulphur Springs', state: 'US-WV' },
  { value: 'WV_williamson', label: 'WV - Williamson', state: 'US-WV' },

  { value: 'WI_appleton', label: 'WI - Appleton', state: 'US-WI' },
  { value: 'WI_ashland', label: 'WI - Ashland', state: 'US-WI' },
  { value: 'WI_baraboo', label: 'WI - Baraboo', state: 'US-WI' },
  { value: 'WI_belmont', label: 'WI - Belmont', state: 'US-WI' },
  { value: 'WI_beloit', label: 'WI - Beloit', state: 'US-WI' },
  { value: 'WI_eau_claire', label: 'WI - Eau Claire', state: 'US-WI' },
  { value: 'WI_fond_du_lac', label: 'WI - Fond du Lac', state: 'US-WI' },
  { value: 'WI_green_bay', label: 'WI - Green Bay', state: 'US-WI' },
  { value: 'WI_hayward', label: 'WI - Hayward', state: 'US-WI' },
  { value: 'WI_janesville', label: 'WI - Janesville', state: 'US-WI' },
  { value: 'WI_kenosha', label: 'WI - Kenosha', state: 'US-WI' },
  { value: 'WI_la_crosse', label: 'WI - La Crosse', state: 'US-WI' },
  { value: 'WI_lake_geneva', label: 'WI - Lake Geneva', state: 'US-WI' },
  { value: 'WI_madison', label: 'WI - Madison', state: 'US-WI' },
  { value: 'WI_manitowoc', label: 'WI - Manitowoc', state: 'US-WI' },
  { value: 'WI_marinette', label: 'WI - Marinette', state: 'US-WI' },
  { value: 'WI_menasha', label: 'WI - Menasha', state: 'US-WI' },
  { value: 'WI_milwaukee', label: 'WI - Milwaukee', state: 'US-WI' },
  { value: 'WI_neenah', label: 'WI - Neenah', state: 'US-WI' },
  { value: 'WI_new_glarus', label: 'WI - New Glarus', state: 'US-WI' },
  { value: 'WI_oconto', label: 'WI - Oconto', state: 'US-WI' },
  { value: 'WI_oshkosh', label: 'WI - Oshkosh', state: 'US-WI' },
  { value: 'WI_peshtigo', label: 'WI - Peshtigo', state: 'US-WI' },
  { value: 'WI_portage', label: 'WI - Portage', state: 'US-WI' },
  { value: 'WI_prairie_du_chien', label: 'WI - Prairie du Chien', state: 'US-WI' },
  { value: 'WI_racine', label: 'WI - Racine', state: 'US-WI' },
  { value: 'WI_rhinelander', label: 'WI - Rhinelander', state: 'US-WI' },
  { value: 'WI_ripon', label: 'WI - Ripon', state: 'US-WI' },
  { value: 'WI_sheboygan', label: 'WI - Sheboygan', state: 'US-WI' },
  { value: 'WI_spring_green', label: 'WI - Spring Green', state: 'US-WI' },
  { value: 'WI_stevens_point', label: 'WI - Stevens Point', state: 'US-WI' },
  { value: 'WI_sturgeon_bay', label: 'WI - Sturgeon Bay', state: 'US-WI' },
  { value: 'WI_superior', label: 'WI - Superior', state: 'US-WI' },
  { value: 'WI_waukesha', label: 'WI - Waukesha', state: 'US-WI' },
  { value: 'WI_wausau', label: 'WI - Wausau', state: 'US-WI' },
  { value: 'WI_wauwatosa', label: 'WI - Wauwatosa', state: 'US-WI' },
  { value: 'WI_west_allis', label: 'WI - West Allis', state: 'US-WI' },
  { value: 'WI_west_bend', label: 'WI - West Bend', state: 'US-WI' },
  { value: 'WI_wisconsin_dells', label: 'WI - Wisconsin Dells', state: 'US-WI' },

  { value: 'WY_buffalo', label: 'WY - Buffalo', state: 'US-WY' },
  { value: 'WY_casper', label: 'WY - Casper', state: 'US-WY' },
  { value: 'WY_cheyenne', label: 'WY - Cheyenne', state: 'US-WY' },
  { value: 'WY_cody', label: 'WY - Cody', state: 'US-WY' },
  { value: 'WY_douglas', label: 'WY - Douglas', state: 'US-WY' },
  { value: 'WY_evanston', label: 'WY - Evanston', state: 'US-WY' },
  { value: 'WY_gillette', label: 'WY - Gillette', state: 'US-WY' },
  { value: 'WY_green_river', label: 'WY - Green River', state: 'US-WY' },
  { value: 'WY_jackson', label: 'WY - Jackson', state: 'US-WY' },
  { value: 'WY_lander', label: 'WY - Lander', state: 'US-WY' },
  { value: 'WY_laramie', label: 'WY - Laramie', state: 'US-WY' },
  { value: 'WY_newcastle', label: 'WY - Newcastle', state: 'US-WY' },
  { value: 'WY_powell', label: 'WY - Powell', state: 'US-WY' },
  { value: 'WY_rawlins', label: 'WY - Rawlins', state: 'US-WY' },
  { value: 'WY_riverton', label: 'WY - Riverton', state: 'US-WY' },
  { value: 'WY_rock_springs', label: 'WY - Rock Springs', state: 'US-WY' },
  { value: 'WY_sheridan', label: 'WY - Sheridan', state: 'US-WY' },
  { value: 'WY_ten_sleep', label: 'WY - Ten Sleep', state: 'US-WY' },
  { value: 'WY_thermopolis', label: 'WY - Thermopolis', state: 'US-WY' },
  { value: 'WY_torrington', label: 'WY - Torrington', state: 'US-WY' },
  { value: 'WY_worland', label: 'WY - Worland', state: 'US-WY' },
];

export const CANADA_PROVINCES = [
  { value: 'CA-AB', label: 'Canada - Alberta' },
  { value: 'CA-BC', label: 'Canada - British Columbia' },
  { value: 'CA-MB', label: 'Canada - Manitoba' },
  { value: 'CA-NB', label: 'Canada - New Brunswick' },
  { value: 'CA-NL', label: 'Canada - Newfoundland and Labrador' },
  { value: 'CA-NT', label: 'Canada - Northwest Territories' },
  { value: 'CA-NS', label: 'Canada - Nova Scotia' },
  { value: 'CA-NU', label: 'Canada - Nunavut' },
  { value: 'CA-ON', label: 'Canada - Ontario' },
  { value: 'CA-PE', label: 'Canada - Prince Edward Island' },
  { value: 'CA-QC', label: 'Canada - Quebec' },
  { value: 'CA-SK', label: 'Canada - Saskatchewan' },
  { value: 'CA-YT', label: 'Canada - Yukon' },
];

export const CANADA_CITIES = [
  { value: 'AB_banff', label: 'AB - Banff', state: 'CA-AB' },
  { value: 'AB_brooks', label: 'AB - Brooks', state: 'CA-AB' },
  { value: 'AB_calgary', label: 'AB - Calgary', state: 'CA-AB' },
  { value: 'AB_edmonton', label: 'AB - Edmonton', state: 'CA-AB' },
  { value: 'AB_fort_mcmurray', label: 'AB - Fort McMurray', state: 'CA-AB' },
  { value: 'AB_grande_prairie', label: 'AB - Grande Prairie', state: 'CA-AB' },
  { value: 'AB_jasper', label: 'AB - Jasper', state: 'CA-AB' },
  { value: 'AB_lake_louise', label: 'AB - Lake Louise', state: 'CA-AB' },
  { value: 'AB_lethbridge', label: 'AB - Lethbridge', state: 'CA-AB' },
  { value: 'AB_medicine_hat', label: 'AB - Medicine Hat', state: 'CA-AB' },
  { value: 'AB_red_deer', label: 'AB - Red Deer', state: 'CA-AB' },
  { value: 'AB_saint_albert', label: 'AB - Saint Albert', state: 'CA-AB' },

  { value: 'BC_barkerville', label: 'BC - Barkerville', state: 'CA-BC' },
  { value: 'BC_burnaby', label: 'BC - Burnaby', state: 'CA-BC' },
  { value: 'BC_campbell_river', label: 'BC - Campbell River', state: 'CA-BC' },
  { value: 'BC_chilliwack', label: 'BC - Chilliwack', state: 'CA-BC' },
  { value: 'BC_courtenay', label: 'BC - Courtenay', state: 'CA-BC' },
  { value: 'BC_cranbrook', label: 'BC - Cranbrook', state: 'CA-BC' },
  { value: 'BC_dawson_creek', label: 'BC - Dawson Creek', state: 'CA-BC' },
  { value: 'BC_delta', label: 'BC - Delta', state: 'CA-BC' },
  { value: 'BC_esquimalt', label: 'BC - Esquimalt', state: 'CA-BC' },
  { value: 'BC_fort_saint_james', label: 'BC - Fort Saint James', state: 'CA-BC' },
  { value: 'BC_fort_saint_john', label: 'BC - Fort Saint John', state: 'CA-BC' },
  { value: 'BC_hope', label: 'BC - Hope', state: 'CA-BC' },
  { value: 'BC_kamloops', label: 'BC - Kamloops', state: 'CA-BC' },
  { value: 'BC_kelowna', label: 'BC - Kelowna', state: 'CA-BC' },
  { value: 'BC_kimberley', label: 'BC - Kimberley', state: 'CA-BC' },
  { value: 'BC_kitimat', label: 'BC - Kitimat', state: 'CA-BC' },
  { value: 'BC_langley', label: 'BC - Langley', state: 'CA-BC' },
  { value: 'BC_nanaimo', label: 'BC - Nanaimo', state: 'CA-BC' },
  { value: 'BC_nelson', label: 'BC - Nelson', state: 'CA-BC' },
  { value: 'BC_new_westminster', label: 'BC - New Westminster', state: 'CA-BC' },
  { value: 'BC_north_vancouver', label: 'BC - North Vancouver', state: 'CA-BC' },
  { value: 'BC_oak_bay', label: 'BC - Oak Bay', state: 'CA-BC' },
  { value: 'BC_penticton', label: 'BC - Penticton', state: 'CA-BC' },
  { value: 'BC_powell_river', label: 'BC - Powell River', state: 'CA-BC' },
  { value: 'BC_prince_george', label: 'BC - Prince George', state: 'CA-BC' },
  { value: 'BC_prince_rupert', label: 'BC - Prince Rupert', state: 'CA-BC' },
  { value: 'BC_quesnel', label: 'BC - Quesnel', state: 'CA-BC' },
  { value: 'BC_revelstoke', label: 'BC - Revelstoke', state: 'CA-BC' },
  { value: 'BC_rossland', label: 'BC - Rossland', state: 'CA-BC' },
  { value: 'BC_trail', label: 'BC - Trail', state: 'CA-BC' },
  { value: 'BC_vancouver', label: 'BC - Vancouver', state: 'CA-BC' },
  { value: 'BC_vernon', label: 'BC - Vernon', state: 'CA-BC' },
  { value: 'BC_victoria', label: 'BC - Victoria', state: 'CA-BC' },
  { value: 'BC_west_vancouver', label: 'BC - West Vancouver', state: 'CA-BC' },
  { value: 'BC_white_rock', label: 'BC - White Rock', state: 'CA-BC' },

  { value: 'MB_brandon', label: 'MB - Brandon', state: 'CA-MB' },
  { value: 'MB_churchill', label: 'MB - Churchill', state: 'CA-MB' },
  { value: 'MB_dauphin', label: 'MB - Dauphin', state: 'CA-MB' },
  { value: 'MB_flin_flon', label: 'MB - Flin Flon', state: 'CA-MB' },
  { value: 'MB_kildonan', label: 'MB - Kildonan', state: 'CA-MB' },
  { value: 'MB_saint_boniface', label: 'MB - Saint Boniface', state: 'CA-MB' },
  { value: 'MB_swan_river', label: 'MB - Swan River', state: 'CA-MB' },
  { value: 'MB_thompson', label: 'MB - Thompson', state: 'CA-MB' },
  { value: 'MB_winnipeg', label: 'MB - Winnipeg', state: 'CA-MB' },
  { value: 'MB_york_factory', label: 'MB - York Factory', state: 'CA-MB' },

  { value: 'NB_bathurst', label: 'NB - Bathurst', state: 'CA-NB' },
  { value: 'NB_caraquet', label: 'NB - Caraquet', state: 'CA-NB' },
  { value: 'NB_dalhousie', label: 'NB - Dalhousie', state: 'CA-NB' },
  { value: 'NB_fredericton', label: 'NB - Fredericton', state: 'CA-NB' },
  { value: 'NB_miramichi', label: 'NB - Miramichi', state: 'CA-NB' },
  { value: 'NB_moncton', label: 'NB - Moncton', state: 'CA-NB' },
  { value: 'NB_saint_john', label: 'NB - Saint John', state: 'CA-NB' },

  { value: 'NL_argentia', label: 'NL - Argentia', state: 'CA-NL' },
  { value: 'NL_bonavista', label: 'NL - Bonavista', state: 'CA-NL' },
  { value: 'NL_channel-port_aux_basques', label: 'NL - Channel-Port aux Basques', state: 'CA-NL' },
  { value: 'NL_corner_brook', label: 'NL - Corner Brook', state: 'CA-NL' },
  { value: 'NL_ferryland', label: 'NL - Ferryland', state: 'CA-NL' },
  { value: 'NL_gander', label: 'NL - Gander', state: 'CA-NL' },
  { value: 'NL_grand_falls–windsor', label: 'NL - Grand Falls–Windsor', state: 'CA-NL' },
  { value: 'NL_happy_valley–goose_bay', label: 'NL - Happy Valley–Goose Bay', state: 'CA-NL' },
  { value: 'NL_harbour_grace', label: 'NL - Harbour Grace', state: 'CA-NL' },
  { value: 'NL_labrador_city', label: 'NL - Labrador City', state: 'CA-NL' },
  { value: 'NL_placentia', label: 'NL - Placentia', state: 'CA-NL' },
  { value: 'NL_saint_anthony', label: 'NL - Saint Anthony', state: 'CA-NL' },
  { value: 'NL_st._john’s', label: 'NL - St. John’s', state: 'CA-NL' },
  { value: 'NL_wabana', label: 'NL - Wabana', state: 'CA-NL' },

  { value: 'NT_fort_smith', label: 'NT - Fort Smith', state: 'CA-NT' },
  { value: 'NT_hay_river', label: 'NT - Hay River', state: 'CA-NT' },
  { value: 'NT_inuvik', label: 'NT - Inuvik', state: 'CA-NT' },
  { value: 'NT_tuktoyaktuk', label: 'NT - Tuktoyaktuk', state: 'CA-NT' },
  { value: 'NT_yellowknife', label: 'NT - Yellowknife', state: 'CA-NT' },

  { value: 'NS_baddeck', label: 'NS - Baddeck', state: 'CA-NS' },
  { value: 'NS_digby', label: 'NS - Digby', state: 'CA-NS' },
  { value: 'NS_glace_bay', label: 'NS - Glace Bay', state: 'CA-NS' },
  { value: 'NS_halifax', label: 'NS - Halifax', state: 'CA-NS' },
  { value: 'NS_liverpool', label: 'NS - Liverpool', state: 'CA-NS' },
  { value: 'NS_louisbourg', label: 'NS - Louisbourg', state: 'CA-NS' },
  { value: 'NS_lunenburg', label: 'NS - Lunenburg', state: 'CA-NS' },
  { value: 'NS_pictou', label: 'NS - Pictou', state: 'CA-NS' },
  { value: 'NS_port_hawkesbury', label: 'NS - Port Hawkesbury', state: 'CA-NS' },
  { value: 'NS_springhill', label: 'NS - Springhill', state: 'CA-NS' },
  { value: 'NS_sydney', label: 'NS - Sydney', state: 'CA-NS' },
  { value: 'NS_yarmouth', label: 'NS - Yarmouth', state: 'CA-NS' },

  { value: 'NU_iqaluit', label: 'NU - Iqaluit', state: 'CA-NU' },

  { value: 'ON_bancroft', label: 'ON - Bancroft', state: 'CA-ON' },
  { value: 'ON_barrie', label: 'ON - Barrie', state: 'CA-ON' },
  { value: 'ON_belleville', label: 'ON - Belleville', state: 'CA-ON' },
  { value: 'ON_brampton', label: 'ON - Brampton', state: 'CA-ON' },
  { value: 'ON_brantford', label: 'ON - Brantford', state: 'CA-ON' },
  { value: 'ON_brockville', label: 'ON - Brockville', state: 'CA-ON' },
  { value: 'ON_burlington', label: 'ON - Burlington', state: 'CA-ON' },
  { value: 'ON_cambridge', label: 'ON - Cambridge', state: 'CA-ON' },
  { value: 'ON_chatham', label: 'ON - Chatham', state: 'CA-ON' },
  { value: 'ON_chatham-kent', label: 'ON - Chatham-Kent', state: 'CA-ON' },
  { value: 'ON_cornwall', label: 'ON - Cornwall', state: 'CA-ON' },
  { value: 'ON_elliot_lake', label: 'ON - Elliot Lake', state: 'CA-ON' },
  { value: 'ON_etobicoke', label: 'ON - Etobicoke', state: 'CA-ON' },
  { value: 'ON_fort_erie', label: 'ON - Fort Erie', state: 'CA-ON' },
  { value: 'ON_fort_frances', label: 'ON - Fort Frances', state: 'CA-ON' },
  { value: 'ON_gananoque', label: 'ON - Gananoque', state: 'CA-ON' },
  { value: 'ON_guelph', label: 'ON - Guelph', state: 'CA-ON' },
  { value: 'ON_hamilton', label: 'ON - Hamilton', state: 'CA-ON' },
  { value: 'ON_iroquois_falls', label: 'ON - Iroquois Falls', state: 'CA-ON' },
  { value: 'ON_kapuskasing', label: 'ON - Kapuskasing', state: 'CA-ON' },
  { value: 'ON_kawartha_lakes', label: 'ON - Kawartha Lakes', state: 'CA-ON' },
  { value: 'ON_kenora', label: 'ON - Kenora', state: 'CA-ON' },
  { value: 'ON_kingston', label: 'ON - Kingston', state: 'CA-ON' },
  { value: 'ON_kirkland_lake', label: 'ON - Kirkland Lake', state: 'CA-ON' },
  { value: 'ON_kitchener', label: 'ON - Kitchener', state: 'CA-ON' },
  { value: 'ON_laurentian_hills', label: 'ON - Laurentian Hills', state: 'CA-ON' },
  { value: 'ON_london', label: 'ON - London', state: 'CA-ON' },
  { value: 'ON_midland', label: 'ON - Midland', state: 'CA-ON' },
  { value: 'ON_mississauga', label: 'ON - Mississauga', state: 'CA-ON' },
  { value: 'ON_moose_factory', label: 'ON - Moose Factory', state: 'CA-ON' },
  { value: 'ON_moosonee', label: 'ON - Moosonee', state: 'CA-ON' },
  { value: 'ON_niagara_falls', label: 'ON - Niagara Falls', state: 'CA-ON' },
  { value: 'ON_niagara-on-the-lake', label: 'ON - Niagara-on-the-Lake', state: 'CA-ON' },
  { value: 'ON_north_bay', label: 'ON - North Bay', state: 'CA-ON' },
  { value: 'ON_north_york', label: 'ON - North York', state: 'CA-ON' },
  { value: 'ON_oakville', label: 'ON - Oakville', state: 'CA-ON' },
  { value: 'ON_orillia', label: 'ON - Orillia', state: 'CA-ON' },
  { value: 'ON_oshawa', label: 'ON - Oshawa', state: 'CA-ON' },
  { value: 'ON_ottawa', label: 'ON - Ottawa', state: 'CA-ON' },
  { value: 'ON_parry_sound', label: 'ON - Parry Sound', state: 'CA-ON' },
  { value: 'ON_perth', label: 'ON - Perth', state: 'CA-ON' },
  { value: 'ON_peterborough', label: 'ON - Peterborough', state: 'CA-ON' },
  { value: 'ON_picton', label: 'ON - Picton', state: 'CA-ON' },
  { value: 'ON_port_colborne', label: 'ON - Port Colborne', state: 'CA-ON' },
  { value: 'ON_saint_catharines', label: 'ON - Saint Catharines', state: 'CA-ON' },
  { value: 'ON_saint_thomas', label: 'ON - Saint Thomas', state: 'CA-ON' },
  { value: 'ON_sarnia-clearwater', label: 'ON - Sarnia-Clearwater', state: 'CA-ON' },
  { value: 'ON_sault_sainte_marie', label: 'ON - Sault Sainte Marie', state: 'CA-ON' },
  { value: 'ON_scarborough', label: 'ON - Scarborough', state: 'CA-ON' },
  { value: 'ON_simcoe', label: 'ON - Simcoe', state: 'CA-ON' },
  { value: 'ON_stratford', label: 'ON - Stratford', state: 'CA-ON' },
  { value: 'ON_sudbury', label: 'ON - Sudbury', state: 'CA-ON' },
  { value: 'ON_temiskaming_shores', label: 'ON - Temiskaming Shores', state: 'CA-ON' },
  { value: 'ON_thorold', label: 'ON - Thorold', state: 'CA-ON' },
  { value: 'ON_thunder_bay', label: 'ON - Thunder Bay', state: 'CA-ON' },
  { value: 'ON_timmins', label: 'ON - Timmins', state: 'CA-ON' },
  { value: 'ON_toronto', label: 'ON - Toronto', state: 'CA-ON' },
  { value: 'ON_trenton', label: 'ON - Trenton', state: 'CA-ON' },
  { value: 'ON_waterloo', label: 'ON - Waterloo', state: 'CA-ON' },
  { value: 'ON_welland', label: 'ON - Welland', state: 'CA-ON' },
  { value: 'ON_west_nipissing', label: 'ON - West Nipissing', state: 'CA-ON' },
  { value: 'ON_windsor', label: 'ON - Windsor', state: 'CA-ON' },
  { value: 'ON_woodstock', label: 'ON - Woodstock', state: 'CA-ON' },
  { value: 'ON_york', label: 'ON - York', state: 'CA-ON' },

  { value: 'PE_borden', label: 'PE - Borden', state: 'CA-PE' },
  { value: 'PE_cavendish', label: 'PE - Cavendish', state: 'CA-PE' },
  { value: 'PE_charlottetown', label: 'PE - Charlottetown', state: 'CA-PE' },
  { value: 'PE_souris', label: 'PE - Souris', state: 'CA-PE' },
  { value: 'PE_summerside', label: 'PE - Summerside', state: 'CA-PE' },

  { value: 'QC_asbestos', label: 'QC - Asbestos', state: 'CA-QC' },
  { value: 'QC_baie-comeau', label: 'QC - Baie-Comeau', state: 'CA-QC' },
  { value: 'QC_beloeil', label: 'QC - Beloeil', state: 'CA-QC' },
  { value: 'QC_cap-de-la-madeleine', label: 'QC - Cap-de-la-Madeleine', state: 'CA-QC' },
  { value: 'QC_chambly', label: 'QC - Chambly', state: 'CA-QC' },
  { value: 'QC_charlesbourg', label: 'QC - Charlesbourg', state: 'CA-QC' },
  { value: 'QC_châteauguay', label: 'QC - Châteauguay', state: 'CA-QC' },
  { value: 'QC_chibougamau', label: 'QC - Chibougamau', state: 'CA-QC' },
  { value: 'QC_côte-saint-luc', label: 'QC - Côte-Saint-Luc', state: 'CA-QC' },
  { value: 'QC_dorval', label: 'QC - Dorval', state: 'CA-QC' },
  { value: 'QC_gaspé', label: 'QC - Gaspé', state: 'CA-QC' },
  { value: 'QC_gatineau', label: 'QC - Gatineau', state: 'CA-QC' },
  { value: 'QC_granby', label: 'QC - Granby', state: 'CA-QC' },
  { value: 'QC_havre-saint-pierre', label: 'QC - Havre-Saint-Pierre', state: 'CA-QC' },
  { value: 'QC_hull', label: 'QC - Hull', state: 'CA-QC' },
  { value: 'QC_jonquière', label: 'QC - Jonquière', state: 'CA-QC' },
  { value: 'QC_kuujjuaq', label: 'QC - Kuujjuaq', state: 'CA-QC' },
  { value: 'QC_la_salle', label: 'QC - La Salle', state: 'CA-QC' },
  { value: 'QC_la_tuque', label: 'QC - La Tuque', state: 'CA-QC' },
  { value: 'QC_lachine', label: 'QC - Lachine', state: 'CA-QC' },
  { value: 'QC_laval', label: 'QC - Laval', state: 'CA-QC' },
  { value: 'QC_lévis', label: 'QC - Lévis', state: 'CA-QC' },
  { value: 'QC_longueuil', label: 'QC - Longueuil', state: 'CA-QC' },
  { value: 'QC_magog', label: 'QC - Magog', state: 'CA-QC' },
  { value: 'QC_matane', label: 'QC - Matane', state: 'CA-QC' },
  { value: 'QC_montreal', label: 'QC - Montreal', state: 'CA-QC' },
  { value: 'QC_montréal-nord', label: 'QC - Montréal-Nord', state: 'CA-QC' },
  { value: 'QC_percé', label: 'QC - Percé', state: 'CA-QC' },
  { value: 'QC_port-cartier', label: 'QC - Port-Cartier', state: 'CA-QC' },
  { value: 'QC_quebec', label: 'QC - Quebec', state: 'CA-QC' },
  { value: 'QC_rimouski', label: 'QC - Rimouski', state: 'CA-QC' },
  { value: 'QC_rouyn-noranda', label: 'QC - Rouyn-Noranda', state: 'CA-QC' },
  { value: 'QC_saguenay', label: 'QC - Saguenay', state: 'CA-QC' },
  { value: 'QC_saint-eustache', label: 'QC - Saint-Eustache', state: 'CA-QC' },
  { value: 'QC_saint-hubert', label: 'QC - Saint-Hubert', state: 'CA-QC' },
  { value: 'QC_sainte-anne-de-beaupré', label: 'QC - Sainte-Anne-de-Beaupré', state: 'CA-QC' },
  { value: 'QC_sainte-foy', label: 'QC - Sainte-Foy', state: 'CA-QC' },
  { value: 'QC_sainte-thérèse', label: 'QC - Sainte-Thérèse', state: 'CA-QC' },
  { value: 'QC_sept-îles', label: 'QC - Sept-Îles', state: 'CA-QC' },
  { value: 'QC_sherbrooke', label: 'QC - Sherbrooke', state: 'CA-QC' },
  { value: 'QC_sorel-tracy', label: 'QC - Sorel-Tracy', state: 'CA-QC' },
  { value: 'QC_trois-rivières', label: 'QC - Trois-Rivières', state: 'CA-QC' },
  { value: 'QC_val-d’or', label: 'QC - Val-d’Or', state: 'CA-QC' },
  { value: 'QC_waskaganish', label: 'QC - Waskaganish', state: 'CA-QC' },

  { value: 'SK_batoche', label: 'SK - Batoche', state: 'CA-SK' },
  { value: 'SK_cumberland_house', label: 'SK - Cumberland House', state: 'CA-SK' },
  { value: 'SK_estevan', label: 'SK - Estevan', state: 'CA-SK' },
  { value: 'SK_flin_flon', label: 'SK - Flin Flon', state: 'CA-SK' },
  { value: 'SK_moose_jaw', label: 'SK - Moose Jaw', state: 'CA-SK' },
  { value: 'SK_prince_albert', label: 'SK - Prince Albert', state: 'CA-SK' },
  { value: 'SK_regina', label: 'SK - Regina', state: 'CA-SK' },
  { value: 'SK_saskatoon', label: 'SK - Saskatoon', state: 'CA-SK' },
  { value: 'SK_uranium_city', label: 'SK - Uranium City', state: 'CA-SK' },

  { value: 'YT_dawson', label: 'YT - Dawson', state: 'CA-YT' },
  { value: 'YT_watson_lake', label: 'YT - Watson Lake', state: 'CA-YT' },
  { value: 'YT_whitehorse', label: 'YT - Whitehorse', state: 'CA-YT' },
];

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    label: 'Los Angeles, CA',
    key: 'los_angeles',
  },
  {
    label: 'San Francisco, CA',
    key: 'san_francisco',
  },
  {
    label: 'San Diego, CA',
    key: 'san_diego',
  },
  {
    label: 'New York, NY',
    key: 'new_york_city',
  },
  {
    label: 'Dallas, TX',
    key: 'dallas',
  },
];
export default defaultLocations;

import React, { useEffect, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from 'classnames';

import css from './Accordion.module.css';

const Accordion = props => {
  const { id, children, title, status, className, rootClassName } = props;
  const [isActive, setIsActive] = useState(status || false);

  const classes = rootClassName || classNames(className, css.accordionItem);

  useEffect(() => {
    setIsActive(status);
  }, [status]);

  return (
    <div className={classes} id={id}>
      <div
        className={css.accordionTitle}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <h3 className={css.titleContent}>{title}</h3>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className={css.accordionContent}>{children}</div>}
    </div>
  );
};

const { node, bool } = PropTypes;

Accordion.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
  id: string,
  title: string.isRequired,
  status: bool,
};

Accordion.defaultProps = {
  className: null,
  rootClassName: null,
}

export default Accordion;

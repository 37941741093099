import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment-timezone';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  required,
  bookingDatesRequired,
  composeValidators,
  validatePositiveNumber,
  bookingDateRequired,
} from '../../util/validators';
import { START_DATE, END_DATE, DATE_TYPES, isTypeSingleDay } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  Form,
  PrimaryButton,
  FieldDateRangeInput,
  FieldSelect,
  FieldDateInput,
  FieldTextInput,
} from '../../components';

import css from './BookingDatesForm.module.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.state = {
      timer: null,
    };
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case the booking date is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { singleBookingDate, bookingDates, dateType, ...rest } = e;
    const isSingleBookingDate = dateType === 'single';
    const { startDate, endDate } = isSingleBookingDate
      ? {
          startDate: singleBookingDate.date,
          endDate: moment(singleBookingDate.date)
            .add(1, 'day')
            .toDate(),
        }
      : bookingDates;

    if (startDate && endDate) {
      this.props.onSubmit({ ...rest, dateType, bookingDates: { startDate, endDate } });
      return;
    }

    e.preventDefault();
    this.setState({
      focusedInput: isSingleBookingDate ? SINGLE_DATE : !startDate ? START_DATE : END_DATE,
    });
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            invalid,
            form,
          } = fieldRenderProps;

          // ### LABELS ###
          const bookingStartSingleTitle = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartSingleTitle',
          });
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const bookingDateTypeLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingDateTypeTitle',
          });
          const eventTypeLabel = intl.formatMessage({
            id: 'BookingDatesForm.eventTypeLabelTitle',
          });
          const guestCountLabel = intl.formatMessage({
            id: 'BookingDatesForm.guestCountLabelTitle',
          });
          const eventLocationLabel = intl.formatMessage({
            id: 'BookingDatesForm.eventLocationLabelTitle',
          });
          const eventDetailsLabel = intl.formatMessage({
            id: 'BookingDatesForm.eventDetailsLabelTitle',
          });

          // ### PLACEHOLDER ###
          const eventTypePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.eventTypePlaceholder',
          });
          const eventDetailsPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.eventDetailsPlaceholder',
          });
          const eventLocationPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.eventLocationPlaceholder',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // ### VALIDATORS & ERROR MESSAGES ###
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const guestCountRequiredErrorMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredGuestCount',
          });
          const guestCountErrorMessage = intl.formatMessage({
            id: 'BookingDatesForm.invalidGuestCount',
          });

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const disabledSubmitButton = invalid || fetchLineItemsInProgress || fetchLineItemsError;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FieldSelect
                className={css.fields}
                name="dateType"
                label={bookingDateTypeLabel}
                id={`${formId}.dateType`}
                onChange={() => {
                  form.batch(() => {
                    form.change(
                      'dateType',
                      isTypeSingleDay(values.dateType) ? 'multiple' : 'single'
                    );
                    form.change('bookingDates', undefined);
                    form.change('singleBookingStartDate', undefined);
                  });
                }}
              >
                {DATE_TYPES.map(type => (
                  <option key={type.key} value={type.key}>
                    {type.label}
                  </option>
                ))}
              </FieldSelect>
              {!isTypeSingleDay(values.dateType) ? (
                <FieldDateRangeInput
                  className={css.bookingDates}
                  name="bookingDates"
                  unitType={unitType}
                  startDateId={`${formId}.bookingStartDate`}
                  startDateLabel={bookingStartLabel}
                  startDatePlaceholderText={startDatePlaceholderText}
                  endDateId={`${formId}.bookingEndDate`}
                  endDateLabel={bookingEndLabel}
                  endDatePlaceholderText={endDatePlaceholderText}
                  focusedInput={this.state.focusedInput}
                  onFocusedInputChange={this.onFocusedInputChange}
                  format={identity}
                  timeSlots={timeSlots}
                  useMobileMargins
                  validate={composeValidators(
                    required(requiredMessage),
                    bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                  )}
                  disabled={fetchLineItemsInProgress}
                />
              ) : (
                <FieldDateInput
                  className={css.bookingDates}
                  name="singleBookingDate"
                  id={`${formId}.singleBookingDate`}
                  label={bookingStartSingleTitle}
                  placeholderText={startDatePlaceholderText}
                  timeSlots={timeSlots}
                  useMobileMargins
                  validate={composeValidators(
                    required(requiredMessage),
                    bookingDateRequired(startDateErrorMessage)
                  )}
                  disabled={fetchLineItemsInProgress}
                />
              )}
              <FieldTextInput
                className={css.fields}
                name="eventType"
                label={eventTypeLabel}
                id={`${formId}.eventType`}
                type="textarea"
                placeholder={eventTypePlaceholder}
              />
              <FieldTextInput
                className={css.fields}
                name="guestCount"
                id={`${formId}.guestCount`}
                label={guestCountLabel}
                validate={composeValidators(
                  required(guestCountRequiredErrorMessage),
                  validatePositiveNumber(guestCountErrorMessage)
                )}
              />
              <FieldTextInput
                className={css.fields}
                name="eventLocation"
                label={eventLocationLabel}
                id={`${formId}.eventLocation`}
                type="textarea"
                placeholder={eventLocationPlaceholder}
              />
              <FieldTextInput
                className={css.fields}
                name="eventDetails"
                id={`${formId}.eventDetails`}
                label={eventDetailsLabel}
                type="textarea"
                placeholder={eventDetailsPlaceholder}
              />
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton disabled={disabledSubmitButton} type="submit">
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  onClearLineItems: func,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;

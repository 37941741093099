import { TRANSITION_TO_FULL_PAYMENT_FLOW, hasTransition } from "./transaction";

const KEY_FULL_PAYMENT_FLOW_FROM_START = 'full-payment-flow-from-start';
const KEY_FULL_PAYMENT_AFTER_OFFER_MADE = 'full-payment-after-offer-made';
const KEY_SPLIT_PAYMENT = 'split-payment';

const PROCESS_DISPLAY_PRICE = {
  [KEY_FULL_PAYMENT_FLOW_FROM_START]: (amount) => amount,
  [KEY_FULL_PAYMENT_AFTER_OFFER_MADE]: (amount) => amount * 2,
  [KEY_SPLIT_PAYMENT]: (amount) => amount
}

export const getProcessDisplayPriceFnc = (transaction) => {
  const { metadata: txMetadata = {}, transitions } = transaction.attributes;

  const { isFullPaymentFlow } = txMetadata;

  const processDisplayPriceKey = isFullPaymentFlow
  ? KEY_FULL_PAYMENT_FLOW_FROM_START
  : hasTransition(transitions, TRANSITION_TO_FULL_PAYMENT_FLOW)
    ? KEY_FULL_PAYMENT_AFTER_OFFER_MADE
    : KEY_SPLIT_PAYMENT

  return PROCESS_DISPLAY_PRICE[processDisplayPriceKey]
}
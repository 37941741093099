import { mapArrayToSelectOptions } from './locationHelpers';

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d"
 */
export const parseSelectFilterOptions = uriComponentValue => {
  const startsWithHasAll = uriComponentValue && uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue && uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  } else if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  } else {
    return uriComponentValue.split(',');
  }
};

/**
 * Check if any of the filters (defined by filterIds) have currently active query parameter in URL.
 */
export const isAnyFilterActive = (filterIds, urlQueryParams, filterConfigs) => {
  const getQueryParamKeysOfGivenFilters = (keys, config) => {
    const isFilterIncluded = filterIds.includes(config.id);
    const addedQueryParamNamesMaybe = isFilterIncluded ? config.queryParamNames : [];
    return [...keys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = filterConfigs.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);
  const activeKey = paramEntries.find(entry => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });
  return !!activeKey;
};

/**
 * Check if the filter is currently active.
 */
export const findOptionsForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config && filter.config.options ? filter.config.options : [];
};

/**
 *
 * @param {Array} options collection of all valid option for the select field
 * @param {String} propertyName property name in option object to compare with the selected
 * @param {String} selectedOptionValue selected option value in option object
 * @returns Return array with one element of the selected options has a right key
 */

export const findSelectOptions = (options, propertyName, selectedOptionValue) => {
  return options.filter(option => option[propertyName] === selectedOptionValue);
};

/**
 *   Format URI component's query param: from ([a,b,c], [pubkey], 'has_all) => { pub_key: 'has_all:a,b,c',... }
 *
 * @param {Array<String>} selectedOptions the string array of the selected option from react-select
 *
 * @param {Array<String>} queryParamNames names of the query that use the selected options
 *
 * @param {String} searchMode the search mode defined in the search schema in Flex
 *
 * @return {Object} collection of the query string
 * */
export const formatQueryParams = (selectedOptions, queryParamNames, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return Array.isArray(queryParamNames)
    ? queryParamNames.reduce((queryParams, currentName) => {
        return {
          ...queryParams,
          [currentName]: value,
        };
      }, {})
    : { [queryParamNames]: value };
};

/**
 * Parse the URL query params { pub_key: 'has_all:a,b,c',... } => [{value: a, label: aLabel, state (optional):}, {...}]
 *
 * @param {Object} queryParams collection of the query string
 *
 * @param {Array<Object>} options collection of all valid option for the select field
 *
 * @returns {Array<Object>} react-select selected options : {value, label, state}
 */
export const mapParamsToSelectOptions = (queryParams, options) => {
  const parsedParams = Object.keys(queryParams).reduce((accumulationParams, name) => {
    const params = queryParams[name] ? parseSelectFilterOptions(queryParams[name]) : [];
    return [...accumulationParams, ...params];
  }, []);
  return mapArrayToSelectOptions([...new Set(parsedParams)], options);
};

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionExploreBars.module.css';

const locations = [
  {
    name: 'Los Angeles',
    searchQuery: '?pub_workCities=has_any%3ACA_los_angeles',
  },
  {
    name: 'Charleston',
    searchQuery: '?pub_workCities=has_any%3ASC_charleston',
  },
  {
    name: 'Portland',
    searchQuery: '?pub_workCities=has_any%3AOR_portland',
  },
  {
    name: 'San Francisco',
    searchQuery: '?pub_workCities=has_any%3ACA_san_francisco',
  },
  {
    name: 'Chicago',
    searchQuery: '?pub_workCities=has_any%3AIL_chicago',
  },
  {
    name: 'Seattle',
    searchQuery: '?pub_workCities=has_any%3AWA_seattle',
  },
  {
    name: 'San Diego',
    searchQuery: '?pub_workCities=has_any%3ACA_san_diego',
  },
  {
    name: 'Las Vegas',
    searchQuery: '?pub_workCities=has_any%3ANV_las_vegas',
  },
  {
    name: 'Miami',
    searchQuery: '?pub_workCities=has_any%3AFL_miami',
  },
  {
    name: 'New York City',
    searchQuery: '?pub_workCities=has_any%3ANY_new_york_city',
  },
  {
    name: 'Austin',
    searchQuery: '?pub_workCities=has_any%3ATX_austin',
  },
  {
    name: 'Calgary',
    searchQuery: '?pub_workCities=has_any%3AAB_calgary',
  },
  {
    name: 'Nashville',
    searchQuery: '?pub_workCities=has_any%3ATN_nashville',
  },
  {
    name: 'Dallas',
    searchQuery: '?pub_workCities=has_any%3ATX_dallas',
  },
  {
    name: 'Toronto',
    searchQuery: '?pub_workCities=has_any%3AON_toronto',
  },
];

const locationLink = ({ name, searchQuery }) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <h3 className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
          tagName=""
        />
      </h3>
    </NamedLink>
  );
};

const SectionExploreBars = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionExploreBars.title" tagName=""/>
      </h2>

      <div className={css.steps}>
        {locations.map(location => (
          <div key={location.name}>{locationLink({ ...location })}</div>
        ))}
      </div>

      <NamedLink name="SearchPage" className={css.outsideButton}>
        <FormattedMessage id="SectionExploreBars.exploreButton" />
      </NamedLink>
    </div>
  );
};

SectionExploreBars.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionExploreBars.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionExploreBars;

import React, { Component } from 'react';
import PropTypes, { number, shape } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { Carousel, NamedLink } from '../../components';
import { withViewport } from '../../util/contextHelpers';
import { compose } from 'redux';
import { viewportMedium } from '../../util/viewportHelpers';

import css from './SectionLocations.module.css';

import detroitImage from './images/location_detroit.jpg';
import losAngelesImage from './images/location_losAngeles.jpg';
import nashvilleImage from './images/location_nashville.jpg';
import sanDiegoImage from './images/location_sanDiego.jpg';
import sanFranciscoImage from './images/location_sanFrancisco.jpg';
import seattleImage from './images/location_seattle.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = ({ name, image, searchQuery }) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <h3 className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
          tagName=""
        />
      </h3>
    </NamedLink>
  );
};

const locations = [
  {
    name: 'Detroit',
    image: detroitImage,
    searchQuery: '?pub_workCities=has_any%3AMI_detroit',
  },
  {
    name: 'Los Angeles',
    image: losAngelesImage,
    searchQuery: '?pub_workCities=has_any%3ACA_los_angeles',
  },
  {
    name: 'Nashville',
    image: nashvilleImage,
    searchQuery: '?pub_workCities=has_any%3AIN_nashville',
  },
  {
    name: 'San Diego',
    image: sanDiegoImage,
    searchQuery: '?pub_workCities=has_any%3ACA_san_diego',
  },
  {
    name: 'San Francisco',
    image: sanFranciscoImage,
    searchQuery: '?pub_workCities=has_any%3ACA_san_francisco',
  },
  {
    name: 'Seattle',
    image: seattleImage,
    searchQuery: '?pub_workCities=has_any%3AWA_seattle',
  },
];

const SectionLocations = props => {
  const { rootClassName, className, viewport } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionLocations.title" tagName=""/>
      </h2>
      <div className={css.locations}>
        <Carousel
          className={css.swiperContainer}
          draggable={false}
          infinite={false}
          dots
          slidesToShow={viewport.width < viewportMedium ? 1 : 3}
        >
          {locations.map(location => {
            return <div key={location.searchQuery} className={css.swiperSlide}>{locationLink({ ...location })}</div>;
          })}
          <div className={classNames(css.endOfSlideContainer, css.swiperSlide)}>
            <NamedLink name="SearchPage" className={css.endOfSlideButton}>
              <FormattedMessage id="SectionLocations.seeAllLocations" />
            </NamedLink>
          </div>
        </Carousel>
      </div>
      <NamedLink name="SearchPage" className={css.outsideButton}>
        <FormattedMessage id="SectionLocations.seeAllLocations" />
      </NamedLink>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,

  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default compose(withViewport)(SectionLocations);

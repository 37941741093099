import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialPinterest.module.css';
import pinterestLogo from './pinterestIcon.png';

const IconSocialPinterest = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return <img src={pinterestLogo} alt="Pinterest Logo" className={classes} />;
};

IconSocialPinterest.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialPinterest.propTypes = { rootClassName: string, className: string };

export default IconSocialPinterest;

import React from 'react';
import { func, bool } from 'prop-types';

import css from './ToggleSwitch.module.css';

const ToggleSwitch = props => {
  const { label, isOpen, onToggle } = props;
  return (
    <div className={css.container}>
      {label && <p className={css.toggleLabel}>{label}</p>}
      <div className={css.toggleSwitch}>
        <input
          type="checkbox"
          className={css.checkbox}
          name={label}
          id={label}
          checked={isOpen}
          onChange={onToggle}
        />
        <label className={css.label} htmlFor={label}>
          <span className={css.inner} />
          <span className={css.switch} />
        </label>
      </div>
    </div>
  );
};

ToggleSwitch.defaultProps = { isOpen: false };

ToggleSwitch.propsTypes = {
  isOpen: bool,
  onToggle: func.isRequired,
};

export default ToggleSwitch;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    declineInProgress,
    declineError,
    onDecline,
    openMakeOfferForm,
    showCreatePaymentLinkButton,
    showDeclineButton,
    showMakeOfferButton,
    onCreatePaymentLink,
    createPaymentLinkInProgress,
    createPaymentLinkError
  } = props;

  const buttonsDisabled = declineInProgress || createPaymentLinkInProgress;

  const declineErrorMessage = declineError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineFailed" />
    </p>
  ) : null;
  const createPaymentLinkErrorMessage = createPaymentLinkError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.createPaymentLinkFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>{declineErrorMessage}{createPaymentLinkErrorMessage}</div>
      <div className={css.actionButtonWrapper}>
        {showDeclineButton && <SecondaryButton
          className={css.secondaryButton}
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={onDecline}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>}
        {showMakeOfferButton && <PrimaryButton
          className={css.primaryButton}
          disabled={buttonsDisabled}
          onClick={openMakeOfferForm}
        >
          <FormattedMessage id="TransactionPanel.makeOfferButton" />
        </PrimaryButton>}
        {showCreatePaymentLinkButton && (
          <PrimaryButton
            className={css.primaryButton}
            inProgress={createPaymentLinkInProgress}
            disabled={buttonsDisabled}
            onClick={onCreatePaymentLink}
          >
            <FormattedMessage id="TransactionPanel.createPaymentLinkButton" />
          </PrimaryButton>
        )}
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ExternalLink } from '../../components';
import config from '../../config';

import css from './SectionConciergeService.module.css';

import image from './images/image.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const SectionConciergeService = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionConciergeService.title" tagName=""/>
      </h2>
      <div className={css.locations}>
        <p>
          <FormattedMessage id="SectionConciergeService.subTitle" />
        </p>
        <p>
          <FormattedMessage id="SectionConciergeService.subSubTitle" />
        </p>

        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage src={image} alt="Concierge service" className={css.locationImage} />
          </div>
        </div>
      </div>
      <ExternalLink className={css.outsideButton} href={config.tallyForm.concierge}>
        <FormattedMessage id="SectionConciergeService.submitEventDetails" />
      </ExternalLink>
    </div>
  );
};

SectionConciergeService.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionConciergeService.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionConciergeService;

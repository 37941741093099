import React, { Component } from 'react';
import { string, func, shape } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import { findOptionsForSelectFilter, findSelectOptions } from '../../util/search';
import { ALL_CITIES } from '../../util/locationHelpers';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MOBILE_BAR_COMPARE_PROPERTY_NAME = 'key';
const MOBILE_BAR_FILTER_ID = 'mobileBarTypes';
const CITY_COMPARE_PROPERTY_NAME = 'value';

const priceData = (price, intl) => {
  if (price && price.amount > 0 && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price && price.amount > 0) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    filtersConfig,
    searchCity,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const {
    title = '',
    price,
    publicData: { standardWorkCities },
    metadata,
  } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const { formattedPrice } = priceData(price, intl);
  const workCity =
    standardWorkCities?.length &&
    findSelectOptions(ALL_CITIES, CITY_COMPARE_PROPERTY_NAME, standardWorkCities[0]);

  const formattedTitle = richText(title, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
  });
  const mobileBarTypeOptions = findOptionsForSelectFilter(MOBILE_BAR_FILTER_ID, filtersConfig);
  const mobileBarType = metadata?.mobileBarTypes
    ? findSelectOptions(
        mobileBarTypeOptions,
        MOBILE_BAR_COMPARE_PROPERTY_NAME,
        metadata.mobileBarTypes[0]
      )
    : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.mainInfo}>
        <p className={css.homeCity}>
          {mobileBarType ? <span> {mobileBarType[0]?.label} &#9679; &nbsp;</span> : null}
          {searchCity ? searchCity.label : workCity && workCity[0].label}
        </p>
        <div className={css.title}>{formattedTitle}</div>
        {formattedPrice && (
          <div className={css.price}>
            <div className={css.perUnit}>
              <FormattedMessage id="ListingCard.price" values={{ price: formattedPrice }} />
            </div>
          </div>
        )}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  filtersConfig: config.custom.filters,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  searchCity: shape({
    label: string,
    value: string,
    state: string,
  }),
};

export default injectIntl(ListingCardComponent);

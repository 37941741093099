import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ValidationError } from '../../components';

import css from './FieldCustomMultipleSelect.module.css';

const FieldCustomMultipleSelectComponent = props => {
  const {
    rootClassName,
    className,
    description,
    id,
    label,
    input,
    meta,
    options,
    isCreatable,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const { valid, invalid, touched, error } = meta;
  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const classes = classNames(css.select, css.root || rootClassName, {
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });
  //custom styles of components inside Select
  const customStyles = {
    control: () => {}, //clear default styles
    valueContainer: styles => ({
      ...styles,
      padding: '0',
    }), //apply custom styles in default style of components
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#e9d190',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: 'var(--matterColorAnti)',
      };
    },
  };
  const selectProps = {
    className: classes,
    id,
    options,
    styles: customStyles,
    components: {
      IndicatorSeparator: () => null,
      IndicatorsContainer: () => null,
    },
    ...input,
    ...meta,
    ...rest,
  };

  return options ? (
    <div className={rootClassName || className}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {description ? <p className={css.description}>{description}</p> : null}
      {isCreatable ? (
        <CreatableSelect {...selectProps} isSearchable isMulti />
      ) : (
        <Select {...selectProps} isMulti />
      )}
      <ValidationError fieldMeta={meta} />
    </div>
  ) : null;
};

FieldCustomMultipleSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  children: null,
  isClearable: false,
  isSearchable: false,
  isCreatable: false,
};

const { string, object, node, bool } = PropTypes;

FieldCustomMultipleSelectComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,

  description: string,

  isClearable: bool,
  isSearchable: bool,
  isCreatable: bool,
};

const FieldCustomMultipleSelect = props => {
  return <Field component={FieldCustomMultipleSelectComponent} {...props} />;
};

export default FieldCustomMultipleSelect;

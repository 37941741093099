import classNames from 'classnames';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import React, { Component } from 'react';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import config from '../../config';
import { SendMessageForm } from '../../forms';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  txIsBookingDeclined,
  txIsBookingExpired,
  txIsBookingRequested,
  txIsCanceled,
  txIsCreateSecondPaymentLinkExpired,
  txIsDelivered,
  txIsDisputeCompleted,
  txIsDisputeRequested,
  txIsEventCompleted,
  txIsEventStarted,
  txIsFirstPaymentConfirmed,
  txIsFirstPaymentPending,
  txIsFirstPayoutExecuted,
  txIsFullPaymentConfirmed,
  txIsFullPaymentFlow,
  txIsFullPaymentNotConfirmed,
  txIsInFirstReview,
  txIsOfferMade,
  txIsReviewed,
  txIsSecondPaymentConfirmPending,
  txIsSecondPaymentConfirmed,
  txIsSecondPaymentLinkCreated,
  txIsSecondPaymentReminderSent,
  txLastTransition,
} from '../../util/transaction';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { isMobileSafari } from '../../util/userAgent';

// These are internal components that make this file more readable.
import { BiMessageSquareDetail } from 'react-icons/bi';
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import PanelHeading, {
  HEADING_ACCEPTED,
  HEADING_BOOKING_EXPIRED,
  HEADING_CANCELED,
  HEADING_CONFIRM_SECOND_PAYMENT_PENDING,
  HEADING_DECLINED,
  HEADING_DELIVERED,
  HEADING_OFFER_MADE,
  HEADING_REQUESTED,
} from './PanelHeading';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import OrderActionButtonsMaybe from './OrderActionButtonsMaybe';

import css from './TransactionPanel.module.css';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onDecline,
      declineInProgress,
      declineError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      openBookingDetailsForm,
      openMakeOfferForm,
      onMakePayment,
      onCancelBooking,
      cancelInProgress,
      cancelError,
      createPaymentLinkInProgress,
      createPaymentLinkError,
      requestDisputeInProgress,
      requestDisputeError,
      onRequestDispute,
      onCreatePaymentLink,
      onCompleteDispute,
      completeDisputeError,
      completeDisputeInProgress,
      onMakeSecondPayment,
      makePaymentError,
      makePaymentInProgress,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const { paymentLink } = currentTransaction?.attributes?.metadata;

    const stateDataFn = tx => {
      if (txIsBookingRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showMakeOfferButton: isProvider,
          showDeclineButton: isProvider,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsBookingDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (
        txIsOfferMade(tx) ||
        txIsFullPaymentFlow(tx) ||
        txIsFullPaymentNotConfirmed(tx) ||
        txIsFirstPaymentPending(tx)
      ) {
        return {
          headingState: HEADING_OFFER_MADE,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
          showDeclineButton: isCustomer,
          showMakePaymentButton: isCustomer,
        };
      } else if (txIsFirstPaymentPending(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
        };
      } else if (txIsFirstPaymentConfirmed(tx) || txIsFullPaymentConfirmed(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
          showCancelButton: isCustomer,
        };
      } else if (txIsFirstPayoutExecuted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsSecondPaymentReminderSent(tx) || txIsCreateSecondPaymentLinkExpired(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showCreatePaymentLinkButton: isProvider && !txIsCreateSecondPaymentLinkExpired(tx),
        };
      } else if (txIsSecondPaymentLinkCreated(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
          showMakePaymentByPaymentLinkButton: isCustomer,
        };
      } else if (txIsSecondPaymentConfirmPending(tx)) {
        return {
          headingState: HEADING_CONFIRM_SECOND_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsSecondPaymentConfirmed(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsFullPaymentFlow(tx) || txIsFullPaymentNotConfirmed(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsEventStarted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
        };
      } else if (txIsDisputeRequested(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
          showCompleteDisputeButton: isCustomer,
        };
      } else if (txIsFullPaymentConfirmed(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsDisputeCompleted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsEventCompleted(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showOrderButtons: isCustomer && !isProviderBanned,
          showDisputeButton: isCustomer,
        };
      } else if (txIsDelivered(tx) || txIsReviewed(tx) || txIsInFirstReview(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsBookingExpired(tx)) {
        return {
          headingState: HEADING_BOOKING_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showDeclineButton={stateData.showDeclineButton}
        showMakeOfferButton={stateData.showMakeOfferButton}
        showButtons={stateData.showSaleButtons}
        declineInProgress={declineInProgress}
        declineError={declineError}
        onDecline={() => onDecline(currentTransaction.id, txLastTransition(currentTransaction))}
        openMakeOfferForm={openMakeOfferForm}
        showCreatePaymentLinkButton={stateData.showCreatePaymentLinkButton}
        onCreatePaymentLink={() =>
          onCreatePaymentLink(currentTransaction.id.uuid, currentListing.id.uuid)
        }
        createPaymentLinkInProgress={createPaymentLinkInProgress}
        createPaymentLinkError={createPaymentLinkError}
      />
    );

    const orderButtons = (
      <OrderActionButtonsMaybe
        showButtons={stateData.showOrderButtons}
        showDeclineButton={stateData.showDeclineButton}
        onDeclineOffer={() =>
          onDecline(currentTransaction.id, txLastTransition(currentTransaction))
        }
        declineInProgress={declineInProgress}
        declineError={declineError}
        showMakePaymentButton={stateData.showMakePaymentButton}
        onMakePayment={onMakePayment}
        onCancelBooking={() =>
          onCancelBooking(currentTransaction.id, txLastTransition(currentTransaction))
        }
        paymentLink={paymentLink}
        showCancelButton={stateData.showCancelButton}
        cancelInProgress={cancelInProgress}
        cancelError={cancelError}
        showMakePaymentByPaymentLinkButton={stateData.showMakePaymentByPaymentLinkButton}
        showDisputeButton={stateData.showDisputeButton}
        onRequestDispute={() => onRequestDispute(currentTransaction.id)}
        requestDisputeInProgress={requestDisputeInProgress}
        requestDisputeError={requestDisputeError}
        showCompleteDisputeButton={stateData.showCompleteDisputeButton}
        onCompleteDispute={() => onCompleteDispute(currentTransaction.id)}
        completeDisputeInProgress={completeDisputeInProgress}
        completeDisputeError={completeDisputeError}
        onMakeSecondPayment={() => {
          onMakeSecondPayment(currentTransaction.id.uuid).then(() => {
            const { paymentLink } = currentTransaction.attributes.metadata;
            try {
              // Issue: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
              setTimeout(() => {
                return window.open(paymentLink, '_blank');
              });
            } catch (e) {
              return window.location.replace(paymentLink);
            }
          });
        }}
        makePaymentInProgress={makePaymentInProgress}
        makePaymentError={makePaymentError}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const viewFullBookingDetails = !stateData.showBookingPanel && (
      <div className={css.viewFullBooking} onClick={openBookingDetailsForm}>
        {intl.formatMessage({ id: 'TransactionPanel.viewFullBooking' })}
        <BiMessageSquareDetail size={15} />
      </div>
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}
            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />
            <div className={css.fullBookingDetailsMobile}>{viewFullBookingDetails}</div>
            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe transaction={currentTransaction} transactionRole={transactionRole} />
            </div>
            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}
            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
            {stateData.showOrderButtons ? (
              <div className={css.mobileActionButtons}>{orderButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}

              <div className={css.fullBookingDetailsDesktop}>{viewFullBookingDetails}</div>
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {stateData.showOrderButtons ? (
                <div className={css.desktopActionButtons}>{orderButtons}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  declineError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
  makeOfferInProgress: false,
  makeOfferError: null,
  cancelInProgress: false,
  cancelError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  onDecline: func.isRequired,
  onCreatePaymentLink: func.isRequired,
  onRequestDispute: func.isRequired,
  onCompleteDispute: func.isRequired,
  onMakeSecondPayment: func.isRequired,

  declineInProgress: bool.isRequired,
  declineError: propTypes.error,
  makeOfferInProgress: bool.isRequired,
  cancelError: propTypes.error,
  cancelInProgress: bool.isRequired,
  createPaymentLinkInProgress: bool.isRequired,
  createPaymentLinkError: propTypes.error,
  requestDisputeInProgress: bool.isRequired,
  requestDisputeError: propTypes.error,
  completeDisputeInProgress: bool.isRequired,
  completeDisputeError: propTypes.error,
  makePaymentInProgress: bool.isRequired,
  makePaymentError: propTypes.error,

  // line items
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
